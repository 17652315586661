import React from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import Icon1 from "../../assets/icons/SimpleStepsIcon1.svg";
import Icon2 from "../../assets/icons/SimpleStepsIcon2.svg";
import Icon3 from "../../assets/icons/SimpleStepsIcon3.svg";
import Icon4 from "../../assets/icons/SimpleStepsIcon4.svg";

export const ThreeSimpleSteps = () => {
  const { isMobile } = useWindowDimensions();

  const stepsContent = [
    {
      header: "3 Easy Steps",
      bgColor: "bg-white",
      content:
        "Complete our quick and confidential medical form detailing yoursymptoms and select your treatment plan.",
      icon: Icon1,
    },
    {
      header: "Fill Out Symptoms Form",
      bgColor: "bg-lavenderBlue",
      content:
        "Fill out our quick and private medical form with your symptoms and choose your treatment plan",
      icon: Icon2,
    },
    {
      header: "Our Doctors Review",
      bgColor: "bg-yellow",
      content:
        "Our licensed U.S. physician will assess your situation and prescribe medication if it is deemed safe and appropriate after a thorough review of your case",
      icon: Icon3,
    },
    {
      header: "Free Delivery or Same Day Pick Up",
      bgColor: "bg-light-blue",
      content:
        "Fill out our quick and private medical form with your symptoms and choose your treatment plan",
      icon: Icon4,
    },
  ];

  return (
    <div
      id="three-simple-steps-container"
      className="grid grid-cols-1 md:grid-cols-4"
    >
      {stepsContent.map((step, index) => (
        <div
          className={`flex flex-col text-black text-start md:my-none items-center relative px-[2rem] md:px-[1rem] xl:px-[1.8rem] 2xl:px-[2.8rem] pb-[3.12rem] md:pb-[2rem] lg:pb-[3.12rem] pt-[3.5rem] md:pt-[2rem] lg:pt-[3rem] xl:pt-[4rem] 2xl:pt-[4.68rem] ${step.bgColor}`}
          key={index}
        >
          <div
            className={`w-full mb-[1.25rem] md:mb-[1rem] lg:mb-[2rem] xl:mb-[3.12rem]`}
          >
            <img
              src={`${step.icon}`}
              alt={step.header}
              className="h-[3.8rem] xl:h-[4.68rem]"
            />
          </div>

          <h1 className="w-full font-semibold text-[2.25rem] md:text-[1.2rem] lg:text-[1.7rem] xl:text-[2rem] 2xl:text-[2.5rem] mb-5 md:mb-3 lg:mb-5 leading-[2.4rem] md:leading-[1.4rem] lg:leading-[1.8rem] xl:leading-[2.1rem] 2xl:leading-[2.6rem]">
            {step.header}
          </h1>
          <p className="text-[1.25rem] md:text-[12px] lg:text-[1rem] 2xl:text-[1.25rem] leading-normal xl:leading-loose">
            {step.content}
          </p>
        </div>
      ))}
    </div>
  );
};
