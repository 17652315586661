import { useState } from "react";
import SectionTitle from "../SectionTitle";
import { useQuery } from "@tanstack/react-query";
import { SearchForPharmacy } from "../../../services/SearchForPharmacy";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import FetchedPharmacySection from "./FetchedPharmacySection";
import PharmacySearchForm from "./PharmacySearchForm";
import DauchiLogo from "../../../assets/images/DauchiLogo.png";

function PharmacySection() {
  const PharmacySectionTitle =
    "Please provide the following information for us to find pharmacies near you!";

  const [pharmacyRequestBody, setPharmacyRequestBody] = useState({});
  const [pharmaciesAreShown, setPharmaciesAreShown] = useState(false);
  const [selectedPharmacyId, setSelectedPharmacyId] = useState(null);

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.isMobile;

  const {
    data: fetchedPharmacies,
    isLoading,
    isError,
  } = useQuery(
    [`pharmacies/search`, { pharmacyRequestBody }],
    () => SearchForPharmacy(pharmacyRequestBody),
    {
      enabled: pharmaciesAreShown,
    }
  );

  const searchForPharmacy = (data) => {
    setPharmacyRequestBody({
      state: data.state,
      city: data.city,
      name: data.name,
      zip: data.zip,
    });
    setPharmaciesAreShown(true);
  };

  const onSubmit = (data) => {
    searchForPharmacy(data);
  };

  return (
    <div className="flex flex-col md:px-[1.87rem]">
      <SectionTitle title="Pharmacy Search" />
      <h2 className="mb-[20px] font-normal md:font-bodyCopy text-base md:text-[20px] text-start">
        {PharmacySectionTitle}
      </h2>
      <div
        className={`w-full rounded-xl bg-white border-inputGraySecondary md:border-[1px] border-opacity-40 flex flex-col py-[1.25rem] text-left xl:px-[2.5rem] mb-[0px] md:mb-[30px]`}
      >
        {<PharmacySearchForm onSubmit={onSubmit} isMobile={isMobile} />}
      </div>

      {isMobile && pharmaciesAreShown && (
        <div className="relative flex items-center justify-center pb-[1rem] lg:py-[2rem]">
          <img
            src={DauchiLogo}
            alt="Dauchi"
            className="h-[3.12rem] w-[3.12rem] z-20"
          />
          <div className="w-full h-[1px] bg-secondary absolute top-1/2 z-10" />
        </div>
      )}
      {pharmaciesAreShown && (
        <FetchedPharmacySection
          isError={isError}
          isLoading={isLoading}
          isMobile={isMobile}
          selectedPharmacyId={selectedPharmacyId}
          setSelectedPharmacyId={setSelectedPharmacyId}
          fetchedPharmacies={fetchedPharmacies}
        />
      )}
    </div>
  );
}

export default PharmacySection;
