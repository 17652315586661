import { useMemo, useState } from "react";
import moment from "moment";
import SelectedOrder from "./SelectedOrder";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import SectionTitle from "../SectionTitle";
import { ORDER_STATUS } from "../../../constants/Common";

const OrdersMobile = ({ orders }) => {
  const [activeTab, setActiveTab] = useState(ORDER_STATUS.SHIPPED);
  const [selectedOrder, setSelectedOrder] = useState("");

  const ORDER_DATE_FORMAT = "MM/DD/YYYY";

  const OrderCard = ({ order }) => {
    const treatmentDummyImage =
      "https://mir-s3-cdn-cf.behance.net/projects/404/6b065d75605675.Y3JvcCw4OTQsNzAwLDAsMA.jpg";

    return (
      <div className="w-full rounded-[3px] border-[1px] border-ordersBorder border-opacity-100 text-start mb-5">
        <h2 className="text-[1rem] px-[1.25rem] py-2 text-white font-medium bg-primary mb-[1.25rem]">
          {order.title}
        </h2>
        <div className="relative w-full p-[1.25rem] flex gap-x-[20px] justify-between">
          <img
            className="h-[85px] w-[85px] object-cover block mr-5"
            src={order.image || treatmentDummyImage}
            alt=""
          />
          <div className="flex w-full justify-between items-start">
            <div className="flex flex-col">
              <p className="font-medium mb-[10px] text-[14px]">{order.title}</p>
              <p className="mb-[10px] text-[12px]">
                Order Created:{" "}
                {moment(order?.created_at)?.format(ORDER_DATE_FORMAT)}
              </p>
              <p className="text-[12px]">${order.total}</p>
            </div>
          </div>
          <div
            className="bg-primary rounded-full p-[1px] absolute top-1/2 translate-y-[-50%] right-4 md:hidden cursor-pointer"
            onClick={() => setSelectedOrder(order)}
          >
            <MdOutlineKeyboardArrowRight size={24} className="text-secondary" />
          </div>
        </div>
      </div>
    );
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setSelectedOrder("");
  };

  const renderOrderContent = () => {
    if (selectedOrder) {
      return <SelectedOrder selectedOrder={selectedOrder} />;
    }

    if (orders?.length > 0) {
      return orders?.map((order, index) => (
        <OrderCard order={order} key={index} />
      ));
    }

    return <p>No orders available.</p>;
  };

  return (
    <>
      <div className="flex items-center">
        {selectedOrder && (
          <div
            className="bg-primary rounded-full p-[2px] self-start cursor-pointer mt-2 mr-3"
            onClick={() => handleTabClick("shipped")}
          >
            <MdOutlineKeyboardArrowLeft size={24} className="text-secondary" />
          </div>
        )}
        <SectionTitle
          title={selectedOrder ? `${selectedOrder.title}` : "Current Orders"}
        />
      </div>
      <div className="w-full mt-10">{renderOrderContent()}</div>
    </>
  );
};

export default OrdersMobile;
