import SectionTitle from "../SectionTitle";
import WideSectionCard from "../WideSectionCard";
import { subscriptionSectionData } from "../../../utils/dashboard";
import { useAppContext } from "../../../context/AppContext";

const SubscriptionsSection = () => {
  const { subscriptions } = useAppContext();
  const SubscriptionDetailsColumn = ({ title, content, className }) => (
    <div className={`flex flex-col mb-[2rem] md:mb-0 ${className}`}>
      <p className="text-[18px] md:text-[1.25rem] xl:text-[1.5rem] font-medium mb-[0.5rem]">
        {title}
      </p>
      <p className="text-[1rem] md:text-[18px] xl:text-[1.25rem]">{content}</p>
    </div>
  );

  const subscriptionContent = (
    name,
    description,
    price,
    frequency,
    subtype,
    active
  ) => {
    const email = "your@email.com";
    const subject = "Edit Refill Data";
    const body =
      "Dear Dauchi,\n\nI would like to edit the refill data.\n\nSincerely,\nPatient";

    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    const mailtoLink =
      "mailto:" + email + "?subject=" + encodedSubject + "&body=" + encodedBody;

    return (
      <div className="w-full">
        <div className="flex justify-between mb-[2rem] md:mb-[5rem]">
          <h2 className="text-[24px] mb-[30px]">{name}</h2>
          {active && (
            <span
              className={`w-[80px] h-fit py-[5px] text-[12px] text-opacity-40 text-center rounded-full ${"bg-green-200"}`}
            >
              Active
            </span>
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between px-[5%] mb-[2rem] md:mb-[5rem]">
          <SubscriptionDetailsColumn
            title="Description"
            content={description}
            className={"w-full md:w-[35%]"}
          />
          <SubscriptionDetailsColumn
            title="Frequency"
            content={frequency}
            className={"w-full md:w-[25%]"}
          />
          <SubscriptionDetailsColumn
            title="Sub Type"
            content={subtype}
            className={"w-full md:w-[25%]"}
          />
          <SubscriptionDetailsColumn
            title="Price"
            content={"$" + price}
            className={"w-full md:w-[15%]"}
          />
        </div>
        <div className="flex flex-col lg:flex-row justify-end px-[5%]">
          <a
            href={mailtoLink}
            className="px-[1.5rem] text-center py-[0.5rem] mb-[1.5rem] lg:mb-0 text-[18px] md:text-[20px] border-primary 
             lg:border-borderGray hover:border-0 hover:bg-primary hover:text-white border rounded-full
             transition-all duration-200
             "
          >
            Manage Subscription
          </a>
        </div>
      </div>
    );
  };

  const wideCardAddOnStyles = "md:w-full m-0 m-[27px] md:mb-[50px]";

  return (
    <div className="flex flex-col">
      <SectionTitle title="Current Subscriptions" />
      {subscriptions.map((subscription, index) => (
        <div className="flex flex-col items-center md:flex-row">
          <WideSectionCard
            key={index}
            content={subscriptionContent(
              subscription.name,
              subscription.description,
              subscription.price,
              subscription.frequency,
              subscription.subtype,
              subscription.active
            )}
            className={wideCardAddOnStyles}
          />
        </div>
      ))}
    </div>
  );
};

export default SubscriptionsSection;
