import axios from "axios";
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API;

export const fetchAddressByZipCode = async (zip) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${GOOGLE_API_KEY}`
    );
    const results = response.data.results;
    if (results.length > 0) {
      const addressComponents = results[0].address_components;
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      const state = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name;
      return { city, state };
    }
    return { city: "", state: "" };
  } catch (error) {
    console.error("Error fetching city and state:", error);
  }
};
