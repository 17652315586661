import React from "react";
import { useLocation } from "react-router-dom";
import { SurveyWizard } from "../../components/survey/SurveyWizard";
import { treatments } from "../../utils/treatments";
import _ from "lodash";

// TODO: Refactor to bring Preliminary Survey for
// this and all treatments under one file e.g(Herpes)
const BvPreliminarySurvey = () => {
  const bvSearchableTag =
    process.env.REACT_APP_BV_PRELIMINARY_SURVEY_SEARCHABLE_TAG;

  const surveyPath = _.find(treatments, {
    searchable_tag: bvSearchableTag,
  })?.href;

  return (
    <SurveyWizard
      surveySearchableTag={bvSearchableTag}
      treatmentSurveyPath={surveyPath}
    />
  );
};

export default BvPreliminarySurvey;
