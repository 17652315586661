import React from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { getWhatsNeededLayout } from "../../utils/treatments";

const TreatmentWhatsNeeded = ({ content }) => {
  const cardLayout = content?.getWhatsNeeded.layout;
  const isCardLayout = cardLayout === getWhatsNeededLayout.card;
  const { windowWidth } = useWindowDimensions();

  const calculateHeight = () => {
    if (windowWidth >= 1560) {
      return "7.3rem";
    } else if (windowWidth >= 1280) {
      return "6.5rem";
    } else if (windowWidth >= 1024) {
      return "5rem";
    } else if (windowWidth >= 768) {
      return "4.2rem";
    } else {
      return "3.12rem";
    }
  };

  const iconStyle = {
    height: calculateHeight(),
  };

  const renderWhatsNeededList = () => {
    return (
      <>
        {content?.getWhatsNeeded.cardList(iconStyle).map((item, index) => {
          return (
            <div
              key={index}
              className={`flex flex-row-reverse text-center justify-center items-center bg-treatmentGetsWhatNeededBackGround rounded-md md:rounded-[1.25rem] justify-between py-[1.56rem] px-[1.25rem] md:px-[1.56rem] ${
                isCardLayout
                  ? "w-full md:flex-col md:w-[25rem] md:py-[3.12rem] gap-y-[2.2rem] xl:gap-y-[3.12rem]"
                  : "w-full md:w-full md:py-[2.4rem]"
              }`}
            >
              <div className="bg-secondary rounded-full p-4 flex items-center justify-center h-[5rem] w-[5rem] md:h-[9.3rem] md:w-[9.3rem] aspect-square">
                {item.icon}
              </div>
              <div
                className={`w-[60%] text-start  flex flex-col text-black md:text-primary ${
                  isCardLayout
                    ? "md:w-full md:text-center"
                    : "md:w-[60%} md:text-start"
                }`}
              >
                <h2
                  className={`font-medium text-[1.2rem] mb-[10px] ${
                    isCardLayout
                      ? "md:text-[1.87rem] md:mb-[1rem] xl:mb-[1.25rem]"
                      : "md:text-[2.25rem] md:mb-[14px]"
                  }`}
                >
                  {item.title}
                </h2>
                <p className="w-full text-[10px] md:text-[1.06rem] lg:text-[1.31rem] xl:text-[1.625rem] 2xl:text-[24px] font-light">
                  {item.text}
                </p>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="md:p-[1.87rem] md:pt-0">
      <div
        id="container"
        className="pt-[1.87rem] pb-[3.12rem] md:pt-10 md:pb-14 bg-primary md:rounded-[3.12rem]"
      >
        <div className="ml-[1.2rem] md:ml-[2.81rem] text-secondary text-start text-[2rem] md:text-[2.18rem] lg:text-[2.75rem] xl:text-[3.25rem] 2xl:text-[4rem] mb-[1.2rem] md:mb-[1rem] lg:mb-[1.2rem] xl:mb-[2.2rem] 2xl:mb-[3.12rem] md:leading-[5.18rem]">
          What You'll Need
        </div>
        <div
          id="list-section"
          className={`mb-8 px-[1.2rem] flex justify-center flex-wrap gap-[1.87rem] ${
            isCardLayout ? "md:px-[1.87rem]" : "md:px-[3rem] xl:px-[8.12rem]"
          }`}
        >
          {renderWhatsNeededList()}
        </div>
      </div>
    </div>
  );
};

export default TreatmentWhatsNeeded;
