import React from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { MobileMoreFromDauchi } from "./MobileMoreFromDauchi";
import { DesktopMoreFromDauchi } from "./DesktopMoreFromDauchi";

export const MoreFromDauchi = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <div className="relative">
      {isMobile ? <MobileMoreFromDauchi /> : <DesktopMoreFromDauchi />}
    </div>
  );
};
