import { BsArrowReturnRight } from "react-icons/bs";
import { treatments } from "../../utils/treatments";
import { Link } from "react-router-dom";
import "./treatmentsOffered.scss";

export const TreatmentsOffered = () => {
  return (
    <div id="treatment-container">
      <div className="flex flex-col font-bodyCopy text-secondary text-start px-[3%] mt-10 mb-[2rem]">
        <h1 className="text-[2rem] md:text-[2.8rem] lg:text-[3.4rem] 2xl:text-[4rem]">
          Treatments We Offer
        </h1>
        <div className="text-[1rem] md:text-[1.37rem] lg:text-[1.62rem] 2xl:text-3xl">
          Select a treatment from the list below
        </div>
      </div>
      <div
        id="treatment-table"
        className="flex flex-wrap pb-[5rem] xl:pb-[9.37rem] gap-[1rem] md:gap-[2.4rem] lg:gap-[2rem] px-[1.2rem] md:px-[2.4rem] custom:px-[2.5rem] justify-center"
      >
        {treatments.map((treatment, index) => (
          <Link
            to={treatment.campaignPageHref}
            key={index}
            className={`treatment-card relative w-full md:w-[20rem] lg:w-[18rem] xl:w-[23rem] 2xl:w-[28rem] custom:w-[34.375rem] h-[7.5rem] smallCustom:h-[10rem] sm:h-[12rem] md:h-[20rem] lg:h-[22rem] xl:h-[24rem] 2xl:h-[26rem] custom:h-[28rem] rounded-xl flex flex-col text-black border-[2px] border-white bg-cover bg-top sm:bg-center`}
            style={{ backgroundImage: `url(${treatment.image})` }}
          >
            <div
              className="absolute z-10 h-full w-full rounded-xl opacity-40"
              style={{ backgroundColor: treatment.cardColor }}
            ></div>

            <div className="treatment-content flex flex-col z-20 h-full w-full justify-between">
              <div className="treatment-name flex">
                <h1 className="m-2 md:m-[1rem] bg-black bg-opacity-40 rounded-full py-2 px-4 text-md smallCustom:text-lg xl:text-[1.5rem] 2xl:text-[24px] custom:text-[28px] text-start text-white inline-block w-auto drop-shadow-2xl">
                  {treatment.name}
                </h1>
              </div>
              <p className="treatment-desc hidden px-2 smallCustom:px-4 sm:px-14 md:px-6 lg:px-8 xl:px-12 2xl:px-12 custom:px-20 text-white text-sm smallCustom:text-lg sm:text-2xl md:text-lg lg:text-lg xl:text-[1.3rem] 2xl:text-[24px] custom:text-[1.75rem] text-start">
                {treatment.description}
              </p>
              <div className="arrow-bg flex items-center justify-center h-[55px] lg:h-[50px] xl:h-[60px] 2xl:h-[70px] custom:h-[75px] w-[55px] lg:w-[50px] xl:w-[60px] 2xl:w-[70px] custom:w-[75px] bg-black bg-opacity-40 rounded-full p-3 xl:p-3 2xl:p-4 ml-auto mr-2 md:mr-6 mb-2 md:my-6">
                <BsArrowReturnRight
                  className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl custom:text-[2.8rem]"
                  style={{ color: `${treatment.cardColor}` }}
                />
              </div>
              <BsArrowReturnRight
                className="hidden treatment-arrow-icon text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl custom:text-[2.8rem]"
                style={{ color: `${treatment.cardColor}` }}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
