import React from "react";
import { useForm, Controller } from "react-hook-form";
import { PharmacyInputFields } from "../../../utils/pharmacyInputFields";
import { populateAddressByZipCode } from "../../../hooks/populateAddressByZipCode";
import { zipRegex } from "../../../utils/validation";

const PharmacySearchForm = ({ isMobile, onSubmit }) => {
  const inputDivOrder = isMobile === true ? [0, 4, 1, 2, 3] : [0, 1, 2, 3, 4];

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const handleInputChange = async (event, name) => {
    if (name !== "zip") {
      return;
    }

    const zipCode = event.target.value;
    if (!zipRegex.test(zipCode)) {
      return;
    }

    const cityFieldName = "city";
    const stateFieldName = "state";
    populateAddressByZipCode(zipCode, setValue, cityFieldName, stateFieldName);
  };

  return (
    <form
      id="pharmacy-form"
      className="w-full md:px-[5%] xl:px-[5%] 2xl:px-0 flex flex-col items-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset className="w-full flex flex-col gap-[1.25rem]">
        <div className="w-full flex items-start flex-wrap text-[0.75rem] sm:text-base 2xl:text-2xl">
          {inputDivOrder.map((fieldOrder, index) => {
            const field = PharmacyInputFields[fieldOrder];
            const dropdownOptions = field.options;
            return (
              <div
                key={index}
                className={`w-full md:w-[44%] xl:w-[28%] mb-[1.2rem] md:mr-[20px] ${
                  field.type === "select" && "relative"
                }`}
              >
                <label className="text-[13px] md:text-base xl:text-[1.25rem] mb-[9px] xl:font-semibold">
                  {field.label}
                </label>
                {field.type === "select" ? (
                  <Controller
                    render={({ field }) => (
                      <>
                        <select
                          {...field}
                          className={`w-full outline-none py-[13px] px-[1.25rem] border bg-transparent border-inputGray border-opacity-30 text-[18px] rounded${
                            errors[field.name]
                              ? "border-red-500 outline-red-500 border-none outline-[1px]"
                              : ""
                          }`}
                        >
                          <option value="">{"State"}</option>
                          {dropdownOptions.map((option, optionIndex) => (
                            <option key={optionIndex} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    control={control}
                    name={field.name}
                    rules={field.rules}
                  />
                ) : (
                  <>
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      className={`w-full outline-none py-[13px] px-[1.25rem] border border-inputGray border-opacity-30 text-[18px] rounded ${
                        errors[field.name] ? "border-red-500" : ""
                      }`}
                      {...register(field.name, field.rules)}
                      onChange={(e) => handleInputChange(e, field.name)}
                    />
                  </>
                )}
                {errors[field.name] && (
                  <span className="text-red-500 text-base lg:text-[1.25rem]">
                    {errors[field.name].message}
                  </span>
                )}
              </div>
            );
          })}
          <button
            type="submit"
            className={`w-full md:w-[44%] xl:w-[28%] mt-[1.7rem] xl:mt-[2.2rem] text-[13px] md:text-base xl:text-[1.25rem] 2xl:text-2xl text-center bg-primary text-white font-bold py-3 md:py-[13px] xl:py-2 rounded-full md:rounded-none`}
          >
            Search
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default PharmacySearchForm;
