import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../context/AppContext";
import { signin, signup } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { useSurveyState } from "./SurveyContext";
import { resendOtp } from "../../services/auth";
import Loader from "../Loader";
import { getUser } from "../../services/user";

const SurveyPasswordSection = ({ hasUserData }) => {
  const { userSurveyInfo, setAppState, setModalState } = useAppContext();
  const [state] = useSurveyState();
  const navigate = useNavigate();

  const phoneNumber = state.cell_question;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    getValues,
  } = useForm();

  useEffect(() => {
    if (hasUserData) {
      navigate("/dashboard");
    }
  }, []);

  const setPasswordInputsData = [
    {
      name: "password",
      label: "Create Password",
      helperText:
        "Password must contain at least 8 characters, 1 capital letter (A-Z), and at least 1 symbol (!, @, #, $, %, ^, &, *, ., or ?).",
      placeholder: "Password",
      defaultValue: "",
      type: "password",
      validation: {
        required: "Password is required",
        minLength: {
          value: 8,
          message: "Password must be at least 8 characters long",
        },
        pattern: {
          value: /^(?=.*[A-Z])(?=.*[!@#$%^&*?.]).{8,}$/,
          message: "Invalid password format",
        },
      },
    },

    {
      name: "password_confirmation",
      label: "Re-enter Password",
      helperText: "Please re-enter your password that you created above",
      placeholder: "Confirm Password",
      defaultValue: "",
      type: "password",
      validation: {
        required: "Confirm Password is required",
        validate: {
          equal: (value) =>
            value === getValues().password ||
            "Confirm password must match Password",
        },
      },
    },
  ];

  const onSubmit = async (data) => {
    try {
      await signup(data, userSurveyInfo?.id);

      // Login User
      const cred = { email: state?.email_question, password: data.password };
      const signedInUser = await signin(cred);
      const userResponse = await getUser(signedInUser?.data?.id);
      setAppState(userResponse);

      navigate("/dashboard?onboarding=true");
    } catch (error) {
      setModalState({
        switch: true,
        message: error.messsage,
        status: "error",
      });
      const { data } = error.response || {};
      alert(data?.error || data?.errors);
      console.error(error.response);
    }
  };

  /**
   * Render input field for set password form
   * @param {object} input - Input Form data
   * @param {integer} index - Input Index
   * @returns - Input Component
   */
  const renderInput = (input, index) => {
    const { label, type, placeholder, name, validation, helperText } = input;
    return (
      <div
        key={index}
        className="flex flex-col items-start mb-[1rem] md:mb-[1.5rem]"
      >
        <label className="text-[1.25rem] md:text-[1.2rem] font-medium">
          {label}
        </label>
        <label
          className="text-[11px] md:text-[12px] text-gray
        2xl:text-[16px] mb-[6px] md:mb-[12px] text-start"
        >
          {helperText}
        </label>
        <input
          type={type}
          placeholder={placeholder}
          {...register(name, validation)}
          className="outline-none h-[2.6rem] 2xl:h-[3.75rem] 2xl:px-[1.5rem] text-base 2xl:text-base p-2 w-full border border-borderGraySmall lg:border-borderGray rounded"
        />
        {errors[name] && (
          <p className="text-red-500 text-xs mt-1">{errors[name]?.message}</p>
        )}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="py-[5rem]">
      <div className="flex flex-col items-center">
        <div className="w-full md:w-[50%] lg:w-[28rem] pt-2">
          {setPasswordInputsData.map(renderInput)}
        </div>
        {isSubmitting ? (
          <Loader />
        ) : (
          <button
            type="submit"
            className={`bg-secondary font-bold text-base 
              2xl:text-[1.68rem] text-darkPrimary py-2 px-24
              rounded-full border-l-2 border-white
              ${!isValid && "opacity-60"}`}
          >
            SUBMIT
          </button>
        )}
      </div>
    </form>
  );
};

export default SurveyPasswordSection;
