import React from "react";
import { CheckmarkSvg } from "../svgs/CheckmarkSvg";

const TreatmentInfoBanner = ({ title, content }) => {
  const renderCheckList = () => {
    return (
      <div>
        {content?.checkList.map((item, index) => {
          return (
            <div className="text-darkGray bg-white flex items-center mb-[14px] md:mb-[1rem] xl:mb-[1.2rem] 2xl:mb-[1.56rem] font-bold  md:px-0 w-[90%] 2xl:w-[80%] gap-[1rem] md:gap-[12px] lg:gap-[1.8rem] xl:gap-x-[2.6rem] 2xl:gap-x-[3.12rem]">
              <span className="scale-50 lg:scale-75 xl:scale-90 2xl:scale-100">
                <CheckmarkSvg fillColor={content.checkMarkColor} size={32} />
              </span>
              <p className="text-start text-base md:text-[1.2rem] lg:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[1.87rem] text-gray font-subHeadline ">
                {item}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row justify-between p-[1rem] md:px-[1.6rem] xl:px-[4.68rem] py-[3rem] xl:py-[4.37rem]">
      <div className="w-full md:w-[45%] pt-[1.87rem] text-start">
        <h2 className="font-medium text-[2rem] md:text-[2.5rem] 2xl:text-[3rem] leading-tight mb-[1.2rem] xl:mb-[1.87rem] text-primary md:text-black">
          More about {title} and what you can do
        </h2>
        <p className="text-[1rem] lg:text-[1.2rem] xl:text-[1.4rem] 2xl:text-[1.87rem] text-start text-gray md:w-[92%] mb-[2.25rem] md:mb-[1.68rem]">
          {content?.paraOne}
        </p>
      </div>
      <div className="bg-white w-full md:w-[48%] md:ml-[2.62rem] md:rounded-[1.25rem] px-[1.25rem] py-[1.75rem] md:p-[2rem] xl:p-[2.75rem]">
        <h2 className="text-start text-[1.75rem] md:text-[2rem] lg:text-[2.2rem] xl:text-[2.7rem] 2xl:text-[3rem] text-primary leading-[2.62rem] mb-[10px] lg:mb-[1.4rem] xl:mb-[1.87rem]">
          This is right for you if
        </h2>
        {renderCheckList()}
      </div>
    </div>
  );
};

export default TreatmentInfoBanner;
