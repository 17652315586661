import { MakeBackendRequest } from "./MakeBackendRequest";

/**
 * API call to get user encounters for treatments
 * @param {*} userId - User ID
 * @returns user encounters array
 */
export const fetchEncounters = async (userId) => {
  const response = await MakeBackendRequest({
    urlSuffix: `/api/v1/users/${userId}/encounters`,
    method: "get",
    data: {},
  });

  return response.data;
};
