import React from "react";
import { Link } from "react-router-dom";

export const Button = ({ buttonProps }) => {
  const { buttonText, secondary, additionalClassNameStyles, href } =
    buttonProps;

  const tailWindStyles = `
    h-15
    ${secondary ? "text-white" : "text-darkPrimary"}
    font-bodyCopy
    border-2
    border-secondary
    rounded-full
    text-sm
    lg:text-md
    md:p-[0.75rem]
    lg:p-2
    ${secondary ? "" : "bg-secondary"}
    ${additionalClassNameStyles}
    `;

  const linkProps = {
    type: "button",
    className: tailWindStyles,
    style: {
      filter: "drop-shadow(-7px 8px 0px rgba(68, 0, 150, 0.4))",
    },
  };

  return href?.includes("#") ? (
    <a href={href}>
      <button {...linkProps}>{buttonText}</button>
    </a>
  ) : (
    <Link to={href}>
      <button {...linkProps}>{buttonText}</button>
    </Link>
  );
};
