const SectionCard = ({ title, content, className }) => (
  <div
    className={`relative w-[100%] h-auto md:w-[485px] md:min-h-[296px] m-[20px] md:m-[27px] bg-white shadow-lg rounded-md flex 
  flex-col pt-[20px] text-left px-[20px] hover:shadow-xl transition duration-200 ${className}`}
  >
    {title && (
      <h2 className="mb-[20px] font-subHeadline text-[20px]">{title}</h2>
    )}
    <div className="font-bodyCopy">{content}</div>
  </div>
);

export default SectionCard;
