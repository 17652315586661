import React from "react";
import { MdClose, MdSmsFailed } from "react-icons/md";
import { useAppContext } from "../../../context/AppContext";

const SurveyModal = () => {
  const { modalState, setModalState } = useAppContext();

  const closeModalHandler = () => {
    setModalState({
      switch: false,
      message: "",
      status: "",
    });
  };

  return (
    <>
      <div
        className="h-screen w-screen fixed z-50 bg-black top-0 opacity-40 blur-3xl"
        onClick={closeModalHandler}
      />
      <div className="h-[50%] sm:h-[26rem] w-[90%] sm:w-[32rem] fixed top-1/2 right-1/2 z-50 -translate-y-1/2 translate-x-1/2 bg-primary text-white rounded-lg shadow-2xl text-center p-4 flex flex-col">
        <div className="flex justify-end">
          <MdClose
            size={36}
            onClick={closeModalHandler}
            className="cursor-pointer"
          />
        </div>
        <div className="flex items-center justify-center flex-col flex-grow gap-8 mb-10">
          <MdSmsFailed size={108} />
          <p className="text-[16px] font-bold">{modalState.message}</p>
        </div>
      </div>
    </>
  );
};

export default SurveyModal;
