import { MakeBackendRequest } from "./MakeBackendRequest";

export const fetchSubscriptions = async (userId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/users/${userId}/user_subscriptions`,
      method: "get",
      data: {},
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};
