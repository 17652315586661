const WideSectionCard = ({ title, content, className }) => (
  <div
    className={`w-full h-auto md:w-[1030px] md:min-h-[296px] bg-white shadow-lg rounded-md flex 
  flex-col text-left p-[20px] hover:shadow-xl transition duration-200 ${className}`}
  >
    {title && (
      <h2 className="mb-[20px] font-subHeadline text-[20px]">{title}</h2>
    )}
    <div className="font-bodyCopy">{content}</div>
  </div>
);

export default WideSectionCard;
