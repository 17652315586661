import React from "react";
import { SurveyWizard } from "../../components/survey/SurveyWizard";
import { treatments } from "../../utils/treatments";
import _ from "lodash";

// TODO: Refactor to bring Preliminary Survey for
// this and all treatments under one file e.g(Herpes)
export const HerpesPreliminarySurvey = () => {
  const herpesSearchableTag =
    process.env.REACT_APP_HERPES_PRELIMINARY_SURVEY_SEARCHABLE_TAG;

  const surveyPath = _.find(treatments, {
    searchable_tag: herpesSearchableTag,
  })?.href;

  return (
    <SurveyWizard
      surveySearchableTag={herpesSearchableTag}
      treatmentSurveyPath={surveyPath}
    />
  );
};
