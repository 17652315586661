import { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { getUser } from "../services/user";
import { useNavigate } from "react-router-dom";
import { signout } from "../services/auth";

export const AppContext = createContext({});

export function AppProvider({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [pharmacies, setPharmacies] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [externalSurveys, setExternalSurveys] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [userSurveyInfo, setUserSurveyInfo] = useState([]);
  const [userTreatments, setUserTreatments] = useState([]);
  const [modalState, setModalState] = useState({
    switch: false,
    message: "",
    status: "",
  });

  const resetAppContext = () => {
    setUser({});
    sessionStorage.removeItem("auth");
  };

  const resetUserSurveyInfo = () => {
    setUserSurveyInfo({});
  };

  const isLoggedIn = () => !_.isEmpty(user?.email);

  const setAppState = (user) => {
    setUser(user);
    setUserSurveyInfo(user);
    setExternalSurveys(user?.external_surveys);
    setUserTreatments(user?.user_treatments);
  };

  const getUserSession = async (redirect) => {
    const userId = JSON.parse(sessionStorage.getItem("auth"))?.id;
    if (userId) {
      try {
        const userResponse = await getUser(userId);
        setAppState(userResponse);
        if (redirect) navigate("/dashboard");
      } catch (error) {
        sessionStorage.removeItem("auth");
        console.error(error.response);
      }
    }
    setLoading(false);
  };

  const signOutUser = async () => {
    try {
      await signout();
    } catch (error) {
      console.error(error.message);
    } finally {
      resetAppContext();
      navigate("/login");
    }
  };

  const value = {
    user,
    setUser,
    pharmacies,
    setPharmacies,
    resetAppContext,
    isLoggedIn,
    faqs,
    setFaqs,
    subscriptions,
    setSubscriptions,
    externalSurveys,
    setExternalSurveys,
    setAppState,
    notifications,
    setNotifications,
    userSurveyInfo,
    setUserSurveyInfo,
    resetUserSurveyInfo,
    modalState,
    setModalState,
    userTreatments,
    setUserTreatments,
    getUserSession,
    signOutUser,
  };

  useEffect(() => {
    getUserSession(true);
  }, []);

  if (loading) {
    return null;
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within the AppProvider");
  }
  return context;
}
