import { MakeBackendRequest } from "./MakeBackendRequest";

export const FetchSurveyByTag = async (searchableTag) => {
  const requestBody = {};

  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/surveys/${searchableTag}`,
      method: "get",
      data: requestBody,
    });

    const survey = response.data.data;
    return survey;
  } catch (error) {
    throw error;
  }
};
