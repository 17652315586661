import React, { useState } from "react";
import { Button } from "../button/Button";
import { MakeBackendRequest } from "../../services/MakeBackendRequest";
import { ErrorBanner } from "../errorBanner/ErrorBanner";
import { useNavigate } from "react-router-dom";

export const PatientPortalForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    prefix: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone: "",
    sex: "",
    gender: "",
    pronouns: "",
    password: "",
    address_line_1: "",
    address_line_2: "",
    address_city: "",
    address_state: "",
    address_zip: "",
  });

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const formClassName = `
    my-1
    rounded-full
    text-darkPrimary
    px-3
  `;

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestBody = convertFormDataToRequestBody(formData);
    const headers = {};

    MakeBackendRequest("/users", "post", requestBody, headers)
      .then((response) => {
        navigate("/");
      })
      .catch((e) => {
        setError(e.response.data.errors);
      });
  };

  const convertFormDataToRequestBody = (formData) => {
    return {
      user: {
        email: formData.email,
        prefix: formData.prefix,
        first_name: formData.first_name,
        last_name: formData.last_name,
        date_of_birth: formData.date_of_birth,
        phone: formData.phone,
        sex: formData.sex,
        gender: formData.gender,
        pronouns: formData.pronouns,
        password: formData.password,
      },
      address: {
        line_1: formData.address_line_1,
        line_2: formData.address_line_2,
        city: formData.address_city,
        state: formData.address_state,
        zip: formData.address_zip,
      },
    };
  };

  const capitalizeWords = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const buttonProps = {
    buttonText: "Register",
    secondary: false,
    additionalClassNameStyles: "text-lg px-20 hover:bg-darkSecondary",
  };

  const handleDismissError = () => {
    setError(null);
  };

  return (
    <div className="text-white my-4">
      {error && <ErrorBanner error={error} onDismiss={handleDismissError} />}
      Please style me 😭 I am a sad little registration form
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => {
          return (
            <div key={key}>
              <label htmlFor={key}>{capitalizeWords(key)}: </label>
              <input
                type={key === "password" ? "password" : "text"}
                id={key}
                name={key}
                value={formData[key]}
                onChange={handleChange}
                className={formClassName}
              />
            </div>
          );
        })}
        <button type="submit">
          <Button buttonProps={buttonProps} />
        </button>
      </form>
    </div>
  );
};
