import React, { useState, useRef, useEffect } from "react";

const Slider = ({ children, className }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const numberOfSlides = React.Children.count(children);
  const startX = useRef(0);
  const endX = useRef(0);

  const goToSlide = (index) => {
    if (index >= 0 && index < numberOfSlides) {
      setCurrentSlide(index);
    }
  };

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    endX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const deltaX = startX.current - endX.current;

    if (deltaX > 50) {
      goToSlide(currentSlide + 1);
    } else if (deltaX < -50) {
      goToSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToSlide((currentSlide + 1) % numberOfSlides);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, numberOfSlides]);

  return (
    <div
      className={`relative w-full overflow-hidden ${className}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="absolute z-50 bottom-0 left-1/2 transform -translate-x-1/2 flex gap-4 md:gap-x-[1.87rem]">
        {Array.from({ length: numberOfSlides }).map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`text-[30px] rounded-full focus:outline-none ${
              currentSlide === index ? "text-white" : "text-darkPrimary"
            }`}
          >
            •
          </button>
        ))}
      </div>
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{
          transform: `translateX(-${100 * currentSlide}%)`,
          width: "100%",
        }}
      >
        {React.Children.map(children, (child, index) => (
          <div className="w-full shrink-0" key={index}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
