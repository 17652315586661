import { useEffect, useState } from "react";

export const useWindowDimensions = () => {
  const [windowWidth, setWindowWidth] = useState(null);
  const [windowHeight, setWindowHeight] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const setDimension = () => {
    setWindowWidth(window && window.innerWidth);
    setWindowHeight(window && window.innerHeight);
    setIsMobile(window ? window.innerWidth < 768 : false);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    setDimension();

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, []);

  return { windowWidth, windowHeight, isMobile };
};
