import React, { useState, useEffect } from "react";
import { getMessagesLink } from "../../services/messages";
import Loader from "../../components/Loader";
import { fetchEncounters } from "../../services/encounters";
import { useQuery } from "@tanstack/react-query";
import docChatIcon from "../../assets/icons/doc-user-message.png";
import _ from "lodash";
import { useAppContext } from "../../context/AppContext";

const ChatRoom = () => {
  const { user, userTreatments } = useAppContext();

  const { data: encounters } = useQuery({
    queryKey: ["fetchEncounters"],
    queryFn: () => fetchEncounters(user?.id),
  });

  const [selectedEncounter, setSelectedEncounter] = useState();

  useEffect(() => {
    if (encounters?.length) {
      setSelectedEncounter(encounters[0]);
    }
  }, [encounters]);

  return (
    <div className="flex flex-col md:flex-row w-full h-full shadow-md relative">
      <div
        className="md:h-full w-full
        md:w-[10rem] bg-transparent flex flex-col
        items-center justify-start"
      >
        {encounters?.map((encounter) => (
          <div
            className={`w-full h-[5rem] cursor-pointer
            mb-2  flex flex-col
            items-start justify-center px-4
            shadow-md rounded-sm transition-all hover:
            duration-300 z-10 border border-lightGray ${
              encounter?.id === selectedEncounter?.id
                ? "bg-primary text-white bg-opacity-100 w-[103%] ml-2"
                : "bg-white bg-opacity-20"
            }`}
            onClick={() => setSelectedEncounter(encounter)}
          >
            <p className="text-md font-semibold">
              {_.capitalize(
                _.find(userTreatments, {
                  treatment_id: encounter?.treatment_id,
                })?.treatment_name
              )}{" "}
              Chatroom
            </p>
          </div>
        ))}
      </div>
      <div className="h-full flex-1">
        {!_.isEmpty(selectedEncounter) ? (
          <iframe
            title="Embedded Content"
            src={selectedEncounter?.messaging_link}
            allowFullScreen
            className="h-full w-full"
          ></iframe>
        ) : (
          <div
            className="absolute top-1/2 left-1/2 -translate-x-1/2 
          -translate-y-1/2 flex items-center justify-center opacity-60"
          >
            <img src={docChatIcon} className="w-7/5" />
          </div>
        )}
      </div>
    </div>
  );
};

const Messages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const getMessagesIFrame = async () => {
    try {
      const messagesLink = await getMessagesLink();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getMessagesIFrame();
  }, []);

  return (
    <div className="h-[85vh] bg-white flex items-center justify-center">
      {isLoading ? <Loader /> : <ChatRoom />}
    </div>
  );
};

export default Messages;
