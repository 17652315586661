import React, { useState } from "react";
import { colors } from "../../theme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import closeIcon from "../../assets/icons/close.png";
import backIcon from "../../assets/icons/arrow_back_ios.png";
import dauchiLogo from "../../assets/icons/DauchiLogo.png";
import {
  loggedInHeaderRoutes,
  dashboardRoutes,
  profileRoute,
} from "../../utils/routes";
import { useAppContext } from "../../context/AppContext";

export const HamburgerMenuSvgButton = ({ navMenuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split("/");
  const { resetAppContext } = useAppContext();

  let loggedInDashboardItems = [...loggedInHeaderRoutes];
  loggedInDashboardItems = loggedInDashboardItems.filter(
    (item) => item.mobileVisibility === true
  );

  const secondary = colors.secondary;

  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

  const toggleMenu = () => {
    setDropDownIsOpen(!dropDownIsOpen);
  };

  const getNavigationItem = (item) => {
    return (
      <div className="flex justify-between items-center mb-[1.5rem]">
        <div className="flex items-center gap-[1rem]">
          {item.icon && (
            <img
              src={item.icon}
              alt={item.text}
              className="h-[1.5rem] w-[1.5rem]"
            />
          )}
          <p className="text-[20px] font-normal">{item.text}</p>
        </div>
        <div>
          <img src={backIcon} alt="back" />
        </div>
      </div>
    );
  };

  const dashboardNavigationItems = (navItems) => {
    return (
      <div className="flex flex-col">
        {navItems.map((navItem, index) =>
          navItem.href?.includes("#") ? (
            <a
              href={navItem.href}
              key={index}
              id={`menu-item-${index}`}
              onClick={toggleMenu}
            >
              {getNavigationItem(navItem)}
            </a>
          ) : (
            <Link
              key={index}
              to={navItem.href}
              id={`menu-item-${index}`}
              onClick={toggleMenu}
            >
              {getNavigationItem(navItem)}
            </Link>
          )
        )}
      </div>
    );
  };

  const handleNavItemClick = (navItem) => {
    if (navItem.text === "Log out") {
      resetAppContext();
      navigate("/login");
      toggleMenu();
    } else {
      navigate(navItem.href);
      toggleMenu();
    }
  };

  const renderLoggedInNav = (navItems) => {
    return (
      <div className="flex flex-col">
        {navItems.map((navItem, index) => (
          <button key={index} onClick={() => handleNavItemClick(navItem)}>
            {getNavigationItem(navItem)}
          </button>
        ))}
      </div>
    );
  };

  const dashboardNavbar = () => {
    return (
      <div className="">
        <div className="m-[0.5rem] mb-[1rem]">
          <img
            src={closeIcon}
            alt="close"
            className="w-[2rem] h-[2rem] hover:cursor-pointer"
            onClick={toggleMenu}
          />
        </div>
        <div className="flex justify-start mb-[1rem]">
          <p className="mx-[7%] text-primary font-normal">Navigate</p>
        </div>
        <div className="mx-[12%]">
          {currentDashboard ? (
            <>
              {dashboardNavigationItems(dashboardRoutes)}
              <div className="relative flex items-center justify-center my-[1rem] mb-[1.5rem]">
                <div className="h-[1px] w-full absolute bg-secondary -z-10" />
                <img
                  src={dauchiLogo}
                  alt="dauchi"
                  className="w-[2.5rem] h-[2.5reem]"
                />
              </div>
              {renderLoggedInNav(loggedInDashboardItems)}
            </>
          ) : (
            dashboardNavigationItems(navMenuItems)
          )}
        </div>
      </div>
    );
  };

  const currentDashboard = pathSegments[1] === "dashboard" ? true : false;

  const dropDownMenu = (
    <div
      className={`font-bodyCopy right-0 z-10 origin-top-right top-0 h-screen w-screen bg-white fixed`}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      {dashboardNavbar()}
    </div>
  );

  const hamburgerMenuStyles = `${
    dropDownIsOpen ? "border rounded-md border-secondary" : "font-bold"
  }`;

  return (
    <>
      <button
        type="button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={toggleMenu}
      >
        <svg
          width="48px"
          height="48px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={hamburgerMenuStyles}
        >
          <path d="M5 6.5H19V8H5V6.5Z" fill={secondary} />
          <path d="M5 16.5H19V18H5V16.5Z" fill={secondary} />
          <path d="M5 11.5H19V13H5V11.5Z" fill={secondary} />
        </svg>
      </button>
      {dropDownIsOpen ? dropDownMenu : null}
    </>
  );
};
