import { MakeBackendRequest } from "./MakeBackendRequest";

export const SubmitSurveyData = async (surveyData, surveySearchableTag) => {
  const requestBody = { surveyData, surveySearchableTag };

  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/user_surveys/upsert/${surveySearchableTag}`,
      method: "put",
      data: requestBody,
    });

    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};
