import { MakeBackendRequest } from "./MakeBackendRequest";

export const FetchConsentPdf = async ({ userId }) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/user_surveys/${userId}/consent_forms`,
      method: "post",
      data: {},
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const DeleteConsentPdf = async ({ userId }) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/user_surveys/${userId}/consent_forms`,
      method: "delete",
      data: {},
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};
