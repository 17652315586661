import React from "react";
import { Logo } from "../logo/Logo";
import { HamburgerMenuSvgButton } from "../svgs/HamburgerMenuSvgButton";

export const MobileNavbar = ({ navMenuItems }) => {
  return (
    <div className="bg-darkPrimary p-2 flex items-center relative z-[900]">
      <div className="flex flex-grow ml-2">
        <Logo />
      </div>
      <HamburgerMenuSvgButton navMenuItems={navMenuItems} />
    </div>
  );
};
