import { defaultHeaders, MakeBackendRequest } from "./MakeBackendRequest";

export const signin = async (data) => {
  const res = await MakeBackendRequest({
    urlSuffix: `/users/auth/sign_in`,
    method: "post",
    data,
  });

  const authorization = res.headers["authorization"];
  const response = res.data;

  // Store user session
  sessionStorage.setItem(
    "auth",
    JSON.stringify({
      authToken: authorization,
      id: response.data.id,
    })
  );

  return response;
};

export const signout = async (data) => {
  const response = await MakeBackendRequest({
    urlSuffix: `/users/auth/sign_out`,
    method: "delete",
    data,
  });
  sessionStorage.removeItem("auth");

  return response.data;
};

export const signup = async (credentials, userId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/users/${userId}`,
      method: "put",
      data: credentials,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (data) => {
  const response = await MakeBackendRequest({
    urlSuffix: `/users/auth/password`,
    method: "post",
    data,
  });

  return response.data;
};

/**
 * Method update password for a user
 * @param {*} data - Request data to submit
 * @param {*} authHeaders -  Authorization Headers for a User
 * @returns Object containing user and success message
 */
export const resetPassword = async (data, authHeaders) => {
  const headers = {
    ...defaultHeaders,
    ...authHeaders,
  };
  const response = await MakeBackendRequest({
    urlSuffix: `/users/auth/password`,
    method: "put",
    data,
    headers,
  });

  return response.data;
};

// /**
//  * Request to resend an OTP
//  * on provided user phone number.
//  * @param {number} phone - User phone number
//  * @returns Object response from the api
//  */
export const resendOtp = async (phone) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/verifications/send_phone_otp`,
      method: "post",
      data: { phone },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * To verify the mobile OTP for a user
 * @param {object} otpData - Data to confirm
 * otp value against a phone number.
 * @returns Object response from the api
 */
export const verifyOtp = async (otpData) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/verifications/verify_phone_otp`,
      method: "post",
      data: otpData,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
