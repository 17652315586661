import { MakeBackendRequest } from "./MakeBackendRequest";

export const clearSingleNotification = async (userId, notificationId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/api/v1/users/${userId}/notifications/${notificationId}`,
      method: "put",
      data: {
        read: true,
        read_at: Date.now(),
      },
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};
