export const fontFamily = {
  headline: ["Playfair Display", "Arial", "sans-serif"],
  subHeadline: ["Work Sans"],
  bodyCopy: ["Work Sans"],
  inter: ["Inter", "sans-serif"],
};

export const fontWeight = {
  headline: "900",
  subHeadline: "400",
  bodyCopy: "500",
};

export const colors = {
  lightPrimary: "#A499F7",
  primary: "#695BD6",
  mediumPrimary: "#453A9B",
  darkPrimary: "#2A1D95",
  lightPurple: "#ece9ff",
  secondary: "#F5D447",
  darkSecondary: "#f8c92a",
  lightSecondary: "#fce892",
  gray: "#77747f",
  lightGray: "#eaeaea",
  darkGray: "#4d4d4d",
  white: "#fff",
  whiteSecondary: "#FCF9FF",
  inputGray: "#707070",
  inputGraySecondary: "#7D7D86",
  borderGray: "#707070",
  borderGraySmall: "#D9D9D9",
  statusYellow: "rgb(254, 240, 138)",
  statusGreen: "rgb(220, 252, 231)",
  statusRed: "rgb(252, 165, 165)",
  yellowMustard: "#FFD481",
  lightGreen: "#BDF3FF",
  success: {
    DEFAULT: "#D1FAE5",
    text: "#065F46",
  },
  warning: {
    DEFAULT: "#FCE892",
    text: "#927B1D",
  },
  critical: {
    DEFAULT: "#FF9A9A",
    text: "#8C1D1D",
  },
  ordersBorder: "#C4BCFF",
  treatmentBackground: "#ffe7e5",
  treatmentCheckmark: "#CA9351",
  treatmentHeroBackground: "#5F51CA",
  treatmentSlidesBackground: "#4443B0D9",
  treatmentGetsWhatNeededBackGround: "#C4BCFF",
  lavenderBlue: "#CBC5FF",
  yellow: "#FFF4C2",
  "light-blue": "#C3FAFF",
};

export const fontSize = {
  xs: "0.7rem",
  sm: "0.8rem",
  md: "0.9rem",
  base: "1rem",
  lg: "1.12rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "2rem",
  "4xl": "2.5rem",
  "5xl": "3rem",
  "6xl": "3.5rem",
  "7xl": "5rem",
  "8xl": "6rem",
};

export const backgroundImage = {
  desktopHero: "url('/src/assets/images/DesktopHeroImage1.png')",
  mobileHero: "url('/src/assets/images/MobileHeroImage.png')",
  hsvBackground: "url('/src/assets/images/HsvHeroBackground.png')",
};
