import React from "react";
import { useNavigate } from "react-router-dom";

const HeroSlide = ({ title, content, surveyPath }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center bg-opacity-60 bg-black md:bg-treatmentSlidesBackground h-full py-[3.8rem] md:py-[3.2rem] 2xl:py-[4rem] px-[1.25rem] md:px-[3rem] 2xl:px-[3.75rem]">
      <p className="text-[2.5rem] md:text-[2.8rem] lg:text-[3.5rem] 2xl:text-[4.43rem] leading-[3.4rem] 2xl:leading-[5.18rem] font-medium text-white mb-[1.2rem]">
        {title}
      </p>
      <p className="text-[1.25rem] md:text-[1.1rem] lg:text-[1.5rem] 2xl:text-[2.31rem] text-white leading-[2.3rem] xl:leading-[2.6rem] 2xl:leading-[2.87rem] mb-[1.87rem]">
        {content}
      </p>
      <button
        className="text-darkPrimary text-[1rem] lg:text-[1.2rem] 2xl:text-[1.68rem] w-[50%] lg:w-[70%] 2xl:w-[50%] rounded-full font-semibold py-[12px] lg:py-[1rem] 2xl:py-[1.2rem] bg-secondary"
        onClick={() => navigate(surveyPath)}
      >
        GET STARTED
      </button>
    </div>
  );
};

export default HeroSlide;
