import React from "react";
import "./TreatmentFooterHero.scss";

const TreatmentFooterHero = ({ content, common }) => {
  return (
    <div className="w-full relative">
      <div className="relative p-[1rem] xl:p-[1.87rem] w-full h-full">
        <div className="w-full rounded-md md:rounded-[3.12rem] text-start py-[10px] md:pt-[1.8rem] 2xl:pt-[2.12rem] p-[2rem] lg:pb-[2.5rem] xl:pb-[2.5rem] 2xl:pb-[3.25rem] px-[10px] md:px-[1.5rem] lg:px-[3rem] relative bg-treatmentHeroBackground flex flex-col-reverse md:flex-row items-center justify-between gap-x-[2rem] overflow-hidden">
          <div className="w-full h-full md:h-auto md:w-auto relative z-40">
            <div className="relative z-30 h-full w-full flex item justify-center bg-mediumPrimary bg-opacity-70 pb-[1rem] md:pb-0 md:bg-transparent">
              <img
                src={content.image}
                className="h-[240px] lg:h-[340px] 2xl:h-[500px] w-[240px] lg:w-[340px] 2xl:w-[500px] rounded-full"
                alt="Dauchi Treatment Footer"
              />
            </div>
            <img
              src={common.eclipse}
              alt="footer eclipse"
              className="absolute z-10 h-1/2 w-full top-0 right-[-30%] transform scale-x-[-1.8] scale-y-[2] md:hidden scale-150 rotate-[-25deg]"
            />
          </div>
          <div className="relative z-40 flex bg-mediumPrimary bg-opacity-70 py-[3.2rem] 2xl:py-[4rem] px-[1.25rem] md:px-[3rem] 2xl:px-[3.75rem] flex-col w-full md:w-[68%] rounded-md md:rounded-[3.5rem] lg:rounded-[6.43rem]">
            <p className="text-[1.75rem] lg:text-[2.4rem] 2xl:text-[3rem] font-medium text-white mb-[1.2rem]">
              {content.question}
            </p>
            <p className="text-[14px] lg:text-[1.5rem] 2xl:text-[2.25rem] text-white leading-relaxed mb-[1.87rem]">
              {content.answer}
            </p>
          </div>
          <img
            src={common.eclipse}
            alt="footer eclipse"
            className="absolute z-10 h-1/2 w-full top-0 right-0 transform scale-x-[-1] hidden md:block"
          />
        </div>
      </div>
    </div>
  );
};

export default TreatmentFooterHero;
