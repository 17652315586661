export const termsContent = () => (
  <div className="text-[14px] lg:text-base">
    <p>
      IMPORTANT: PLEASE READ THESE TERMS CAREFULLY AS THEY MAY IMPACT YOUR LEGAL
      RIGHTS IN THE EVENT OF A DISPUTE BETWEEN US. SPECIFICALLY, PLEASE REFER TO
      SECTION 17 BELOW, WHICH REQUIRES THAT CERTAIN DISPUTES BE SETTLED THROUGH
      MANDATORY BINDING ARBITRATION WHICH WILL PRECLUDE YOU FROM LEADING OR
      PARTICIPATING IN A CLASS ACTION.
    </p>
    <br />
    <p>
      AMONG OTHER ACTIVITIES, DAUCHI'S SERVICES ENABLE COORDINATION AND
      COMMUNICATION WITH A HEALTH CARE PROVIDER. IT DOES NOT REPLACE YOUR
      RELATIONSHIP WITH ANY PHYSICIAN.
    </p>
    <br />
    <p>
      THESE SERVICES MIGHT NOT BE APPROPRIATE FOR ALL MEDICAL CONDITIONS OR
      CONCERNS. IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY OR MENTAL HEALTH
      CRISIS, CALL YOUR DOCTOR OR 911 IMMEDIATELY. DO NOT ACCESS THE SERVICES
      FOR EMERGENCY OR CRISIS CARE.
    </p>
    <br />
    <p>
      <p>Introduction</p>
      <br />
      Dauchi, owned and operated by Derma Corp, and its affiliates including
      HerpAlert and other related entities (collectively, "Dauchi," "we", "us",
      or "our") own and operate the websites located at www.dauchi.com and other
      associated mobile applications ("Apps"). We refer to the Websites, Apps,
      and other services provided by Dauchi together herein as the "Services."
      In these Terms of Use, the terms "you" and "yours" refer to the person
      using the Services.
    </p>
    <br />
    <p>
      These terms and conditions of use ("Terms of Use") describe your rights
      and responsibilities with regard to the Services. Your access to and use
      of the Services is subject to these Terms of Use, our Privacy Policy, as
      well as all applicable laws and regulations. In using certain parts of the
      Services, you may be presented with additional or supplementary terms
      regarding the use of those specific Services, and you agree to review and
      be bound by such additional terms.
    </p>
    <br />
    <p>
      PLEASE READ THESE TERMS OF USE CAREFULLY BECAUSE THEY SET FORTH THE
      IMPORTANT TERMS YOU WILL NEED TO KNOW ABOUT THE SERVICES.
    </p>
    <br />
    <p>
      YOU UNDERSTAND THAT BY ACCESSING OR USING THE SERVICES, YOU ACKNOWLEDGE
      THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE LEGALLY BOUND BY AND
      COMPLY WITH THESE TERMS OF USE. IF YOU DO NOT OR CANNOT AGREE WITH ANY
      PART OF THESE TERMS OF USE, YOU MAY NOT USE THE SERVICES.
    </p>
    <br />
    <p>THE TERMS OF USE ARE SUBJECT TO CHANGE AS PROVIDED HEREIN.</p>
    <br />
    <p>These Terms of Use Contain the Following Sections:</p>
    <ul className="m-2">
      {[
        "Services Overview, Availability, and Eligibility",
        "Telehealth, Pharmacy, and At-Home Testing Services",
        "Registration and Account Creation",
        "Privacy",
        "Ownership and License to Use the Services",
        "User Content and Dauchi's License to Use Such Content",
        "Copyright Notices",
        "Restrictions on Use",
        "Disclaimer of Warranties",
        "Limitation of Liability",
        "Indemnification",
        "Third-Party Links and Features on the Services",
        "Changes to the Terms of Use and the Services",
        "Payments",
        "Termination",
        "Governing Law; Severability",
        "Dispute Resolution: Binding Arbitration Clause and Class Waiver",
        "Communications by Text Message and Email",
        "Miscellaneous Terms",
        "Contact Us",
        "Additional Terms for Specific Products and Services",
        "Notice to Patients - Open Payments Database",
      ].map((point, index) => (
        <li key={index} className="list-inside list-disc">
          {point}
        </li>
      ))}
    </ul>
    <br />
    <p className="font-bold">
      1. Services Overview, Availability, and Eligibility
    </p>
    <br />
    <p>
      Overview of the Services. The Services may include (i) providing
      individuals with information on health care and wellness; (ii) providing
      individuals with pharmacy services; (iii) providing individuals with
      access to technology-oriented tools for addressing certain health issues;
      (iv) development and gathering of health care records and health care
      information with retention of the same for use in health care provider
      appointments, communications, and pharmacy services; (v) administrative
      support in connection with scheduling, payment for health care provider
      services, and payment for pharmacy services; and (vi) telecommunications
      support for using the Services as a means of direct access to a health
      care provider for communication, consultations, assessments, and treatment
      by such health care organizations and their providers.
    </p>
    <br />
    <p>
      Availability. Certain of our Services are currently only available to
      individuals located in certain states. You will be provided with notice of
      such limitations on availability of certain Services in your location when
      using those parts of the Services.
    </p>
    <br />
    <p>
      Dauchi is based in the United States. We provide the Services for use only
      by persons located in the United States. We make no claims or
      representations that the Services or any material included in the Services
      are accessible or appropriate outside of the United States. Access to the
      Services may not be legal by certain persons or in certain countries. If
      you access the Services from outside the United States, you do so at your
      own risk and you are solely responsible for compliance with local laws,
      including export laws as applicable.
    </p>
    <br />
    <p>
      Eligibility. Our Services are intended for use by you only if you are 18
      years of age or over. To qualify to use the Services, the following must
      be true, and by accessing or using the Services, you represent and warrant
      that they are true:
    </p>
    <ul className="m-2">
      {[
        "You are age 18 or over or, if you are accessing the Services in violation of the Eligibility requirement related to age, you otherwise have the express consent of your parent or legal guardian. Note, however, that some Services may only be available to individuals over the age of 21, and in such circumstances, you will be notified of this requirement.",
        "You are located in a state where we operate (depending on the type of Services).",
        "You agree to be legally bound by and comply with these Terms of Use.",
      ].map((point, index) => (
        <li key={index} className="list-inside list-disc">
          {point}
        </li>
      ))}
    </ul>
    <br />
    <p>
      You understand and agree that satisfying the above requirements does not
      guarantee that you will receive Services. In addition to the above
      requirements, Dauchi and certain affiliated professional entities reserve
      the right to change or include new requirements as deemed appropriate in
      their sole discretion without providing prior notice to you.
    </p>
    <br />
    <p>
      To access or use the Services, you must have compatible devices, access to
      the Internet, and certain necessary software. Fees and charges may apply
      to your use of mobile services and to the Internet.
    </p>
    <br />
    <br />
    <p className="font-bold">
      2. Telehealth, Pharmacy, and At-Home Testing Services
    </p>
    <br />
    <p>
      Telehealth Services. Telemedicine involves the delivery of health care
      services using electronic communications, information technology, or other
      means between a health care provider and a patient who are not in the same
      physical location. Telemedicine may be used for diagnosis, treatment,
      follow-up and/or related patient education, and may include, but is not
      limited to:
    </p>
    <ul className="m-2">
      {[
        "Electronic transmission of medical records, photo images, personal health information, or other data between a patient and health care provider;",
        "Interactions between a patient and health care provider via audio, video, and/or data communications; and",
        "Use of output data from medical devices, sound, and video files.",
      ].map((point, index) => (
        <li key={index} className="list-inside list-disc">
          {point}
        </li>
      ))}
    </ul>
    <br />
    <p>
      The electronic systems used in the Services will incorporate network and
      software security protocols to protect the privacy and security of health
      information, and will include measures to safeguard your data to ensure
      its integrity against intentional or unintentional corruption or unlawful
      access. By accepting the Terms of Use, you agree and consent to Dauchi,
      the affiliated physician practices, the health care providers contracted
      or employed by the affiliated physician practices, and any other health
      care organization(s) or provider(s) with whom we partner to provide the
      Services to you sending you disclosures, notices, messages, reports, and
      other communications either in writing or electronically including without
      limitation through your account or by email. It is your responsibility to
      monitor these communications. You acknowledge and agree that you will not
      hold us, any affiliated physician practice, any health care provider
      contracted or employed by an affiliated physician practice, or any health
      care organization(s) or provider(s) with whom we partner to offer the
      Services to you liable for any loss, injury, or claim of any kind
      resulting from your failure to read these communications or for your
      failure to comply with any treatment recommendations contained in these
      communications. Moreover, you acknowledge and agree that no means of
      electronic transmission can be guaranteed to be 100% secure. By accessing
      or using the Services, you agree to us transmitting health and other
      information to you electronically and that any information sent to or
      signed by you or us electronically shall be deemed equivalent to as if
      such information was provided or signed in writing.
    </p>
    <br />
    <p>
      Please see our Consent to Telehealth available at
      dauchi.com/consent-to-telehealth for additional information about the
      risks and benefits of telehealth. The Consent to Telehealth is hereby
      incorporated into these Terms of Use by reference and constitutes a part
      of these Terms of Use. Please also see Section 21 below for additional
      terms applicable to the use of certain services and products on the
      Services.
    </p>
    <br />
    <p>
      Your Relationship with Dauchi. Dauchi does not provide any medical
      services, including via the Services. Rather, Dauchi provides a technology
      platform for you to access a health care provider who is employed or
      contracted with a Dauchi affiliated physician practice and obtain access
      to additional information, which you may or may not choose to utilize in
      planning your health care and wellness. The health and wellness resources
      made available through our Services are for informational purposes only,
      and are not a substitute for direct in-person health care services in all
      cases; nor are they an indicator of specific results. The decision to
      focus on diagnosis, treatment recommendations, or both, rests with you and
      the health care provider. You understand that by coordinating and
      consulting with an affiliated physician practice or its health care
      providers through the Services, you are not entering into a
      provider-patient relationship with Dauchi.
    </p>
    <br />
    <p>
      Except for specific communications received from an affiliated physician
      practice or its health care providers, none of the information you receive
      through the Services should be considered medical advice.
    </p>
    <br />
    <p>
      No Insurance Accepted; Notice of Your Financial Responsibility. Dauchi and
      the affiliated physician practices do not accept commercial health
      insurance plans, are not in-network with any commercial health insurance
      plans, and are not enrolled with federal or state health care programs,
      such as Medicare and Medicaid. By choosing to use the Services, you are
      specifically choosing to obtain products and services on a cash-pay basis
      outside of any commercial health insurance plan or federal or state health
      care program. Thus, you are solely responsible for the costs of any
      services or products provided to you. If you are a federal health program
      beneficiary, you agree that neither you, Dauchi, the affiliated physician
      practices, or any of the health care organization(s) or provider(s) with
      whom we partner to provide health care and pharmacy services to you will
      submit a claim for reimbursement to any federal or state healthcare
      program for the costs of the services and products provided to you through
      the Services. Please also see Section 14 below for additional terms
      applicable to your payment obligations.
    </p>
    <br />
    <p>
      Pharmacy Services. If you receive a prescription as a result of your use
      of the Services, you may select Honeybee Health LLC or one of our partner
      pharmacies (collectively, the "Dauchi Pharmacy Network") to ship your
      prescription. You may also choose to have your prescription fulfilled
      through the pharmacy of your choice when prompted during your use of the
      Services. You give us consent to send and disclose to the Dauchi Pharmacy
      Network or the pharmacy of your choice all information provided by you,
      health care records, and other applicable health care information and
      personal information (such as your name, location and demographic
      information) so that you may receive pharmacy services.
    </p>
    <br />
    <p>
      If you select to use the Dauchi Pharmacy Network to fulfill any
      prescriptions, you acknowledge that your medication will not be shipped in
      child-resistant packaging and that you must keep it out of the reach of
      children.
    </p>
    <br />
    <p>At-home Testing Services.</p>
    <br />
    <p>
      At-home sample collection kits and laboratory services available through
      the Services may require a valid order by a licensed health care provider.
      We or our subsidiaries have partnered with a health care provider who is
      licensed in your state to review your request for at-home testing (the
      "Reviewing Provider"). You understand and agree that at-home testing
      services are intended for informational, educational, and wellness
      purposes only. They are not intended to be diagnostic. You further
      understand and agree that you do not establish a provider-patient
      relationship with the Reviewing Provider as a result of your use of the
      Services.
    </p>
    <br />
    <p>
      We will send or cause to be sent the requested test kit to your address
      after any required health care provider review and approval. You must
      follow the instructions that accompany the kit to register your kit,
      collect the applicable biological sample(s), complete any required
      documentation, and return such biological samples and documentation to the
      applicable laboratory that performs the testing (the "Lab"). Failure to
      follow the instructions may result in your samples not being processed by
      the Lab.
    </p>
    <br />
    <p>
      The Lab sends the results of the tests to the Reviewing Provider who
      releases the test results to us. We provide the test results to you via
      the Services. You understand and agree that it is your responsibility to
      bring your results to your primary health care provider for interpretation
      and to inform decisions made about your health and treatment.
    </p>

    <br />
    <br />
    <p className="font-bold">3. Registration and Account Creation</p>
    <br />
    <p>
      Although certain parts of the Services are accessible without creating an
      account, you may be required to create an account to access and use
      certain parts of the Services. If you create an account, you agree to
      provide information that is accurate, complete and correct, and to
      accurately maintain and update any information about yourself that you
      have provided to Dauchi. If you do not maintain such information, or
      Dauchi has reasonable grounds to suspect as much, Dauchi has the right to
      suspend or terminate your account and your use of the Services. You agree
      to keep confidential your username and password and to exit from your
      account at the end of each session. You are responsible for all activities
      that occur under your account and for maintaining the confidentiality of
      your password. You also agree to immediately notify Dauchi of any
      unauthorized use of your username, password or any other breach of
      security that you become aware of involving or relating to the Services by
      emailing Dauchi at support@dauchi.com. Dauchi may take any and all actions
      it deems necessary or reasonable to maintain the security of the Services.
    </p>
    <br />
    <p>
      You are responsible for changing your password promptly if you think it
      has been compromised. You may not transfer or share your password with
      anyone, or create more than one account. You may not use anyone else's
      account at any time. Dauchi explicitly disclaims liability for any and all
      losses and damages arising from your failure to comply with this section.
    </p>
    <br />
    <p>
      Our Apps are intended for use only on mobile phones that run an unmodified
      manufacturer approved operating system. Using the Apps on a mobile phone
      with a modified operating system may undermine security features that are
      intended to protect your protected health information and other
      information from unauthorized or unintended disclosure. As a result, you
      may compromise your information if you use the App on a mobile phone that
      has been modified. Use of the App on a mobile phone with a modified
      operating system is a material breach of these Terms of Use.
    </p>
    <br />
    <br />
    <p className="font-bold">4. Privacy</p>
    <br />
    <p>
      Dauchi understands the importance of confidentiality and privacy regarding
      your health information. Please see our Privacy Policy available at
      dauchi.com/privacy-policy for information about how we collect and use
      your information The Privacy Policy is hereby incorporated into these
      Terms of Use by reference and constitute a part of these Terms of Use.
    </p>
    <br />
    <br />
    <p className="font-bold">5. Ownership and License to Use the Services</p>
    <br />
    <p>
      Ownership. As between Dauchi and you, Dauchi is the sole and exclusive
      owner of all right, title and interest in and to the Services and their
      content, features and functionality (including, without limitation, all
      information, software, text, displays, images, video, audio, design,
      selection, arrangement and look and feel) ("Services Content"). You are
      not permitted to reproduce, publish, distribute, modify, reverse engineer,
      disassemble, create derivative works of, publicly display, publicly
      perform, republish, download, store, transmit, sell or participate in any
      sale of, or exploit in any way, in whole or in part, any of the Services
      or Services Content except as permitted by these Terms of Use or otherwise
      by Dauchi expressly in writing. Any copy, modification, revision,
      enhancement, adaptation, translation, or derivative work of the Services
      or Services Content shall be owned solely and exclusively by Dauchi or its
      licensors, including all intellectual property rights therein. You may not
      access or use for any commercial purposes any part of the Services or
      Services Content.
    </p>
    <br />
    <p>
      Certain names, logos, and other materials displayed in and through the
      Services may constitute trademarks, trade names, service marks or logos
      ("Marks") of Dauchi or its affiliates. You are not authorized to use any
      such Marks without the express written permission of Dauchi. Ownership of
      all such Marks and the goodwill associated therewith remains with us or
      our affiliates.
    </p>
    <br />
    <p>
      Your License. Subject to your compliance with these Terms of Use, we
      hereby grant to you a personal, limited, revocable, non-exclusive, and
      nontransferable right to view, download, access, and use the Services and
      Services Content solely for your personal and non-commercial use and only
      as permitted under these Terms of Use and the Privacy Policy. No other
      right, title, or interest in or to the Services or Services Content is
      transferred to you, and all rights not expressly granted are reserved by
      Dauchi or its licensors.
    </p>
    <br />
    <br />
    <p className="font-bold">
      6. User Content and Dauchi's License to Use Such Content
    </p>
    <br />
    <p>
      Except as provided in our Privacy Policy; or information governed by
      applicable federal and state-specific privacy laws and regulations; you
      understand and agree that any information you provide through the
      Services, whether by direct entry, submission, email or otherwise,
      including, but not limited to, data, questions, comments, forum
      communications, or suggestions/feedback, will be treated as
      non-confidential and non-proprietary and will become the property of
      Dauchi and/or (i) the affiliated physician practices, or (ii) individual
      health providers utilizing the Services ("User Content").
    </p>
    <br />
    <p>
      Except as provided in our Privacy Policy or subject to any applicable
      laws, User Content may be used by Dauchi for any purpose, including,
      without limitation, reproduction, solicitation, disclosure, transmission,
      publication, broadcast, and posting, and Dauchi shall be free to use such
      User Content for any purpose whatsoever, including, without limitation,
      developing and marketing products using such information, without any
      compensation owed to you. You hereby grant Dauchi, our service providers,
      our successors and assigns, our affiliated health care providers, and
      their affiliated physician practices, the fully transferable and
      sublicensable right and license to use, reproduce, modify, analyze,
      perform, display, distribute, and otherwise disclose to third parties any
      data or information you submit on or through the Services for the purposes
      of providing Services to you; marketing Services to you; conducting
      research or analyses of such data; and designing, developing,
      implementing, modifying and/or improving new, current or future features,
      products and services of Dauchi using such data. You understand that any
      User Content you publish in public forums will be viewable and copyable by
      other users of the forum and potentially the public at large.
    </p>
    <br />
    <p>
      WITHOUT LIMITATION OF THE FOREGOING, DAUCHI DOES NOT ASSUME RESPONSIBILITY
      OR LIABILITY FOR THE USER CONTENT FOUND IN ANY PUBLIC FORUM, NOR FOR ANY
      CLAIMS, DAMAGES OR LOSSES RESULTING FROM ANY USE OF ANY PUBLIC FORUM OR
      THE USER CONTENT CONTAINED THEREIN.
    </p>
    <br />
    <p>
      You acknowledge, consent, and agree that we may access, monitor, preserve,
      and disclose your information and/or any User Content you submit or make
      available for inclusion on the Services, if required to do so by law or in
      a good faith belief that such access, preservation, or disclosure is
      permitted by law or reasonably necessary or appropriate for any of the
      following reasons: (1) to comply with legal process; (2) to enforce these
      Terms of Use, our Privacy Policy, or other contracts with you, including
      investigation of potential violations thereof; (3) to respond to claims
      that any content violates the rights of third parties; (4) to respond to
      your requests for customer service; and (5) to protect the rights,
      property, or personal safety of Dauchi, its agents and affiliates, its
      users, and the public. This includes exchanging information with other
      companies and organizations for fraud protection, and spam/malware
      prevention, and similar purposes.
    </p>

    <br />
    <br />
    <p className="font-bold">7. Copyright Notices</p>
    <br />
    <p>
      Dauchi reserves the right to remove any content or any other material or
      information available on or through our Services, at any time, for any
      reason. Dauchi otherwise complies with the provisions of the Digital
      Millennium Copyright Act ("DMCA") applicable to Internet service providers
      (17 U.S.C. § 512, as amended), and responds to clear notices of alleged
      copyright infringement. This section describes the procedure that should
      be followed to file a notification of alleged copyright infringement with
      Dauchi.
    </p>
    <br />
    <p>
      Notification of Claimed Copyright Infringement. If you have objections to
      copyrighted content or material made available on or through our Services,
      you may submit a notification to our Designated Agent at the following
      email address: support@dauchi.com
    </p>
    <br />
    <p>
      Any notification to Dauchi under 17 U.S.C. § 512(c) alleging copyright
      infringement must include all of the following information:
    </p>
    <ul className="m-2">
      {[
        "1. An electronic or physical signature of the person authorized to act on behalf of the owner of the exclusive right being infringed;",
        "2. An identification of the copyrighted work or other intellectual property that you claim has been infringed or, if multiple copyrighted works are covered by a single notification, a representative list of such works;",
        "3. An identification of the content or material that you claim is infringing and where it is located on our Services;",
        "4. Information sufficient for Dauchi to contact you, such as your address, telephone number, and/or email address;",
        "5. A statement by you that you have a good-faith belief that the use of the content or material of which you are complaining is not authorized by the copyright owner, its agent, or the law; and",
        "6. A signed statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the copyright owner or authorized to act on the copyright owner's behalf.",
      ].map((point, index) => (
        <li key={index}>{point}</li>
      ))}
    </ul>
    <br />
    <br />
    <p className="font-bold">8. Restrictions on Use</p>
    <br />
    <p>
      You agree that in using or accessing the Services, you will not, and will
      not attempt to:
    </p>
    <ul className="m-2">
      {[
        "Impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity;",
        "Violate any local, state, national or international law (including export laws);",
        "Reverse engineer, disassemble, decompile, or translate any software or other components of the Services;",
        "Distribute, input, upload, transmit, or otherwise run or propagate any virus, application, Trojan horse, or any other harmful computer code that could damage or alter a computer, portable device, computer network, communication network, data, or the Services, or any other system, device, or property;",
        "Access or use the Services in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any third party;",
        "Use any robot, spider, scraper, or other automated means to access the Services for any purpose without our express prior written permission or bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Services;",
        "License, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Services, Service Content, or User Content other than as expressly permitted herein;",
        "Create or develop competing products or services or for any other purpose that is to Dauchi's detriment or commercial disadvantage;",
        "Damage, destroy, disrupt, disable, impair, overburden, interfere with, or otherwise impede or harm in any manner the Services, Service Content, or User Content, in whole or in part;",
        "Use framing techniques to enclose any trademark, logo, or the Services without our express prior written consent;",
        "Post, transmit or otherwise disseminate any content that, as we determine at our sole discretion: (i) is unlawful, harmful, harassing, fraudulent, threatening, abusive, libelous, defamatory, vulgar, obscene, hateful, violent, demeaning, intimidating, discriminatory, or racially, ethnically or otherwise objectionable, or infringes our or any third party's intellectual property or other rights, (ii) is derogatory or harmful to our reputation, the reputation of our licensors, affiliated healthcare providers, affiliated healthcare practices, or any of our or their respective officers, members, employees, representatives, licensors and/or suppliers, in any way; (iii) may incite violence or other unlawful activity; or (iv) is harmful to children in any manner; or (iv) attempts to obtain the personal information of other users;",
        "Harm, harass, threaten, abuse, defame, demean, discriminate against, or intimidate any affiliated health care provider who provides health care services related to the Services, as we determine in our sole discretion;",
        "Disrupt, interfere with, violate the security of, or attempt to gain unauthorized access to the Services or any computer network;",
        "Bypass, breach, avoid, remove, deactivate, impair, descramble, or otherwise circumvent any security device, protection, or technological measure implemented by Dauchi or any of our service providers to protect the Services;",
        "Remove, delete, alter, or obscure any trademarks, specifications, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Services or any Service Content or User Content;",
        "Use any manual process or automated device to monitor or copy any content made available on or through the Services for any unauthorized purpose except as permitted herein;",
        `To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail", "chain letter" or "spam" or any other similar solicitation;`,
        "Copy, duplicate, download, store in a retrieval system, publish, transmit or otherwise reproduce, transfer, distribute, store, disseminate, aggregate, use as a component of or as the basis for a database or otherwise use in any form or by any means any data, text, reports, or other materials related to Dauchi or third-party content from the Services;",
        "Otherwise use the Services in any manner that exceeds the scope of use granted herein;",
        "Encourage or enable any other individual to do any of the foregoing.",
      ].map((point, index) => (
        <li key={index} className="list-inside list-disc">
          {point}
        </li>
      ))}
    </ul>
    <br />
    <br />
    <p className="font-bold">9. Disclaimer of Warranties</p>
    <br />
    <p>
      YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK. YOU
      ACKNOWLEDGE AND AGREE THAT THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS
      AVAILABLE" BASIS. DAUCHI AND ITS AFFILIATES, INCLUDING WITHOUT LIMITATION
      ALL AFFILIATED PHYSICIAN PRACTICES, AND THEIR RESPECTIVE OFFICERS,
      DIRECTORS, MANAGERS, PARTNERS, MEMBERS, EMPLOYEES, AND AGENTS
      (COLLECTIVELY "RELATED PERSONS") MAKE NO REPRESENTATIONS OR WARRANTIES AND
      EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
      IMPLIED, WITH RESPECT TO THE SERVICES INCLUDING, BUT NOT LIMITED TO, ANY
      REPRESENTATIONS OR WARRANTIES WITH RESPECT TO MERCHANTABILITY, FITNESS FOR
      A PARTICULAR USE OR PURPOSE, NONINFRINGEMENT, TITLE, AVAILABILITY,
      SECURITY, OPERABILITY, CONDITION, QUIET ENJOYMENT, VALUE, ACCURACY OF
      DATA, FREEDOM FROM VIRUSES OR MALWARE, COMPLETENESS, TIMELINESS,
      FUNCTIONALITY, RELIABILITY, SEQUENCING OR SPEED OF DELIVERY OR SYSTEM
      INTEGRATION. WE MAKE NO WARRANTIES OR REPRESENTATIONS THAT YOUR USE OF THE
      SERVICES WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES.
    </p>
    <br />
    <p>
      TO THE FULLEST EXTENT OF APPLICABLE LAW, NEITHER DAUCHI NOR ITS RELATED
      PERSONS WILL BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON
      INFORMATION OBTAINED THROUGH THE SERVICES. IT IS YOUR RESPONSIBILITY TO
      EVALUATE THE ACCURACY, COMPLETENESS, TIMELINESS, RELIABILITY OR USEFULNESS
      OF THE SERVICES, SERVICES CONTENT, AND USER CONTENT. FURTHERMORE, DAUCHI
      DOES NOT GUARANTEE THAT THE SERVICES WILL BE UNINTERRUPTED, OR FREE FROM
      ERROR, DEFECT, LOSS, DELAY IN OPERATION, CORRUPTION, CYBER ATTACK,
      VIRUSES, INTERFERENCE, HACKING, MALWARE, OR OTHER SECURITY INTRUSION, AND
      DAUCHI DISCLAIMS ANY LIABILITY RELATING THERETO.
    </p>
    <br />
    <p>
      YOU UNDERSTAND AND AGREE THAT ANY CONTENT, MATERIAL AND/OR INFORMATION
      OBTAINED THROUGH THE USE OF THE SERVICES ARE USED AT YOUR SOLE RISK AND
      THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR
      MOBILE PHONE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH
      CONTENT, MATERIAL AND/OR INFORMATION, OR YOUR RELIANCE ON ANY SUCH
      CONTENT, MATERIAL, AND/OR INFORMATION.
    </p>
    <br />
    <br />
    <p className="font-bold">10. Limitation of Liability</p>
    <br />
    <p>
      YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW AND
      EXCEPT AS SET FORTH IN THIS SECTION, IN NO EVENT WILL DAUCHI, ITS RELATED
      PERSONS OR LICENSORS BE LIABLE TO YOU OR TO ANY PARTY FOR ANY CLAIMS,
      LIABILITIES, LOSSES, COSTS OR DAMAGES UNDER ANY LEGAL OR EQUITABLE THEORY,
      WHETHER IN TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), CONTRACT,
      WARRANTY, STATUTE OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY
      INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY
      DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES,
      PROFITS, GOODWILL, USE OR DATA, SERVICE INTERRUPTION, COMPUTER OR MOBILE
      PHONE DAMAGE, OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE PRODUCTS OR
      SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL
      DISTRESS, INCLUDING DEATH, ARISING OUT OF OR IN CONNECTION WITH ANY
      ACCESS, USE OF (OR INABILITY TO USE) THE SERVICES OR ANY SERVICES CONTENT,
      OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATED TO YOUR USE OF THE
      SERVICES. THIS IS TRUE EVEN IF DAUCHI OR RELATED PERSONS HAVE BEEN ADVISED
      OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
    </p>
    <br />
    <p>
      IN NO EVENT SHALL DAUCHI OR IT'S RELATED PERSONS' TOTAL LIABILITY TO YOU
      FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT
      (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED IN THE
      AGGREGATE, THE GREATER OF (I) ONE HUNDRED DOLLARS ($100), OR (II) THE
      AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE SERVICES IN THE TWELVE (12)
      MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM.
    </p>
    <br />
    <p>
      Some jurisdictions do not allow the exclusion of certain warranties or the
      limitation or exclusion of liability for damages such as in this section.
      Accordingly, some of these limitations may not apply to you. If you are a
      New Jersey resident, or a resident of another U.S. state that permits the
      exclusion of these warranties and liabilities, then the limitations in
      this section specifically do apply to you.
    </p>
    <br />
    <br />
    <p className="font-bold">11. Indemnification</p>
    <br />
    <p>
      You agree to indemnify, defend, and hold harmless Dauchi, its affiliates,
      its affiliated health care practices, its subsidiaries, and all of their
      directors, officers, employees, contractors, licensors, suppliers,
      representatives, proprietors, partners, shareholders, principals, agents,
      predecessors, successors, assigns, accountants, and attorneys harmless
      from and against any and all third-party suits, actions, claims,
      proceedings, damages, settlements, judgments, injuries, liabilities,
      obligations, losses, risks, costs, and expenses (including, without
      limitation, reasonable attorneys' fees, litigation expenses, and
      accounting fees), relating to or arising from, or alleged to arise from,
      (i) your use of the Services, or your use of the materials or features
      available on the Services, in an unauthorized manner, (ii) your fraud,
      violation of law, negligence, or willful misconduct, or (iii) any breach
      by you of these Terms of Use.
    </p>
    <br />
    <br />
    <p className="font-bold">
      12. Third-Party Links and Features on the Services
    </p>
    <br />
    <p>
      The Services may contain hyperlinks, plug-ins, products, or features
      operated by third parties ("Third-Party Services"). Such Third-Party
      Services are not under our control, therefore, we are not responsible for
      the information, products or services described by, or for the content or
      features of any such Third-Party Services. We are providing these
      Third-Party Services to you only as a convenience, and the inclusion of
      any Third-Party Services does not necessarily imply endorsement of the
      Third-Party Services or any association with its operators. Your use of
      these Third-Party Services is at your own risk, and we are not liable to
      you in any way, either directly or indirectly, for any content, errors,
      damage or loss caused by or in connection with use of or reliance on
      information contained in or provided to Third-Party Services. You may have
      arrived to the Services through a Third-Party Service, and you understand
      and agree that we are not responsible for the information, products or
      services described on those Third-Party Services and only these Terms of
      Use will apply to your use of or access to the Services.
    </p>
    <br />
    <p>
      Moreover, we are not involved in any actual transaction between any user
      and another user or third party, nor are we the agent of either for any
      purpose. As such, we will not be involved in resolving any disputes
      between any user and another user or third party relating to or arising
      out of any transaction between such parties. We urge users and third
      parties to cooperate with one another to resolve any such disputes. In the
      event that you have a dispute with one or more other users or third
      parties through your use of the Services, you release Dauchi, its
      officers, employees, agents, and successors from claims, demands, and
      damages of every kind or nature, known or unknown, suspected or
      unsuspected, disclosed or undisclosed, arising out of or in any way
      related to such disputes and/or our Services.
    </p>
    <br />
    <br />
    <p className="font-bold">
      13. Changes to the Terms of Use and the Services
    </p>
    <br />
    <p>
      The Services are continually under development, and Dauchi reserves the
      right to review or remove any part of these Terms of Use in its sole
      discretion at any time and without prior notice to you. You should check
      the Terms of Use from time to time when you use the Services to determine
      if any changes have been made. Any changes to these Terms of Use are
      effective upon posting to the Services, unless applicable law requires us
      to provide additional notice or take other actions before such changes can
      become effective. If you disagree with these Terms of Use, your sole and
      exclusive remedy is to discontinue your use of the Services. Your
      continued use after a change has been posted constitutes your acceptance
      of the changes.
    </p>
    <br />
    <p>
      You acknowledge and agree that: (1) all or any part of the Services may
      not be accessible at any time, for any period, or for any reason; and (2)
      Dauchi will not be liable if for any reason all or any part of the
      Services are unavailable at any time or for any period. Dauchi reserves
      the right at any time and for any reason to modify, or temporarily or
      permanently discontinue, the Services or any portion thereof, with or
      without notice. You agree that Dauchi shall not be liable to you or to any
      third party for any modification, suspension, or discontinuance of the
      Services.
    </p>
    <br />
    <br />
    <p className="font-bold">14. Payments</p>
    <br />
    <p>
      You agree to pay all fees due for services requested at checkout and
      pursuant to all payment terms presented to you when engaging in
      transactions. Prices are subject to change at any point in our sole
      discretion. You will see a prompt for your payment details, such as your
      credit card information and any promotional codes you may have. By
      entering your payment information and submitting your request, you
      authorize us, our affiliates, or our third-party payment processors to
      charge the amount due, including recurring fees associated with
      Subscription Services (as defined below).
    </p>
    <br />
    <p>
      You understand and agree that you are responsible for all fees due to
      receive health care services and pharmacy services, including any fees
      charged by the health care organization(s) or provider(s) that provide
      services to you in connection with the Services. Your payments to Dauchi
      may include fees charged by health care organization(s) or provider(s) for
      health care services and/or pharmacy services, which Dauchi collects on
      their behalf. Any health care services or pharmacy services not provided
      by us, an affiliated physician practice, or the Dauchi Pharmacy Network,
      or otherwise made available through the Services are not included in the
      payments collected by Dauchi and you may be separately charged by the
      applicable health care organization(s) and/or provider(s) for such
      services. In the event that your credit card expires or Dauchi, our
      affiliates, or our third-party payment processors are unable to process
      your payment, you may receive notice for you to provide an alternative
      payment method. Dauchi and/or the health care organization(s) and/or
      provider(s) have no obligation to provide any health care services or
      pharmacy services unless and until full payment has been received and/or
      verified. You are responsible for keeping your payment information (such
      as credit card number and billing address) accurate and up to date at all
      times.
    </p>
    <br />
    <p>
      Certain products or services offered on the Services may be offered on a
      subscription basis ("Subscription Services"). For Subscription Services,
      your payment method will be automatically charged at regular intervals as
      described during the checkout process for the applicable Subscription
      Services. Depending on the specific Service you select, you may be charged
      at the time you submit a request (for instance, a treatment request), and
      the products or services purchased will begin to be provided after all
      onboarding steps are complete (for instance, after your treatment request
      is approved by a provider and you provide all required information). For
      other Services, you may not be charged until all required onboarding steps
      are completed.
    </p>
    <br />
    <p>
      You may cancel a Subscription Service to prevent future renewals at any
      time before the cancelation cutoff date as indicated to you at the time
      you purchase a Subscription Service. You will also be informed about how
      you can cancel these Subscription Services.
    </p>
    <br />
    <p>
      EXCEPT AS OTHERWISE SET FORTH IN ANY RETURN OR REFUND POLICY PROVIDED TO
      YOU ON THE SERVICES AND AS EXPLAINED BELOW, YOU ACKNOWLEDGE AND AGREE THAT
      DUE TO THE NATURE OF THE PRODUCTS AND SERVICES PURCHASABLE THROUGH THE
      SERVICES ANY APPLICABLE FEES AND OTHER CHARGES ARE NOT REFUNDABLE IN WHOLE
      OR IN PART. YOU ARE FULLY LIABLE FOR ALL CHARGES TO YOUR ACCOUNT,
      INCLUDING ANY UNAUTHORIZED CHARGES.
    </p>
    <br />
    <p>
      You will automatically be refunded if we have already charged your payment
      method and you are subsequently deemed unsuitable for treatment or we
      reject your treatment request for any other reason. These refunds (along
      with all refunds described in these Terms or otherwise provided by Dauchi)
      may take 5 business days or longer to process.
    </p>
    <br />
    <p>
      If you submit a treatment request but you do not complete additional
      onboarding steps we require in order to process your request for certain
      Subscription Services, your treatment request may expire. If your payment
      method has already been charged, you will automatically be refunded when a
      treatment request expires. In order to receive the desired Services, you
      will need to initiate a new treatment request.
    </p>
    <br />
    <p>
      When you submit a treatment request, your provider may suggest an
      alternative treatment plan that better aligns with your health needs. When
      this occurs, you will be prompted to accept the suggested alternative. If
      you accept the suggested alternative treatment plan and your payment
      method has already been charged, the initial charge will be refunded
      automatically, and you will incur a new charge for the alternative
      treatment plan. If you take no action and do not accept the alternative
      treatment plan, your treatment request may expire (at which point you will
      automatically be refunded if your payment method has already been
      charged). Once expired, in order to receive the desired Services, you will
      need to initiate a new treatment request.
    </p>
    <br />
    <br />
    <p className="font-bold">15. Termination</p>
    <br />
    <p>
      The Terms of Use will remain in full force and effect as long as you
      continue to access or use the Services. You may terminate the Terms of Use
      at any time by discontinuing use of the Services. Your permission to use
      the Services automatically terminates if you violate these Terms of Use.
    </p>
    <br />
    <p>
      Dauchi may terminate or suspend any of the rights granted by these Terms
      of Use and your access to and use of the Services with or without prior
      notice, for any reason, and at any time, including for violations of these
      Terms of Use. The following provisions survive the expiration or
      termination of these Terms of Use for any reason whatsoever: Disclaimer of
      Warranties; Limitation of Liability; Indemnification; Governing Law,
      Dispute Resolution, Arbitration, Venue, Severability of Provisions; No
      Waiver; and Assignment.
    </p>
    <br />
    <p>
      Subject to applicable law, Dauchi reserves the right to maintain, delete
      or destroy all communications and materials posted or uploaded to the
      Services pursuant to its internal record retention and/or content
      destruction policies. After such termination, Dauchi will have no further
      obligation to provide the Services, except to the extent an affiliated
      professional entity is obligated to provide you access to your health
      records or is required to provide you with continuing care under
      applicable legal, ethical and professional obligations to you. You agree
      that if your use of the Services is terminated pursuant to these Terms of
      Use, you will not attempt to use the Services in any way, and further
      agree that if you violate this restriction after such termination, you
      will indemnify and hold Dauchi harmless from any and all liability that
      Dauchi may incur therefore.
    </p>
    <br />
    <br />
    <p className="font-bold">16. Governing Law; Severability</p>
    <br />
    <p>
      Governing Law. These Terms of Use and your use of the Services shall be
      governed by the laws of the State of California, without giving effect to
      the principles of conflict of laws. Subject to the requirement to
      arbitrate set forth in Section 17, exclusive jurisdiction for all disputes
      that do not require arbitration will be the state and federal courts
      located in Los Angeles, California, and you consent to the jurisdiction of
      those courts.
    </p>
    <br />
    <p>
      Severability. All parts of these Terms of Use apply to the maximum extent
      permitted by law. Dauchi and you both agree that if we cannot enforce a
      part of this contract as written, then that part will be replaced with
      terms that most closely match the intent of the part we cannot enforce, to
      the extent permitted by law. The invalidity of part of these Terms of Use
      will not affect the validity and enforceability of the remaining
      provisions. The section headings are for convenience only and do not have
      any force or effect.
    </p>
    <br />
    <br />
    <p className="font-bold">
      17. Dispute Resolution: Binding Arbitration Clause and Class Action Waiver
    </p>
    <br />
    <p>
      PLEASE READ THIS SECTION CAREFULLY. IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL
      RIGHTS—INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT (BY REQUIRING YOUR
      DISPUTE TO BE SUBMITTED TO ARBITRATION) AND LIMITING YOUR RIGHTS TO
      RESOLVE YOUR DISPUTE AS PART OF A CLASS.
    </p>
    <br />
    <p>
      As detailed herein, the Terms of Use mandate that all disputes between you
      and Dauchi be resolved first through an informal dispute resolution
      process. In the event informal resolution fails, the Terms of Use further
      mandate that all disputes (except those identified in Section 17.3) be
      formally resolved through binding arbitration. Binding arbitration means
      that an arbitrator, and not any federal, state or local court or agency,
      shall have exclusive authority to resolve such disputes. Consequently, you
      should read the entirety of Section 17 carefully as it may significantly
      affect your legal rights.
    </p>
    <br />
    <br />
    <div className="ml-2">
      <p className="font-bold">17.1 Informal Dispute Resolution.</p>
      <br />
      <p>
        For any and all disputes between you and Dauchi, the parties shall use
        their best efforts to settle informally the dispute, claim, question, or
        disagreement and to engage in good faith negotiations. Failure to engage
        in this process could result in the award of fees against you in
        arbitration.
      </p>
      <br />
      <p>
        To initiate informal dispute resolution, the initiating party must first
        send a written description of the dispute to the other party. For any
        dispute against Dauchi that you initiate, you agree to send to Dauchi
        (a) a written description of the dispute and (b) the email address(es)
        associated with your account through the following email address:
        support@dauchi.com. The written description must be on an individual
        basis and provide, at minimum, the following information: your name; a
        description of the nature or basis of the claim or dispute; and the
        specific relief sought. For any dispute that Dauchi initiates, we will
        send our written description of the dispute to the email address
        associated with your use of the Services.
      </p>
      <br />
      <p>
        If the parties’ dispute is not resolved within sixty (60) days after
        receipt of the written description of the dispute by a party, you and
        Dauchi agree to resolve any remaining dispute through the additional
        dispute resolution provisions set forth below.
      </p>
      <br />
      <p>
        A good faith engagement in informal dispute resolution shall be and is a
        prerequisite and condition precedent to either party initiating a
        lawsuit or arbitration. The parties agree that any applicable statute of
        limitations period and filing fees or other deadlines will be tolled
        while the parties engage in this informal dispute resolution process.
      </p>
      <br />
      <br />
      <p className="font-bold ml-2">17.2 Binding Arbitration</p>
      <br />
      <p>
        After the parties have engaged in a good-faith effort to resolve their
        dispute(s) in accordance with the Informal Dispute Resolution process
        (Section 17.1), and only if those efforts fail, then either party may
        initiate arbitration as set forth in this Section.
      </p>
      <br />
      <p>
        If you determine to initiate arbitration, a copy of the arbitration
        demand must be emailed to support@dauchi.com. If Dauchi is initiating
        arbitration, it will serve a copy of the demand to the email address
        associated with your use of the Dauchi Services.
      </p>
      <br />
      <p>
        (a) Mutual Arbitration Agreement. Except as set forth in Section 17.3
        below, you and Dauchi agree that all claims, disputes, or disagreements
        that may arise out of or relating to the interpretation, applicability,
        enforceability, formation, or performance of these Terms of
        Use—including but not limited to any claim that all or any part of these
        terms are void or voidable, whether a claim is subject to arbitration,
        and any dispute regarding the payment, non-payment, or timing of any
        administrative or arbitrator fees—shall be resolved exclusively through
        binding arbitration in accordance with this Section 17.2 (collectively,
        the “Arbitration Agreement”). The arbitrator shall be empowered under
        this Arbitration Agreement to grant whatever relief would be available
        in a court under law or in equity. The arbitrator has the right to
        impose sanctions in accordance with the arbitration provider’s rules and
        procedures for any frivolous claims or submissions the arbitrator
        determines have not been filed in good faith, as well as for a party’s
        failure to comply with this Section 17.2.
      </p>
      <br />
      <p>
        This Arbitration Agreement is intended to be interpreted broadly, and it
        applies to claims that arose, were asserted, or involve facts occurring
        before the existence of this Arbitration Agreement or any prior
        agreement as well as claims that may arise after the termination of this
        Arbitration Agreement, in accordance with the notice opt-out provisions
        set forth in Sections 17.4 and 17.5.
      </p>
      <br />
      <p>
        This Arbitration Agreement is governed by the Federal Arbitration Act
        (“FAA”) in all respects and evidences a transaction involving interstate
        commerce. You and Dauchi expressly agree that the FAA shall exclusively
        govern the interpretation and enforcement of this Arbitration Agreement.
        If for whatever reason the rules and procedures of the FAA cannot apply,
        the state law governing arbitration agreements in the state in which you
        reside shall apply.
      </p>
      <br />
      <p>
        Except as set forth in Section 17.2(c) below, if any provision of this
        Arbitration Agreement is found by an arbitrator or court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the
        arbitrator or court should endeavor to give effect to the parties’
        intentions as reflected in the provision, and the other provisions
        thereof remain in full force and effect.
      </p>
      <br />
      <p>
        (b) WAIVER OF RIGHTS INCLUDING JURY TRIAL. THE PARTIES UNDERSTAND THAT
        ARBITRATION MEANS THAT AN ARBITRATOR AND NOT A JUDGE OR JURY WILL DECIDE
        THE CLAIM, AND THAT RIGHTS TO DISCOVERY AND APPEALS MAY BE LIMITED IN
        ARBITRATION. THE PARTIES FURTHER UNDERSTAND THAT THE COSTS OF
        ARBITRATION COULD EXCEED THE COST OF LITIGATION IN SOME INSTANCES.
      </p>
      <br />
      <p>
        YOU HEREBY ACKNOWLEDGE AND AGREE THAT BY AGREEING TO THESE TERMS AND
        ARBITRATION AGREEMENT, YOU AND DAUCHI ARE EACH WAIVING THE RIGHT TO A
        TRIAL BY JURY TO THE MAXIMUM EXTENT PERMITTED BY LAW.
      </p>
      <br />
      <p>
        (c) CLASS ARBITRATION AND COLLECTIVE RELIEF WAIVER. YOU AND DAUCHI
        ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT ALLOWED BY LAW, AND
        EXCEPT AS OTHERWISE SET OUT IN THIS SECTION 17.2(c) AND SECTION
        17.2(e)(vi) BELOW, ANY ARBITRATION SHALL BE CONDUCTED IN AN INDIVIDUAL
        CAPACITY ONLY AND NOT AS A CLASS OR OTHER CONSOLIDATED ACTION, AND THE
        ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY
        SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO RESOLVE AN INDIVIDUAL
        PARTY’S CLAIM; NOTWITHSTANDING THIS ACKNOWLEDGEMENT AND AGREEMENT, ANY
        ARBITRATION INVOLVING YOU MAY PROCEED ON A CONSOLIDATED BASIS IF AND
        ONLY IF DAUCHI PROVIDES ITS CONSENT TO CONSOLIDATE IN WRITING.
      </p>
      <br />
      <p>
        If there is a final judicial determination that applicable law precludes
        enforcement of this Paragraph’s limitations as to a particular remedy,
        then that remedy (and only that remedy) must be severed from the
        arbitration and may be sought in court. The parties agree, however, that
        any adjudication of remedies not subject to arbitration shall be stayed
        pending the outcome of any arbitrable claims and remedies.
      </p>
      <br />
      <p>
        If there is a final judicial determination that either the Class
        Arbitration and Collective Relief Waiver in this Section, or that the
        provisions in this Section 17.2 are not enforceable as to a particular
        claim or request for relief, then the parties agree that that particular
        claim or request for relief may proceed in court as provided herein, but
        shall be severed and stayed pending arbitration of the remaining claims.
        This provision does not prevent you or Dauchi from participating in a
        class-wide settlement of claims.
      </p>
      <br />
      <p>
        (d) Arbitration Location. If you are a resident of the United States,
        arbitration will take place in Los Angeles, California. For residents in
        Canada (and anywhere else outside the United States), arbitration shall
        be initiated in the County of Los Angeles, State of California, United
        States of America, unless you and Dauchi otherwise agree or unless the
        designated arbitrator determines that such venue would be unreasonably
        burdensome to any party, in which case the arbitrator shall have the
        discretion to select another venue.
      </p>
      <br />
      <p>
        For any arbitration conducted in the County of Los Angeles, State of
        California, United States of America, you and Dauchi agree to submit to
        the personal jurisdiction of any federal or state court in Los Angeles
        County, California, in order to compel arbitration, to stay proceedings
        pending arbitration, or to confirm, modify, vacate, or enter judgment on
        the award entered by the arbitrator; and in connection with any such
        proceeding, further agree to accept service of process by U.S. or
        certified mail and hereby waive any and all jurisdictional and venue
        defenses otherwise available.
      </p>
      <br />
      <p>(e) The Arbitration Rules.</p>
      <br />
      <p>(i) The Provider.</p>
      <br />
      <p>
        The arbitration will be administered by National Arbitration and
        Mediation (“NAM”) and resolved before a single arbitrator. If NAM is not
        available to arbitrate, the parties will select an alternative
        arbitration provider.
      </p>
      <br />
      <p>
        Except as modified by this “Dispute Resolution” provision, NAM will
        administer the arbitration in accordance with the NAM Comprehensive
        Dispute Resolution Rules and Procedures, Fees For Disputes When One of
        the Parties is a Consumer and the Mass Filing Dispute Resolution Rules
        and Procedures in effect at the time any demand for arbitration is filed
        with NAM, excluding any rules or procedures governing or permitting
        class or representative actions. The applicable NAM rules and procedures
        are available at http://www.namadr.com or by emailing National
        Arbitration and Mediation’s Commercial Dept at commercial@namadr.com.
      </p>
      <br />
      <p>
        You are responsible for your own attorneys’ fees unless the arbitration
        rules and/or applicable law provide otherwise.
      </p>
      <br />
      <p>
        The parties agree that NAM has discretion to reduce the amount or modify
        the timing of any administrative or arbitration fees due under NAM’s
        Rules where it deems appropriate, provided that such modification does
        not increase the costs to you, and you further agree that you waive any
        objection to such fee modification. The parties also agree that a
        good-faith challenge by either party to the fees imposed by NAM does not
        constitute a default, waiver, or breach of this Section 17 while such
        challenge remains pending before NAM the arbitrator, and/or a court of
        competent jurisdiction, and that any and all due dates for those fees
        shall be tolled during the pendency of such challenge.
      </p>
      <br />
      <p>(ii) Arbitration Demand Must Contain Sufficient Information.</p>
      <br />
      <p>
        Any arbitration demand or counterclaim asserted by either party must
        contain sufficient information to provide fair notice to the other party
        of the asserting party’s identity, the claims being asserted, and the
        factual allegations on which they are based, and must include proof that
        the claimant is party to this Arbitration Agreement and to these Terms
        of Use. The arbitrator and/or NAM may require amendment of any demand or
        counterclaim that does not satisfy these requirements. The arbitrator
        has the right to impose sanctions for any claims the arbitrator
        determines to be frivolous or improper (under the standard set forth in
        Federal Rule of Civil Procedure 11), including for any claim filed on
        behalf of a claimant who is not a party to this Arbitration Agreement or
        to these Terms of Use.
      </p>
      <br />
      <p>(iii) Arbitration Conducted on Papers in Some Circumstances.</p>
      <br />
      <p>
        If the amount in controversy does not exceed $10,000 and you do not seek
        injunctive or declaratory relief, then the arbitration will be conducted
        solely on the basis of documents that you and Dauchi submit to the
        arbitrator, unless the arbitrator determines that a hearing is
        necessary. If the amount in controversy exceeds $10,000 or seeks
        declaratory or injunctive relief, either party may request (or the
        arbitrator may determine) to hold a hearing, which shall be via
        videoconference or telephone conference unless the parties agree
        otherwise.
      </p>
      <br />
      <p>(iv) Dispositive Motions.</p>
      <br />
      <p>
        Subject to the applicable NAM rules and procedures, the parties agree
        that the arbitrator will have the discretion to allow the filing of
        dispositive motions if they are likely to efficiently resolve or narrow
        issues in dispute.
      </p>
      <br />
      <p>(v) Batching.</p>
      <br />
      <p>
        To increase the efficiency of administration and resolution of
        arbitrations, in the event 100 or more similar arbitration demands
        (those asserting the same or substantially similar facts or claims, and
        seeking the same or substantially similar relief) presented by or with
        the assistance or coordination of the same law firm(s) or
        organization(s) are submitted to NAM (or another arbitration provider
        selected in accordance with Section 17.2(e)(i) if NAM is unavailable)
        against Dauchi within reasonably close temporal proximity (“Mass
        Filing”), the parties agree (A) to administer the Mass Filing in batches
        of 100 demands per batch (to the extent there are fewer than 100
        arbitration demands left over after the batching described above, a
        final batch will consist of the remaining demands) with only one batch
        filed, processed, and adjudicated at a time; (B) to designate one
        arbitrator for each batch; (C) to accept applicable fees, including any
        related fee reduction determined by NAM (or another arbitration provider
        selected in accordance with 17.2(e)(i) if NAM is unavailable) in its
        discretion; (D) that no other demands for arbitration that are part of
        the Mass Filing may be filed, processed, or adjudicated until the prior
        batch of 100 is filed, processed, and adjudicated; (E) that fees
        associated with a demand for arbitration included in a Mass Filing,
        including fees owed by Dauchi and the claimants, shall only be due after
        your demand for arbitration is included in a set of batch proceedings
        and that batch is properly designated for filing, processing, and
        adjudication; and (F) that the staged process of batched proceedings,
        with each set including 100 demands, shall continue until each demand
        (including your demand) is adjudicated or otherwise resolved. If your
        demand for arbitration is included in the Mass Filing, any statute of
        limitation applicable to your claims will remain tolled until your
        demand for arbitration is decided, withdrawn, or settled.
      </p>
      <br />
      <p>
        Arbitrator selection for each batch shall be conducted to the greatest
        extent possible in accordance with the applicable NAM rules and
        procedures for such selection, and shall be subject to any rights to
        strike an arbitrator provided under applicable state law if the rights
        granted by law exceed those provided for in the NAM rules, and the
        arbitrator will determine the location where the proceedings will be
        conducted.
      </p>
      <br />
      <p>
        You agree to cooperate in good faith with Dauchi and the arbitration
        provider to implement such a “batch approach” or other similar approach
        to provide for an efficient resolution of claims, including the payment
        of combined reduced fees, set by NAM in its discretion, for each batch
        of claims. The parties further agree to cooperate with each other and
        the arbitration provider or arbitrator to establish any other processes
        or procedures that the arbitration provider or arbitrator believe will
        provide for an efficient resolution of claims. For example, if the
        number of cases filed makes batches of 100 cases too small for the
        prompt resolution of all filed claims, you and we agree that NAM may
        increase or decrease the batch size, or transfer a case between batches
        in the reasoned discretion of the NAM procedural arbitrator. Any and all
        disagreements between the parties as to whether this provision applies
        or as to the process or procedure for batching shall be resolved by a
        NAM procedural arbitrator.
      </p>
      <br />
      <p>
        This “Batch Arbitration” provision shall in no way be interpreted as
        increasing the number of claims necessary to trigger the applicability
        of NAM’s Mass Filing Supplemental Dispute Resolution Rules and
        Procedures or authorizing class arbitration of any kind.
      </p>
      <br />
      <p>(vi) No Class or Consolidated Arbitration Absent Written Consent.</p>
      <br />
      <p>
        Unless Dauchi otherwise consents in writing, which it may do on a
        case-by-case basis, Dauchi does not agree or consent to class
        arbitration, private attorney general arbitration, or arbitration
        involving joint or consolidated claims under any circumstances, except
        as set forth in Section 17.2(c) and this Section 17.2(e)(vi).
      </p>
      <br />
      <p>(vii) Arbitration Award.</p>
      <br />
      <p>
        The arbitrator will render an award within the time frame specified in
        the applicable NAM rules and procedures. The arbitrator’s decision will
        include the essential findings and conclusions upon which the arbitrator
        based the award. Judgment on the arbitration award may be entered in any
        court having jurisdiction thereof. The arbitrator will have the
        authority to award monetary damages on an individual basis and to grant,
        on an individual basis, any non-monetary remedy or relief available to
        an individual to the extent available under applicable law, the arbitral
        forum’s rules, and this Arbitration Agreement. The parties agree that
        the damages and/or other relief must be consistent with Section 17.2(c)
        above, and also must be consistent with the terms of the “Limitation of
        Liability” section of these Terms of Use as to the types and the amounts
        of damages or other relief for which a party may be held liable. No
        arbitration award or decision will have any preclusive effect as to
        issues or claims in any dispute with anyone who is not a named party to
        the arbitration. Attorneys’ fees will be available to the prevailing
        party in the arbitration only if authorized under applicable substantive
        law governing the claims in the arbitration.
      </p>
      <br />
      <p>17.3 Exceptions to Arbitration.</p>
      <br />
      <p>
        Notwithstanding the parties’ agreement to resolve all disputes through
        binding arbitration as set forth in Section 17.2:
      </p>
      <br />
      <p>(a) IP Disputes.</p>
      <br />
      <p>
        Either party’s claims of infringement or misappropriation of the other
        party’s patent, copyright, trademark, or trade secret, shall be
        exclusively brought in the state and federal courts located in the City
        and County of Los Angeles, California..
      </p>
      <br />
      <p>(b) Small Claims Court and Statutes of Limitation.</p>
      <br />
      <p>
        Either party may elect to have disputes or claims resolved in a small
        claims court regardless of what forum the filing party initially chose,
        provided the disputes or claims are within the scope of that court’s
        jurisdiction.
      </p>
      <br />
      <p>
        Either party may also seek a declaratory judgment or other equitable
        relief in a court of competent jurisdiction regarding whether a party’s
        claims are time-barred or may be in fact be brought in small claims
        court. Seeking such relief shall not waive a party’s right to
        arbitration under this agreement, and any filed arbitrations related to
        any action filed pursuant to this paragraph shall automatically be
        stayed (and any applicable statute of limitations tolled) pending the
        outcome of such action.
      </p>
      <br />
      <p>(c) Jurisdiction/Service of Process.</p>
      <br />
      <p>
        For any dispute not subject to arbitration under this Section 17, you
        and Dauchi agree to submit to the personal and exclusive jurisdiction of
        and venue in the federal and state courts located in Los Angeles,
        California. You further agree to accept service of process by U.S. or
        certified mail, and hereby waive any and all jurisdictional and venue
        defenses otherwise available.
      </p>
      <br />
      <p>17.4 30-Day Right to Opt Out.</p>
      <br />
      <p>
        You have the right to opt-out and not be bound by the arbitration and
        class action waiver provisions set forth above by sending written
        notice, signed by you, of your decision to opt-out to the following
        address: support@dauchi.com. The notice must be sent within 30 days of
        August 12, 2024 or your first use of the Services, whichever is later;
        otherwise, you shall be bound to arbitrate disputes in accordance with
        the terms of Section 17.2. If you opt-out of the arbitration provisions,
        Dauchi also will not be bound by them.
      </p>
      <br />
      <p>
        If you opt out of the Arbitration Agreement, you may exercise your right
        to a trial by jury or judge, as permitted by applicable law, but any
        prior existing agreement to arbitrate disputes under a prior version of
        the Arbitration Agreement will not apply to claims not yet filed. If
        Dauchi changes this “Dispute Resolution” section after the date you
        first accepted this Agreement (or accepted any subsequent changes to
        this Agreement), you agree that your continued use of the Services 30
        days after such change will be deemed acceptance of those changes. If
        you do not agree to such change, you may opt out of the Arbitration
        Agreement by providing notice as described in Section 17.4.
      </p>
      <br />
      <p>
        Dauchi will continue to honor any valid opt outs if you opted out of
        arbitration in a prior version of the Terms pursuant to the requirements
        set forth in that version. If you do not timely opt out of this
        Arbitration Agreement, such action shall constitute mutual acceptance of
        the terms of these “Dispute Resolution” provisions by you and Dauchi.
      </p>
      <br />
      <p>17.5 Changes to this Section.</p>
      <br />
      <p>
        Dauchi will provide 30 days’ notice of the date of any material changes
        to this Section 17. Changes will become effective on the 30th day and
        apply to all claims not yet filed. If you continue to use the Services
        after the 30th day, you agree that any unfiled claims of which Dauchi
        does not have actual notice are subject to the revised clause.
      </p>
    </div>
    <br />
    <br />
    <p className="font-bold">18. Communications by Text Message and Email</p>
    <br />
    <p>
      By opting-in to receive text (SMS) messages from Dauchi or by sending
      Dauchi an initial text message (an "SMS Enrollment"), you consent to
      receiving text messages regarding your Dauchi account and use of the
      Services. These text messages may include order confirmations, shipping
      notifications, messages from your healthcare provider, and other
      transactional messages, as well as promotional and marketing
      notifications, to the extent you have opted-in to receive such messages
      from Dauchi.
    </p>
    <br />
    <p>
      If you provide your phone number during any interaction with Dauchi but do
      not complete the process or choose to discontinue your use of the
      Services, Dauchi reserves the right to send you a promotional offer(s) via
      text message, even if you did not explicitly opt-in to receive text
      messages.
    </p>
    <br />
    <p>
      With your SMS Enrollment, you represent and understand that: (1) you are
      the owner or authorized user of the mobile device you used in order to
      initiate the SMS Enrollment, (2) you are authorized to approve any
      applicable charges in connection with the text messages you send to and
      receive from Dauchi, (3) you will be responsible for all messaging and
      other data charges that may apply for any text messages sent to you from
      Dauchi, or from Dauchi to you, and (4) neither Dauchi, nor your or
      Dauchi's mobile carriers, will be liable for delayed or undelivered
      messages.
    </p>
    <br />
    <p>
      Note that access to the Services is not conditioned upon your consent to
      receive marketing or promotional text messages from Dauchi, and you can
      opt-out of any of Dauchi's SMS services at any time by texting "STOP" to
      the message received, from the mobile device that is subscribed to receive
      the SMS messages. After you send the text message "STOP" to us, we may
      send you one final text message to confirm that you have been
      unsubscribed. If you have opted-in to receive more than one type of text
      message from Dauchi, you will need to opt-out of each Dauchi SMS service
      to which you are subscribed. Until you have done so, you may continue to
      receive the types of text messages from Dauchi that you have opted-in to
      receive but have not unsubscribed from.
    </p>
    <br />
    <p>
      You also understand that while Dauchi takes your privacy and the security
      of your health and other sensitive information very seriously, the
      transmission of information over the internet and mobile networks is not
      100% secure. Text messages and emails that you send to or receive from
      Dauchi are not encrypted, which means that it is possible they may be
      intercepted by third parties. If you choose to send or receive information
      about your health or any other sensitive information by text message or
      email, you do so at your own risk. By initiating an SMS Enrollment, you
      consent to sending text messages to Dauchi, and receiving text messages
      from Dauchi, that are not encrypted. Likewise, by emailing Dauchi or
      giving Dauchi your email, you consent to receiving unencrypted emails
      messages from Dauchi.
    </p>
    <br />
    <p>
      If you are experiencing any issues with Dauchi's text messaging or email
      services, or if you have any concerns about sending or receiving any
      sensitive information through text or email, please contact us directly at
      support@dauchi.com. If you have questions specific to your text or data
      plan, please contact your wireless provider.
    </p>
    <br />
    <br />
    <p className="font-bold">19. Miscellaneous Terms</p>
    <br />
    <p>
      No waiver. No waiver by Dauchi of any term or condition set forth in these
      Terms of Use shall be deemed a further or continuing waiver of such term
      or condition or a waiver of any other term or condition, and any failure
      by Dauchi to assert a right or provision under these Terms of Use shall
      not constitute a waiver of such right or provision.
    </p>
    <br />
    <p>
      No agency relationship. Neither these Terms of Use, nor any Services
      Content, materials or features of the Services create any partnership,
      joint venture, employment, or other agency relationship between us and
      you. You may not enter into any contract on our behalf or bind us in any
      way.
    </p>
    <br />
    <p>
      Remedies. You agree that any violation, or threatened violation, by you of
      these Terms of Use constitutes an unlawful and unfair business practice
      that will cause us irreparable and unquantifiable harm. You also agree
      that monetary damages would be inadequate for such harm and consent to our
      obtaining any injunctive or equitable relief that we deem necessary or
      appropriate. These remedies are in addition to any other remedies we may
      have at law or in equity.
    </p>
    <br />
    <p>
      Assignment. You may not assign any of your rights under these Terms of
      Use, and any such attempt will be null and void. Dauchi and its affiliates
      may, in their individual discretion, transfer, without further consent or
      notification, all contractual rights and obligations pursuant to these
      Terms of Use if some or all of the business of Dauchi is transferred to
      another entity by way of merger, sale of its assets, or otherwise.
    </p>
    <br />
    <p>
      Notice for California Users. If you are a California resident, you hereby
      waive California Civil Code §1542, which says: "A general release does not
      extend to claims which the creditor does not know or suspect to exist in
      his favor at the time of executing the release, which if known by him must
      have materially affected his settlement with the debtor." This release
      includes the criminal acts of others.
    </p>
    <br />
    <p>
      Headings. The heading references herein are for convenience purposes only,
      and shall not be deemed to limit or affect any of the provisions hereof.
    </p>
    <br />
    <p>
      Entire Agreement. This is the entire agreement between you and Dauchi
      relating to the subject matter herein and supersedes all previous
      communications, representations, understandings and agreements, either
      oral or written, between the parties with respect to said subject matter,
      excluding any other agreements that you may have entered into with Dauchi.
    </p>
    <br />
    <br />
    <p className="font-bold">20. Contact Us</p>
    <br />
    <p>
      If you have any questions about these Terms of Use, please contact us at:
    </p>
    <br />
    <p>
      Dauchi 7216 Varna Avenue North Hollywood, CA 91605{" "}
      <span className="underline">support@dauchi.com</span>
    </p>
  </div>
);
