export const SurveyProgressElement = (props) => {
  const { section, sectionIndex, isPast, isCurrent, isFuture } = props;

  const bubbleClassNameSwitch = () => {
    if (isPast) {
      return pastElementClassName;
    } else if (isCurrent) {
      return currentElementClassName;
    } else if (isFuture) {
      return futureElementClassName;
    }
  };

  const pastElementClassName = `
    bg-darkPrimary
    border-2
    border-darkPrimary

  `;

  const currentElementClassName = `
    bg-darkPrimary
    border-2
    border-secondary
  `;

  const futureElementClassName = `
    bg-lightGray
    border-2
    border-lightGray
  `;

  const textClassNameSwitch = () => {
    if (isFuture) {
      return futureTextClassName;
    } else {
      return pastOrCurrentTextClassName;
    }
  };

  const futureTextClassName = `
    text-darkGray
  `;

  const pastOrCurrentTextClassName = `
    text-darkPrimary
  `;

  return (
    <div className="relative">
      <div
        className={`rounded-full flex items-center justify-center text-[14px] lg:text-base 2xl:text-2xl mx-auto text-white my-auto mb-[1rem] w-[1.8rem] lg:w-[2.5rem] 2xl:w-[3.75rem] h-[1.8rem] lg:h-[2.5rem] 2xl:h-[3.75rem] ${bubbleClassNameSwitch()}`}
      >
        {sectionIndex + 1}
      </div>
      <p
        className={`absolute -left-1/2 -right-1/2 font-subHeadline ${textClassNameSwitch()} text-[12px] lg:text-[1.37rem]`}
        style={{ fontWeight: "600" }}
      >
        {section.name}
      </p>
    </div>
  );
};
