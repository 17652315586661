import React, { useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { IoIosStar } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { FaPhoneAlt } from "react-icons/fa";
import { BsFillClockFill } from "react-icons/bs";
import { PiGlobe } from "react-icons/pi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import _ from "lodash";

const FetchedPharmacySection = ({
  isLoading,
  isError,
  isMobile,
  fetchedPharmacies,
}) => {
  const { user } = useAppContext();
  const { pharmacies } = user;
  const primarPharmacy = _.find(pharmacies, { is_primary: true });

  const [selectedPharmacy, setSelectedPharmacy] = useState(null);

  const MarkAsDefaultComponent = (pharmacy) => {
    const {
      name,
      address1,
      address2,
      city,
      id,
      zip_code,
      state,
      primary_phone,
    } = pharmacy;
    const email = "support@dauchi.com";
    const emailSubject = encodeURIComponent("Pharmacy Change Request");
    const emailBody = encodeURIComponent(`
        Request to change Pharamcy to following:\n\n
        ID: ${id}\n
        Name: ${name}\n
        Contact: ${primary_phone}\n
        location: ${[address1, address2, city, state, zip_code].join(", ")}
    `);
    const mailtoLink = `mailto:${email}?subject=${emailSubject}&body=${emailBody}`;

    return (
      <label class="custom-radio">
        <a
          href={mailtoLink}
          class={`radio-label text-[14px] lg:text-[1rem] text-primary hover:font-bold lg:mx-[1rem] underline`}
        >
          Mark as default pharmacy
        </a>
      </label>
    );
  };

  const Pharmacy = ({ pharmacy }) => {
    const isPrimary = primarPharmacy?.mapping_id == pharmacy.id;
    const isSelected = pharmacy.id === selectedPharmacy?.id;
    return (
      <div className="flex items-center">
        {isSelected && (
          <div className="hidden md:block h-[3.75rem] mb-[2.5rem] w-[5px] bg-secondary rounded-r-full" />
        )}
        <div
          className="w-full mb-[2rem] flex flex-col px-[16px] md:px-0 md:mx-[20px] pb-[40px] md:pb-0 border-b-[1px] border-inputGraySecondary cursor-pointer relative"
          onClick={() => setSelectedPharmacy(pharmacy)}
        >
          <div className="flex flex-col lg:flex-row justify-between mb-[10px]">
            <div className="flex items-center">
              <label className="text-[1.2rem] 2xl:text-[1.25rem] font-medium">
                {pharmacy.name.split("#")[0]}
              </label>
              {isPrimary && (
                <span className="bg-primary text-white ml-[1rem] text-[10px] lg:text-[12px] px-[10px] py-[2px] rounded-full">
                  Curent Pharmacy
                </span>
              )}
            </div>
            {!isPrimary && !isMobile && MarkAsDefaultComponent(pharmacy)}
          </div>
          <div className="flex text-[1rem] 2xl:text-[20px] max-w-[300px] 2xl:max-w-[422px] flex-col items-start">
            {["address1", "primary_phone"].map((key) => (
              <>
                <p
                  key={key}
                  className="mb-[10px] text-base lg:text-[1.25rem] text-start"
                >
                  {key === "zip_code"
                    ? pharmacy[key].split("-")[0]
                    : pharmacy[key]}
                </p>
              </>
            ))}
          </div>
          {!isPrimary && isMobile && MarkAsDefaultComponent(pharmacy)}
          <div className="bg-primary rounded-full p-[1px] absolute top-1/2 translate-y-[-50%] right-4 md:hidden">
            <MdOutlineKeyboardArrowRight size={32} className="text-secondary" />
          </div>
        </div>
      </div>
    );
  };

  const SelectedPharmacy = () => {
    const isPrimary = primarPharmacy?.mapping_id == selectedPharmacy.id;
    const rating = 4.7;
    const fullStars = Math.floor(rating);

    const pharmacyImage =
      "https://i.pinimg.com/originals/5c/00/4b/5c004b7ee153412f3e4a6604c29a22db.jpg";

    const detailsList = [
      {
        name: "address1",
        icon: <HiLocationMarker size={20} className="text-primary" />,
      },
      {
        name: "primary_phone",
        icon: <FaPhoneAlt size={20} className="text-primary" />,
      },
      {
        name: "schedule",
        icon: <BsFillClockFill size={20} className="text-primary" />,
      },
      { name: "url", icon: <PiGlobe size={20} className="text-primary" /> },
    ];

    selectedPharmacy.schedule = "7am - 11pm";
    selectedPharmacy.url = "https://www.cvspharmacy.org";

    return (
      <div className="self-start w-full lg:w-[60%] rounded-lg border-[1px] border-inputGraySecondary border-opacity-40 p-[1.25rem] text-start mb-[1.25rem]">
        <div className="flex justify-between items-center mb-[10px]">
          <h2 className="text-[1.2rem] 2xl:text-[1.75rem] text-primary font-medium text-start">
            {selectedPharmacy.name.split("#")[0]}
          </h2>
          {isPrimary && (
            <span className="bg-primary text-white ml-[1rem] text-[10px] lg:text-[12px] font-medium px-[10px] py-[2px] rounded-full">
              Curent Pharmacy
            </span>
          )}
        </div>
        <div className="flex items-center space-x-[6px] mb-[10px]">
          <div className="flex items-center space-x-[6px]">
            {Array(fullStars)
              .fill(0)
              .map((_, index) => (
                <IoIosStar key={index} className="text-yellow-500" size={20} />
              ))}
          </div>
          <p className="text-[18px]">{"(72)"}</p>
        </div>
        <p className="text-[18px] mb-[60px]">
          <span className="text-green-800">Open</span> . Closes at 10pm
        </p>
        <div className="w-full flex flex-col 2xl:flex-row justify-between">
          <div className="flex flex-col">
            {detailsList.map((detail) => (
              <div className="flex mb-[1.56rem]">
                <div className="bg-secondary self-start p-1 rounded-full">
                  {detail.icon}
                </div>
                <p className="ml-[1.25rem] text-[1rem] xl:text-[1.25rem]">
                  {selectedPharmacy[detail.name]}
                </p>
              </div>
            ))}
          </div>
          <img
            className="max-h-[180px] max-w-[280px] object-cover"
            src={selectedPharmacy.image || pharmacyImage}
            alt=""
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <p>Loading Pharmacies...</p>;
  }

  if (isError) {
    return <p>Error fetching pharmacy data.</p>;
  }

  if (!fetchedPharmacies.length) {
    return <div>No pharmacies found</div>;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-[1rem] xl:space-x-[1.875rem]">
        <div className="w-full lg:w-[40%] min-w-[20rem] lg:min-w-[22rem] justify-between flex flex-col border-[1px] rounded-md md:rounded-xl border-opacity-40 border-inputGraySecondary">
          <h2 className="px-[1rem] py-[7px] bg-primary text-white md:text-black md:bg-transparent md:p-[20px] md:px-[20px] font-medium text-[1.25rem] text-start mb-[1.87rem] md:mb-0">
            Search Results
          </h2>
          {fetchedPharmacies.map((pharmacy, index) => (
            <Pharmacy key={index} pharmacy={pharmacy} />
          ))}
        </div>
        {selectedPharmacy && <SelectedPharmacy />}
      </div>
    </div>
  );
};

export default FetchedPharmacySection;
