import React from "react";
import { CheckmarkSvg } from "../svgs/CheckmarkSvg";
import { colors } from "../../theme";
import { useSurveyState } from "./SurveyContext";

const SubscriptionCard = () => {
  const [state] = useSurveyState();

  const answer = state.selectedSubscription;

  if (!answer) {
    return;
  }

  return (
    <div
      className={`border border-inputGray w-full rounded-[18px] sm:rounded-[25px] lg:rounded-[35px] bg-primary mb-[3rem]`}
    >
      <label
        className={`w-full mx-[0.67%] border-inputGray text-base 2xl:text-2xl p-2 rounded cursor-pointer`}
      >
        <div
          className={`flex justify-between px-[1.6rem] sm:px-[2.3rem] lg:px-[3.75rem] ${
            answer.additional_answer_details_list.length > 0 &&
            "border-b-[1px] border-white pb-[30px]"
          }`}
        >
          <p
            className={`text-[1rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[3rem] text-white font-bold text-start max-w-[36.37rem]`}
          >
            {answer.label}
          </p>
          <p
            className={`text-[0.9rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[2.93rem] font-bold`}
          >
            {answer.secondary_label.split("/").map((part, index, array) => (
              <span
                key={index}
                className={index === 1 ? "text-secondary" : "text-white"}
              >
                {part}
                {index < array.length - 1 ? "/" : ""}
              </span>
            ))}
          </p>
        </div>

        {answer.additional_answer_details_list.length > 0 && (
          <div className="py-[1.8rem] sm:py-[2rem] lg:py-[3.12rem] text-white text-start px-[1.6rem] sm:px-[2.3rem] lg:px-[3.75rem]">
            {answer.additional_answer_details_list.map(
              (detail, detailIndex) => (
                <div className="flex items-center gap-[1.2rem] sm:gap-[2.5rem] mb-[8px]">
                  <CheckmarkSvg fillColor={colors.secondary} />
                  <p
                    key={detailIndex}
                    className="text-[14px] lg:text-base 2xl:text-2xl font-bold"
                  >
                    {detail}
                  </p>
                </div>
              )
            )}
          </div>
        )}
        <div className="pr-[2.3rem] lg:pr-[3.75rem] text-[18px] md:text-[1.75rem] 2xl:text-[3rem] flex justify-end items-center">
          <div className="h-[1px] mr-[1rem] lg:mr-[2rem] bg-white w-full" />
          <p className="text-white font-bold mr-[10px]">Total:</p>
          <p className="text-secondary font-semibold">
            {answer.secondary_label}
          </p>
        </div>
      </label>
    </div>
  );
};

export default SubscriptionCard;
