import _ from "lodash";

export const paymentPlaceholderQuestion = {
  id: 5,
  name: "Payment",
  subheader: "Payment Information",
  order: 5,
  questions: [
    {
      id: 18,
      searchable_tag: "payment_fields_question",
      order: 20,
      question_text: "",
      input_placeholder_text: null,
      required: false,
      input_type: "credit_card_input",
      dependent_question_conditions: null,
      is_default_visible: true,
      is_internal_hidden_input_question: false,
      answers: [],
    },
  ],
};

export const getPendingExternalSurveys = (externalSurvey) => {
  if (externalSurvey?.length) {
    const pendingSurveys = externalSurvey.filter((survey) =>
      _.isEmpty(survey.completed_at)
    );

    return pendingSurveys;
  }
};

export const markSurveyCompleted = (
  externalSurveys,
  setExternalSurveys,
  surveyToMark
) => {
  const surveys = Object.assign([], externalSurveys);
  const survey = _.find(surveys, {
    id: surveyToMark?.id,
  });

  survey.completed_at = new Date().toISOString();
  setExternalSurveys(surveys);
};
