import { emailRegex } from "./validation";

export const loginFormData = [
  {
    name: "email",
    type: "email",
    placeholder: "Email",
    rules: {
      required: "Email is required",
      pattern: {
        value: emailRegex,
        message: "Invalid Email Address",
      },
    },
  },
  {
    name: "password",
    type: "password",
    placeholder: "Password",
    rules: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must be at least 8 characters long",
      },
    },
  },
];


export const forgotPasswordFormData = [
  {
    name: "email",
    type: "email",
    placeholder: "Email",
    rules: {
      required: "Email is required",
      pattern: {
        value: emailRegex,
        message: "Invalid Email Address",
      },
    },
  }
]