import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { fetchFaqs } from "../../services/fetchFaqs";
import { fetchSubscriptions } from "../../services/fetchSubscriptions";
import { fetchNotifications } from "../../services/notifications";

export const ProtectedRoute = ({ children, redirectTo }) => {
  const {
    user,
    isLoggedIn,
    faqs,
    setFaqs,
    subscriptions,
    setSubscriptions,
    notifications,
    setNotifications,
  } = useAppContext();

  const navigate = useNavigate();

  const fetchAndSetFaqs = async () => {
    try {
      const faqsData = await fetchFaqs();
      setFaqs(faqsData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAndSetUserSubscriptions = async () => {
    try {
      const subscriptionData = await fetchSubscriptions(user?.id);
      setSubscriptions(subscriptionData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAndSetNotifications = async () => {
    try {
      const notificationsData = await fetchNotifications(user?.id);
      setNotifications(notificationsData);
    } catch (error) {
      console.error(error);
    }
  };

  const performLoggedInOperations = () => {
    if (!faqs.length) fetchAndSetFaqs();

    if (!subscriptions.length) fetchAndSetUserSubscriptions();

    if (!notifications.length) fetchAndSetNotifications();
  };

  useEffect(() => {
    if (isLoggedIn()) {
      performLoggedInOperations();
    } else {
      navigate("/login");
    }
  }, [user]);

  if (!isLoggedIn()) {
    return <Navigate to={redirectTo || "/"} replace />;
  }

  return children;
};
