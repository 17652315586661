import { useAppContext } from "../../context/AppContext";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { signout } from "../../services/auth";
import { loggedInHeaderRoutes } from "../../utils/routes";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";

const DashboardHeader = ({ pendingSurveys }) => {
  const { resetAppContext, user, notifications } = useAppContext();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      await signout();
    } catch (error) {
      console.error(error.message);
    } finally {
      resetAppContext();
      navigate("/login");
    }
  };

  const loggedInItem = (item, index) => {
    if (!item.desktopVisibility) return <></>;
    return item.type === "link" ? (
      <NavLink key={index} to={item.href} className="relative">
        <img
          src={item.desktopIcon}
          alt={item.name}
          className="w-[2.2rem] h-[2.2rem] p-[.3rem] bg-secondary rounded-full"
        />
      </NavLink>
    ) : (
      <div className="relative">
        <div
          className="flex items-center justify-center cursor-pointer"
          // onClick={() => handleNavItemClick(item)}
        >
          {item.text === "My Profile" ? (
            <>
              <div className="w-[.1rem] h-[2rem] bg-borderGray mr-3" />
              <p className="text-darkGray font-semibold italichover:underline">
                Hi, {user?.first_name || "User"}!
              </p>
              <RiLogoutCircleRLine
                onClick={handleLogOut}
                className="mx-2 w-[1.3rem] h-[1.3rem]"
              />
            </>
          ) : (
            <img
              src={item.desktopIcon}
              alt={item.name}
              className="w-[2.2rem] h-[2.2rem] p-1 bg-secondary rounded-full"
            />
          )}
        </div>
        {notifications?.length && item.text === "Notifications" ? (
          <div
            className="w-5 h-5 bg-red-600 rounded-full absolute
              -bottom-1 -right-1 flex items-center justify-center"
          >
            <p className="text-white text-xs font-bold">
              {notifications?.length}
            </p>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="w-full flex items-start justify-between p-5">
      {/* Left */}
      <div className="rounded-md px-[1rem] bg-[#F6F6F6] flex items-center justify-center">
        {!pendingSurveys?.length && (
          <>
            <CiSearch className="w-5 h-5 cursor-pointer" />
            <input
              className="w-44 py-[0.7rem] pl-2 bg-[#F6F6F6] outline-none text-[14px]"
              placeholder="Search"
            />
          </>
        )}
      </div>

      {/* Right */}
      <div className="flex gap-4">
        {loggedInHeaderRoutes.map((item, index) => loggedInItem(item, index))}
      </div>
    </div>
  );
};

export default DashboardHeader;
