import { useState } from "react";
import DauchiLogo from "../../assets/images/DauLogo.png";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { Logo } from "../../components/logo/Logo";
import "./Login.scss";
import { useAppContext } from "../../context/AppContext";
import { Navigate } from "react-router-dom";
import LoginBack from "../../assets/images/herpesBanner.png";

const Login = () => {
  const { isLoggedIn } = useAppContext();
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const toggleForgotPassword = () => {
    setIsForgotPassword((prevState) => !prevState);
  };

  if (isLoggedIn()) return <Navigate to={"/dashboard"} replace />;

  return (
    <section className="relative md:absolute  min-h-screen w-full top-0 left-0 right-0 overflow-hidden bg-white z-50 flex">
      <div className="hidden md:flex relative z-20 flex-grow rounded-r-xl bg-gradient-to-b from-primary to-darkPrimary" />
      <div className="absolute z-20 h-full w-full bg-lightPrimary opacity-20 md:hidden" />
      <div className="absolute left-10 top-5 z-50 hidden md:block">
        <Logo />
      </div>
      <div className="absolute z-10 h-full w-full bg-red-300 md:hidden">
        <img src={LoginBack} className="h-full w-full object-cover" alt="" />
      </div>
      <div className="bg-transparent z-50 w-full md:w-[50%] lg:w-[40%] relative flex flex-col items-center justify-center pb-20">
        <img src={DauchiLogo} alt="" className="w-[10rem]" />

        {isForgotPassword ? (
          <ForgotPasswordForm
            toggleForgotPassword={toggleForgotPassword}
            isForgotPassword={isForgotPassword}
          />
        ) : (
          <LoginForm
            toggleForgotPassword={toggleForgotPassword}
            isForgotPassword={isForgotPassword}
          />
        )}
      </div>
    </section>
  );
};
export default Login;
