import { useQuery } from "@tanstack/react-query";
import { fetchFaqs } from "../../../services/fetchFaqs";
import { FaQuestion } from "react-icons/fa6";
import SectionTitle from "../SectionTitle";

function HelpSection() {
  const { data: faqs } = useQuery({
    queryKey: ["faqData"],
    queryFn: () => fetchFaqs(),
  });

  const renderFaqs = () => {
    return (
      <>
        {faqs?.map((faq, index) => (
          <div
            key={index}
            className="text-center lg:text-start border-[1px] border-inputGray border-opacity-40 p-[20px] col-span-1"
            id={faq.id}
          >
            <div className="w-full flex items-center justify-center">
              {faq.image ? (
                <img
                  src={faq.image}
                  className="h-[4.68rem] w-[4.68rem] object-cover rounded-full mb-[1.25rem]"
                />
              ) : (
                <span className="bg-secondary p-2 rounded-full h-[4.68rem] w-[4.68rem] flex items-center justify-center mb-[1.25rem]">
                  <FaQuestion size={48} className="text-primary" />
                </span>
              )}
            </div>
            <p className="mb-[1.25rem] font-medium text-[1.5rem] text-primary">
              {faq?.question_text}
            </p>
            <p className="text-base leading-[18.77px] font-normal">
              {faq?.answer_text}
            </p>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col md:px-[1.87rem] mb-[3.12rem]">
      <SectionTitle title="Help Center" />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[1.87rem] max-w-[78rem]">
        {renderFaqs()}
        <div className="text-center col-span-1 lg:col-span-2 xl:col-span-3 border-[1px] border-inputGray border-opacity-40 p-[20px]">
          <div className="w-full flex items-center justify-center">
            <span className="bg-secondary p-2 rounded-full h-[4.68rem] w-[4.68rem] flex items-center justify-center mb-[1.25rem]">
              <FaQuestion size={48} className="text-primary" />
            </span>
          </div>
          <p className="mb-[1.25rem] font-medium text-[1.5rem] text-primary">
            Still can’t find an answer to your question?
          </p>
          <p className="text-base leading-[18.77px] font-normal">
            No worries! Email us at{" "}
            <span className="text-primary">support@dauchi.com</span> and we’ll
            get back to you as soon as we can!
          </p>
        </div>
      </div>
    </div>
  );
}

export default HelpSection;
