import dauchiLogo from "../../../assets/images/DauLogo.png";
import { useForm } from "react-hook-form";
import InputField from "../../Login/InputField";
import Loader from "../../../components/Loader";
import { resetPassword } from "../../../services/auth";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const PasswordChange = () => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const uid = params.get("uid");
  const client = params.get("client");
  const accessToken = params.get("access-token");

  const onSubmit = async (formData) => {
    try {
      const headers = { client, uid, "access-token": accessToken };
      await resetPassword(formData, headers);

      setPasswordUpdated(true);
    } catch (error) {
      alert(
        error?.response?.data?.errors || error?.data?.errors || error?.message
      );
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm();

  const reserPasswordFields = [
    {
      name: "password",
      label: "Create Password",
      helperText:
        "Password must contain at least 8 characters, 1 capital letter (A-Z), and at least 1 symbol (!, @, #, $, %, ^, &, *, ., or ?).",
      placeholder: "Password",
      defaultValue: "",
      type: "password",
      rules: {
        required: "Password is required",
        minLength: {
          value: 8,
          message: "Password must be at least 8 characters long",
        },
        pattern: {
          value: /^(?=.*[A-Z])(?=.*[!@#$%^&*?.]).{8,}$/,
          message: "Invalid password format",
        },
      },
    },

    {
      name: "password_confirmation",
      label: "Re-enter Password",
      helperText: "Please re-enter your password that you created above",
      placeholder: "Confirm Password",
      defaultValue: "",
      type: "password",
      rules: {
        required: "Confirm Password is required",
        validate: {
          equal: (value) =>
            value === getValues().password ||
            "Confirm password must match Password",
        },
      },
    },
  ];

  if (!uid || !client || !accessToken) {
    return navigate("/404");
  }

  return (
    <section
      className="overflow-hidden 
      min-h-screen h-full bg-white z-50 flex
      flex-col items-center justify-start mt-[8rem]"
    >
      <img src={dauchiLogo} alt="" className="w-[10rem] mb-[5rem]" />
      {!passwordUpdated ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-[1.5rem]"
        >
          {reserPasswordFields.map((field) => (
            <InputField
              key={field.name}
              type={field.type}
              placeholder={field.placeholder}
              register={register}
              rules={field.rules}
              error={errors[field.name]}
              name={field.name}
            />
          ))}
          <div>
            {isSubmitting ? (
              <div className="w-[20rem]">
                <Loader />
              </div>
            ) : (
              <button
                type="submit"
                className={`bg-secondary font-bold text-xl 
               text-darkPrimary py-3
                rounded-full border-l-2 border-white w-[20rem]`}
              >
                Change Password
              </button>
            )}
          </div>
        </form>
      ) : (
        <div className="flex flex-col gap-[1.5rem]">
          <h1 className="text-primary font-headline font-semibold text-3xl mb-8">
            Password Updated Successfully!
          </h1>
          <Link to={"/login"}>
            <button
              type="submit"
              className={`bg-secondary font-bold text-xl 
               text-darkPrimary py-3
                rounded-full border-l-2 border-white w-[20rem]`}
            >
              Login
            </button>
          </Link>
        </div>
      )}
    </section>
  );
};
export default PasswordChange;
