import React from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { MobileFooter } from "./MobileFooter";
import { DesktopFooter } from "./DesktopFooter";
import { treatments } from "../../utils/treatments";
import { FaFacebookSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitterSquare } from "react-icons/fa";

export const Footer = () => {
  const { isMobile } = useWindowDimensions();
  const icons = [
    {
      icon: <AiFillInstagram />,
    },
    {
      icon: <FaFacebookSquare />,
    },
    {
      icon: <FaTwitterSquare />,
    },
  ];

  const resources = [
    { name: "Patient Portal" },
    {
      name: "Privacy Policy",
      href: "/privacy",
    },
    {
      name: "How it works",
    },
  ];

  const aboutUs = [
    {
      name: "Frequently Asked Questions",
    },
  ];

  return isMobile ? (
    <MobileFooter
      treatments={treatments}
      resources={resources}
      aboutUs={aboutUs}
      icons={icons}
    />
  ) : (
    <DesktopFooter
      treatments={treatments}
      resources={resources}
      aboutUs={aboutUs}
      icons={icons}
    />
  );
};
