import Medicine from "../../assets/images/Medicine.png";
import Snapshot from "../../assets/images/snapshot.png";
import ContactUs from "../../assets/images/ContactUs.png";
import Logo from "../../assets/images/DauLogo.png";
import QuestionMark from "../../assets/images/question_marks";
import Provider from "../../assets/images/OurProviderMobile.png";
export const MobileMoreFromDauchi = () => {
  return (
    <div>
      <div className="bg-primary pb-[2rem]">
        <div className="flex flex-col items-start px-[5%] pt-[1.5rem]">
          <h1 className="text-secondary text-3xl">More from Dauchi</h1>
          <div className="text-secondary text-lg pb-4">
            Everything you need from A to Z
          </div>
        </div>
        <div className="flex flex-col items-center px-[1.25rem]">
          <div className="flex relative w-full justify-between bg-primary border-[1px] border-white rounded-xl h-[150px] smallCustom:h-[200px] sm:h-[250px] my-2">
            <div className="flex flex-col justify-center pl-6 sm:pl-10 text-secondary ">
              <h1 className="text-start text-xl smallCustom:text-[1.75rem] sm:text-4xl font-subHeadline ">
                What We Treat
              </h1>
              <p className="text-start text-[10px] smallCustom:text-xs sm:text-md font-subHeadline w-[70%]">
                We have a wide range of treatments. Check them out.
              </p>
            </div>
            <img
              src={Medicine}
              alt="Medicine"
              className="absolute right-0 rounded-xl h-[9.2rem] smallCustom:h-[12.2rem] sm:h-[15.4rem]"
            />
          </div>
          <div className="flex w-full justify-between bg-secondary border-[1px] border-white rounded-xl h-[150px] smallCustom:h-[200px] sm:h-[250px] my-2">
            <div className="flex items-end pl-6 smallCustom:pl-10 sm:pl-12">
              <img
                src={Snapshot}
                alt="snapshot"
                className="h-[8rem] smallCustom:h-[10rem] sm:h-[12rem]"
              />
            </div>
            <div className="flex flex-col justify-center w-[50%]">
              <h1 className="text-darkPrimary text-start text-xl smallCustom:text-[1.75rem] sm:text-4xl font-subHeadline">
                Patient Portal
              </h1>
              <p className="text-darkPrimary text-start text-[10px] smallCustom:text-xs sm:text-md font-subHeadline mt-1 w-[80%]">
                Manage your subscriptions in our new patient portal.
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full justify-between bg-darkPrimary rounded-xl border-[1px] border-white h-[150px] smallCustom:h-[200px] sm:h-[250px] my-2">
            <div className="flex flex-col items-center pt-2 sm:pt-4">
              <h1 className="text-white text-start text-xl smallCustom:text-[1.75rem] sm:text-4xl font-subHeadline">
                Contact Us
              </h1>
              <p className="text-white text-start text-[10px] smallCustom:text-xs sm:text-md w-[40%] smallCustom:w-[35%] sm:w-[35%] font-subHeadline">
                Shoot your shot. Slide into our DM’s!
              </p>
            </div>
            <div className="flex justify-center px-[3rem] smallCustom:px-0">
              <img src={ContactUs} alt="contact-us" />
            </div>
          </div>
          <div className="flex justify-between w-full bg-lightSecondary rounded-xl border-[1px] h-[150px] smallCustom:h-[200px] sm:h-[250px] my-2 border-white">
            <div className="flex-1 p-6 smallCustom:p-8 sm:p-10 flex flex-col justify-center">
              <h1 className="text-darkPrimary text-start text-xl smallCustom:text-[1.75rem] sm:text-4xl font-subHeadline">
                About
              </h1>
              <p className="text-darkPrimary text-start text-[10px] smallCustom:text-xs sm:text-md font-subHeadline w-[70%]">
                Curious about us? Aw shucks.
              </p>
            </div>
            <div className="flex flex-1 items-center justify-end pr-8 sm:pr-10">
              <img
                src={Logo}
                alt="dauchi-logo"
                className="h-28 smallCustom:h-32 sm:h-40"
              />
            </div>
          </div>

          <div className="flex bg-lightPrimary rounded-xl w-full text-white border-[1px] border-white h-[150px] smallCustom:h-[200px] sm:h-[250px] my-2">
            <div className="flex flex-col pt-6 pl-6 smallCustom:pt-8 smallCustom:pl-8 sm:pt-10 sm:pl-10">
              <h1 className="text-xl smallCustom:text-[1.75rem] sm:text-4xl text-start font-subHeadline">
                FAQ's
              </h1>
              <p className="text-start text-[10px] smallCustom:text-xs sm:text-md font-subHeadline mt-1 w-[80%] smallCustom:w-[70%]">
                You’ve got questions? We’ve got answers
              </p>
            </div>
            <div className="flex justify-between items-center">
              <img
                src={QuestionMark}
                alt="question-mark"
                className="h-[8rem] smallCustom:h-[9rem] sm:h-[12rem]"
              />
            </div>
          </div>
          <div className="flex w-full bg-primary rounded-xl border-[1px] h-[150px] smallCustom:h-[200px] sm:h-[250px] my-2 border-white">
            <div className="pt-6 pl-6 smallCustom:pt-8 smallCustom:pl-8 sm:pt-10 sm:pl-10">
              <h1 className="text-white text-start text-xl smallCustom:text-[1.75rem] sm:text-4xl font-subHeadline">
                Our Providers
              </h1>
              <p className="text-white text-start text-[10px] smallCustom:text-xs sm:text-md font-subHeadline mt-1 w-[70%] smallCustom:w-[65%]">
                We’ve got a great group. They’ve got you covered!
              </p>
            </div>
            <div className="flex items-end">
              <img
                src={Provider}
                alt="our-provider"
                className="smallCustom:h-[10rem] sm:h-[13rem]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
