import React, { useEffect, useState } from "react";
import { fieldIsRequiredMessage } from "../../constants/ValidationErrorConstants";
import { CheckmarkSvg } from "../svgs/CheckmarkSvg";
import { colors } from "../../theme";
import { useSurveyState } from "./SurveyContext";
import { fetchUserSpecificSubscriptions } from "../../services/survey";
import { useAppContext } from "../../context/AppContext";
import { useQuery } from "@tanstack/react-query";
import Loader from "../Loader";
import _ from "lodash";

export const SurveyTreatmentSubscriptionSelect = (props) => {
  const {
    question,
    register,
    updateQuestionVisibilities,
    setValue,
    watch,
    trigger,
  } = props;
  const { userSurveyInfo } = useAppContext();

  const { data: subscriptionOptions, isLoading } = useQuery({
    queryKey: ["userSpecificSubscriptions"],
    queryFn: () => fetchUserSpecificSubscriptions(userSurveyInfo?.userSurveyId),
  });

  const [state, setState] = useSurveyState();
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerSelect = (answer) => {
    setState((prevState) => ({
      ...prevState,
      selectedSubscription: answer,
    }));
    if (selectedAnswer === answer?.searchable_tag) {
      setSelectedAnswer(null);
    } else {
      setSelectedAnswer(answer?.searchable_tag);
    }
    setValue(question.searchable_tag, answer.searchable_tag);
    updateQuestionVisibilities(question, answer);
    trigger(question.searchable_tag);
  };

  const answerTags =
    subscriptionOptions?.subscriptions?.map((sub) => sub?.searchable_tag) || [];
  const filteredAnswers =
    question.answers.filter((answer) =>
      answerTags.includes(answer.searchable_tag)
    ) || question.answers;

  useEffect(() => {
    console.warn(question);
    // Automatically select first subscription option.
    if (!_.isEmpty(subscriptionOptions)) {
      handleAnswerSelect(filteredAnswers[0]);
    }
  }, [subscriptionOptions]);

  return (
    <div className="flex flex-col gap-[1.5rem] sm:gap-[3rem] justify-center items-center">
      {isLoading ? (
        <Loader />
      ) : (
        filteredAnswers.map((answer, index) => {
          const isSelected =
            answer.searchable_tag === watch(question.searchable_tag);

          return (
            <div className="w-full">
              <div
                key={answer.searchable_tag}
                className={`border border-borderGraySmall lg:border-borderGray w-full rounded-[18px] sm:rounded-[25px] lg:rounded-[35px] ${
                  isSelected ? "bg-primary" : "bg-white"
                }`}
              >
                <label
                  className={`w-full mx-[0.67%] border-inputGray text-base 2xl:text-2xl p-2 rounded cursor-pointer`}
                  onClick={() => handleAnswerSelect(answer)}
                >
                  <input
                    {...register(question.searchable_tag, {
                      required: fieldIsRequiredMessage(
                        question.validation_field_name
                      ),
                    })}
                    type="radio"
                    value={answer.searchable_tag}
                    onChange={() => handleAnswerSelect(answer)}
                    checked={isSelected}
                    className="hidden"
                  />
                  <div
                    className={`flex justify-between px-[1.6rem] sm:px-[2.3rem] lg:px-[3.75rem] ${
                      isSelected &&
                      answer.additional_answer_details_list.length > 0 &&
                      "border-b-[1px] border-white pb-[30px]"
                    }`}
                  >
                    <p
                      className={`${
                        isSelected
                          ? "text-[1rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[3rem] text-secondary"
                          : "text-[0.9rem] sm:text-[1.6rem] lg:text-[1.8rem] 2xl:text-[2.31rem] text-primary"
                      } font-bold text-start max-w-[36.37rem]`}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: answer.label.replace(
                            /(On-Demand)/,
                            isSelected
                              ? "<span class='text-secondary'>$1</span>"
                              : "<span class='text-gray'>$1</span>"
                          ),
                        }}
                      ></span>
                    </p>
                    <p
                      className={`text-[0.9rem] sm:text-[1.8rem] lg:text-[2rem] 2xl:text-[2.93rem] font-bold `}
                    >
                      {answer.secondary_label
                        .split("/")
                        .map((part, index, array) => (
                          <span
                            key={index}
                            className={
                              index === 1
                                ? isSelected
                                  ? "text-secondary"
                                  : "text-primary"
                                : isSelected
                                ? "text-white"
                                : "text-gray"
                            }
                          >
                            {part}
                            {index < array.length - 1 ? "/" : ""}
                          </span>
                        ))}
                    </p>
                  </div>

                  {isSelected &&
                    answer.additional_answer_details_list.length > 0 && (
                      <div className="py-[1.8rem] sm:py-[2rem] lg:py-[3.12rem] text-white text-start px-[1.6rem] sm:px-[2.3rem] lg:px-[3.75rem]">
                        {answer.additional_answer_details_list.map(
                          (detail, detailIndex) => (
                            <div className="flex items-center gap-[1.2rem] sm:gap-[2.5rem] mb-[8px]">
                              <CheckmarkSvg fillColor={colors.secondary} />
                              <p
                                key={detailIndex}
                                className="text-[14px] lg:text-base 2xl:text-2xl font-bold"
                              >
                                {detail}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    )}
                </label>
              </div>
              {isSelected && (
                <p className="lg:text-start pt-[10px] md:pt-[1rem] lg:pt-[1.25rem] text-inputGray text-[10px] md:text-[12px] lg:text-[14px] 2xl:text-[1.3rem] opacity-70 italic">
                  ** Initial prescription and refills are issued when medically
                  appropriate, subject to physician’s medical judgement, and not
                  guaranteed.
                </p>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};
