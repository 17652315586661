import DesktopMailIcon from "../assets/icons/mail.svg";
import DesktopNotificationIcon from "../assets/icons/notifications.svg";
import DesktopProfileIcon from "../assets/icons/profile.svg";
import MailIcon from "../assets/icons/mail2.svg";
import ProfileIcon from "../assets/icons/person.svg";
import HomeIcon from "../assets/icons/home.svg";
import SubscriptionIcon from "../assets/icons/ballot.svg";
import HelpIcon from "../assets/icons/help_center.svg";
import OrdersIcon from "../assets/icons/package_2.svg";
import PharmacyIcon from "../assets/icons/medical_services.svg";

export const profileRoute = {
  text: "My Profile",
  href: "dashboard/profile",
};

export const loggedInHeaderRoutes = [
  {
    text: "Notifications",
    desktopIcon: DesktopNotificationIcon,
    icon: "",
    type: "link",
    href: "/dashboard/notifications",
    desktopVisibility: true,
    mobileVisibility: false,
  },
  {
    text: "Start New Treatment",
    desktopIcon: DesktopMailIcon,
    icon: SubscriptionIcon,
    type: "link",
    href: "/dashboard/treatments",
    desktopVisibility: false,
    mobileVisibility: true,
  },
  {
    text: "Messaging",
    desktopIcon: DesktopMailIcon,
    icon: MailIcon,
    type: "link",
    href: "/dashboard/chat",
    desktopVisibility: false,
    mobileVisibility: true,
  },
  {
    text: "My Profile",
    desktopIcon: DesktopProfileIcon,
    icon: ProfileIcon,
    type: "button",
    desktopVisibility: true,
    mobileVisibility: true,
  },
  {
    text: "Log out",
    desktopIcon: "",
    icon: "",
    desktopVisibility: false,
    mobileVisibility: true,
  },
];

export const dashboardRoutes = [
  { text: "Home", icon: HomeIcon, href: "/dashboard/" },
  {
    text: "Subscription",
    icon: SubscriptionIcon,
    href: "/dashboard/subscriptions",
  },
  { text: "Orders", icon: OrdersIcon, href: "/dashboard/order" },
  { text: "Pharmacies", icon: PharmacyIcon, href: "/dashboard/pharmacies" },
  { text: "Help", icon: HelpIcon, href: "/dashboard/help" },
];

export const headerRoutes = [
  { text: "HOW IT WORKS", href: "#three-simple-steps" },
  { text: "WHAT WE TREAT", href: "#treatments-offered" },
  { text: "OUR DOCTORS", href: "our-doctors" },
  { text: "RESOURCES", href: "resources" },
  { text: "PATIENT PORTAL", href: "/dashboard" },
];
