import { MakeBackendRequest } from "./MakeBackendRequest";
import _ from "lodash";

export const fetchUserSpecificSubscriptions = async (userSurveyId) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/user_surveys/${userSurveyId}/filter_subscriptions`,
      method: "get",
      data: {},
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
