const InputField = ({
  name,
  label,
  type,
  placeholder,
  register,
  error,
  rules,
}) => {
  return (
    <div className="flex flex-col items-start">
      {label && (
        <label className="text-[13px] md:text-[14px] mb-[6px] md:mb-[12px]">
          {label}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        className="outline-none h-[2.5rem] md:h-[3.75rem] 2xl:px-[1.5rem] text-base 2xl:text-base p-2 w-full border border-borderGraySmall lg:border-borderGray rounded placeholder:text-black"
        {...register(name, rules)}
        name={name}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
    </div>
  );
};

export default InputField;
