import React, { useEffect } from "react";
import TreatmentHero from "../../components/Treatment/TreatmentHero";
import TreatmentInfoBanner from "../../components/Treatment/TreatmentInfoBanner";
import TreatmentWhatsNeeded from "../../components/Treatment/TreatmentWhatsNeeded";
import TreatmentFooterHero from "../../components/Treatment/TreatmentFooterHero";
import { useLocation } from "react-router-dom";
import { treatments } from "../../utils/treatments";
import _ from "lodash";

const TreatmentPage = ({ path }) => {
  const treatment =
    _.find(treatments, {
      campaignPageHref: `/${path}`,
    }) || {};

  const treatmentContent = treatment?.content;
  const surveyPath = treatment?.href;

  const { hero, infoBanner, footerHero, backgroundColor, common } =
    treatmentContent || {};

  useEffect(() => {
    document.documentElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <div className={`${backgroundColor}`}>
      <TreatmentHero content={hero} surveyPath={surveyPath} common={common} />
      <TreatmentInfoBanner title={hero.title} content={infoBanner} />
      <TreatmentWhatsNeeded
        content={treatmentContent}
        surveyPath={surveyPath}
      />
      {!_.isEmpty(footerHero) && (
        <TreatmentFooterHero content={footerHero} common={common} />
      )}
    </div>
  );
};

export default TreatmentPage;
