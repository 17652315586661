import React from "react";
import {
  emailInputTypeEnumString,
  fieldIsRequiredMessage,
} from "../../constants/ValidationErrorConstants";
import { useAppContext } from "../../context/AppContext";

export const SurveyEmailInputField = ({ question, register, hasUserData }) => {
  const { user } = useAppContext();
  const isEmailValid = (email) => {
    if (!question.input_type === emailInputTypeEnumString) {
      return true;
    }
    // Regular expression for basic email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  return (
    <div className="w-full p-1">
      <input
        type="text"
        placeholder={question.input_placeholder_text}
        {...register(question.searchable_tag, {
          required: fieldIsRequiredMessage(question.validation_field_name),
          validate: (value) => isEmailValid(value) || "Invalid email format",
        })}
        className={`outline-none text-[0.75rem] sm:text-base 2xl:text-2xl p-2 w-full border-[1px] border-borderGraySmall lg:border-borderGray font-inter text-inputGraySecondary font-semibold rounded ${
          hasUserData && "opacity-50 cursor-not-allowed"
        }`}
        autoCapitalize="none"
        defaultValue={user?.email}
        disabled={hasUserData ? true : false}
      />
    </div>
  );
};
