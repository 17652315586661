import React from "react";

export const SurveyMultiSelectField = ({
  question,
  register,
  errors,
  watch,
  setValue,
  trigger,
}) => {
  const atLeastOneCheckboxChecked = (data) => {
    if (data.length) {
      return true;
    }
    return "At least one selection must be made";
  };

  const handleCheckboxChange = (value) => {
    // Use watch to get the current state of the checkboxes
    const selectedOptions = watch(question.searchable_tag) || [];
    if (selectedOptions.includes(value)) {
      setValue(
        question.searchable_tag,
        selectedOptions.filter((item) => item !== value)
      );
    } else {
      setValue(question.searchable_tag, [...selectedOptions, value]);
    }
    trigger(question.searchable_tag);
  };

  const handleOtherInputChange = (event) => {
    setValue(`${question.searchable_tag}_other_input`, event.target.value);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex justify-start lg:justify-center justify-between items-center flex-wrap lg:flex-nowrap ">
        {question.answers.map((answer, index) => {
          const isChecked = watch(question.searchable_tag)
            ? watch(question.searchable_tag).includes(answer.searchable_tag)
            : false;

          return (
            <label
              key={index}
              className="m-1 lg:m-2 w-[47%] sm:w-[48%] lg:w-[35%] mb-[1rem] lg:mb-0"
            >
              <input
                name={`${question.searchable_tag}-${answer.searchable_tag}`}
                type="checkbox"
                value={answer.searchable_tag}
                {...register(question.searchable_tag, {
                  validate: atLeastOneCheckboxChecked,
                })}
                checked={isChecked}
                onChange={() => handleCheckboxChange(answer.searchable_tag)}
                className="hidden"
              />
              <div
                className={`break-words text-[0.75rem] sm:text-base 2xl:text-2xl border-[1px] border-borderGraySmall lg:border-borderGray font-semibold rounded p-2 cursor-pointer transition-colors ${
                  isChecked
                    ? "bg-primary text-white"
                    : "bg-white text-inputGraySecondary"
                }`}
              >
                {answer.label}
              </div>
            </label>
          );
        })}
      </div>

      {watch(question.searchable_tag) &&
        watch(question.searchable_tag).includes("other_input_answer") && (
          <div className="w-full p-1 lg:px-[8px] lg:pt-[1rem]">
            <input
              type="text"
              name={`${question.searchable_tag}_other_input`}
              value={watch(`${question.searchable_tag}_other_input`) || ""}
              onChange={handleOtherInputChange}
              {...register(`${question.searchable_tag}_other_input`, {
                required: "Other location is required",
              })}
              placeholder="Enter other location"
              className="outline-none p-2 w-full border-[1px] border-borderGraySmall lg:border-borderGray font-inter text-inputGraySecondary font-semibold rounded text-[0.75rem] sm:text-base 2xl:text-2xl"
            />
            {errors[`${question.searchable_tag}_other_input`] && (
              <p className="text-red-700 mt-1">
                {errors[`${question.searchable_tag}_other_input`].message}
              </p>
            )}
          </div>
        )}
    </div>
  );
};
