import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SurveyWizard } from "../../components/survey/SurveyWizard";
import { treatments } from "../../utils/treatments";
import _ from "lodash";

// TODO: Refactor to bring Preliminary Survey for
// this and all treatments under one file e.g(Herpes)
const UTIPreliminarySurvey = () => {
  const utiSearchableTag =
    process.env.REACT_APP_UTI_PRELIMINARY_SURVEY_SEARCHABLE_TAG;

  const surveyPath = _.find(treatments, {
    searchable_tag: utiSearchableTag,
  })?.href;

  useEffect(() => {
    console.warn(surveyPath);
    console.warn(process.env.REACT_APP_UTI_PRELIMINARY_SURVEY_SEARCHABLE_TAG);
  }, []);

  return (
    <SurveyWizard
      surveySearchableTag={utiSearchableTag}
      treatmentSurveyPath={surveyPath}
    />
  );
};

export default UTIPreliminarySurvey;
