import { useEffect } from "react";
import closeIcon from "../../assets/icons/close.png";

const Modal = ({ content, onClose = () => {} }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "auto");
  });

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-end md:items-center justify-center z-50">
      <div className="bg-black absolute top-0 left-0 w-full h-full opacity-40" />
      {/* Modal Content Section*/}
      <div className="relative flex flex-col w-full h-[90%] md:w-[80%] md:h-[85%] bg-white rounded-md z-50">
        <div className="flex justify-end items-start cursor-pointer my-5 mx-5">
          <img src={closeIcon} className="w-10 h-10" onClick={onClose} />
        </div>
        {content}
      </div>
    </div>
  );
};

export default Modal;
