import React from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { MobileTopThinBanner } from "./MobileTopThinBanner";
import { DesktopTopThinBanner } from "./DesktopTopThinBanner";

export const TopThinBanner = () => {
  const { isMobile } = useWindowDimensions();

  return isMobile ? <MobileTopThinBanner /> : <DesktopTopThinBanner />;
};
