import { MakeBackendRequest } from "./MakeBackendRequest";

export const getMessagesLink = async () => {
  const response = await MakeBackendRequest({
    urlSuffix: `/messages`,
    method: "get",
  });

  return response.data;
};
