import React from "react";

export const PriorDiagnosisSvg = ({ fillColor, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      viewBox="0 0 84.5 117.5"
    >
      <defs>
        <clipPath id="a">
          <path fill={fillColor} d="M0 0h84.45v117.44H0z" />
        </clipPath>
      </defs>
      <g fill={fillColor} clip-path="url(#a)">
        <path d="M19.98 73.43a1.13 1.13 0 0 0 .57-.15L53.22 53.9a.84.84 0 0 0 .28-1.25 1.12 1.12 0 0 0-1.42-.25L19.4 71.78a.84.84 0 0 0-.28 1.26 1.07 1.07 0 0 0 .85.4m.01-7.99a1.13 1.13 0 0 0 .57-.15l32.67-19.38a.84.84 0 0 0 .28-1.26 1.12 1.12 0 0 0-1.42-.24L19.4 63.8a.84.84 0 0 0-.28 1.25 1.07 1.07 0 0 0 .85.4m.01-15.96a1.13 1.13 0 0 0 .57-.16l10.12-6a.84.84 0 0 0 .28-1.25 1.11 1.11 0 0 0-1.43-.24l-10.11 6a.84.84 0 0 0-.28 1.25 1.07 1.07 0 0 0 .85.4m0-4.91a1.13 1.13 0 0 0 .57-.15l10.12-6a.84.84 0 0 0 .28-1.26 1.11 1.11 0 0 0-1.43-.24l-10.11 6a.84.84 0 0 0-.28 1.25 1.07 1.07 0 0 0 .85.4" />
        <path d="m83.97 92.93-15.19-8.35.16.23-1.35-.75v-1.2l.13.2V69.77a.88.88 0 0 0-.48-.77l-7.4-4.07V1.26a.8.8 0 0 0-.07-.86 1.06 1.06 0 0 0-.86-.38L58.82 0h-7.07a1.03 1.03 0 0 0-.95.56L.46 30.24l-.07.06a.96.96 0 0 0-.15.13.92.92 0 0 0-.1.14.85.85 0 0 0-.08.15.8.8 0 0 0-.04.18.73.73 0 0 0-.02.1v85.55a.97.97 0 0 0 1.02.9H8.1a1.04 1.04 0 0 0 .87-.46l38.26-22.55v11.77a.88.88 0 0 0 .49.77l12.92 7.1a1 1 0 0 0 .44.3 63.67 63.67 0 0 1 .59.1 1.13 1.13 0 0 0 .5-.11.98.98 0 0 0 .39-.34l4.64-2.32a.89.89 0 0 0 .52-.78v-7.68l3 1.65 7.13 3.92a1.15 1.15 0 0 0 1.04.03.96.96 0 0 0 .39-.34l4.65-2.32a.89.89 0 0 0 .51-.79V93.7a.88.88 0 0 0-.48-.76m-76.89 22.7H2.05V31.9h5.02Zm.5-85.55H4.4L51.85 2.12l.53-.32h3.17L52 3.9Zm39.66 62.17L9.12 114.73V31.34L52.17 5.97l5.63-3.32v61.16l-.89-.49-2.94-1.61a1.15 1.15 0 0 0-1.05-.02L48.28 64a1.13 1.13 0 0 0-.51.1.9.9 0 0 0-.53.8v7.82l-10-5.5a1.15 1.15 0 0 0-1.05-.02l-4.64 2.31a1.14 1.14 0 0 0-.51.12.93.93 0 0 0-.5.55.83.83 0 0 0-.11.32.8.8 0 0 0 .08.46v11.3a.88.88 0 0 0 .48.77l5.1 2.8-16.68 9.9a.84.84 0 0 0-.28 1.24 1.07 1.07 0 0 0 .85.4 1.12 1.12 0 0 0 .58-.15l17.43-10.34 9.24 5.08Zm0-17.13V78l-13.3-7.32 2.73-1.36Zm18.18 35.13-2.72 1.35v-11.14l2.72 1.5ZM77.1 106l-15.16-8.34a1.15 1.15 0 0 0-1.04-.03.9.9 0 0 0-.52.8v13.1l-10.83-5.96V91.34a.88.88 0 0 0-.48-.76l-16.25-8.94v-9.16l15.16 8.34a1.15 1.15 0 0 0 1.04.02.89.89 0 0 0 .53-.79v-13.1l10.83 5.96v14.22a.88.88 0 0 0 .48.77l16.24 8.93Zm5.16-2.14-.13-.19v1.07l-2.71 1.35V96.2a.88.88 0 0 0-.48-.77L62.69 86.5V72.28a.88.88 0 0 0-.48-.77l-11.55-6.35 2.73-1.36 1.4.76-.15-.22 10.9 6v9.6l-.13-.2v4.89a.88.88 0 0 0 .48.76l5.63 3.1-.16-.23 10.91 6Z" />
      </g>
    </svg>
  );
};
