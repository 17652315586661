import React, { useState } from "react";
import { fieldIsRequiredMessage } from "../../../constants/ValidationErrorConstants";
import "./SurveySingleSelectField.scss";

export const SurveySingleSelectField = (props) => {
  const {
    question,
    register,
    updateQuestionVisibilities,
    setValue,
    watch,
    sectionName,
    trigger,
  } = props;

  const handleAnswerSelect = (answer) => {
    setValue(question.searchable_tag, answer.searchable_tag);
    updateQuestionVisibilities(question, answer);
    trigger(question.searchable_tag);
  };

  return (
    <div className="flex justify-start md:justify-center justify-between items-center flex-wrap md:flex-nowrap">
      {question.answers.map((answer, index) => {
        const isSelected =
          answer.searchable_tag === watch(question.searchable_tag);

        return (
          <label
            key={answer.searchable_tag}
            className={`${
              sectionName === "Treatment"
                ? "w-[100%] mb-[0.75rem]"
                : "w-[47%] sm:w-[48%] lg:w-[35%]"
            } mx-[0.67%] border-[1px] border-borderGraySmall lg:border-borderGray rounded mb-[1rem] md:mb-0 text-[0.75rem] sm:text-base 2xl:text-2xl p-2 lg:py-[11px] font-semibold cursor-pointer ${
              isSelected
                ? "bg-primary text-white"
                : "bg-white text-inputGraySecondary"
            }`}
          >
            <input
              {...register(question.searchable_tag, {
                required: fieldIsRequiredMessage(
                  question.validation_field_name
                ),
              })}
              type="radio"
              value={answer.searchable_tag}
              onChange={() => handleAnswerSelect(answer)}
              checked={isSelected}
              className="hidden"
            />
            {answer.label}
          </label>
        );
      })}
    </div>
  );
};
