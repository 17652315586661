import _ from "lodash";
import { useAppContext } from "../../../context/AppContext";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import OrdersDesktop from "./OrdersDesktop";
import OrdersMobile from "./OrdersMobile";

function OdersSection() {
  const { user } = useAppContext();
  const { orders } = user || {};

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.isMobile;

  return (
    <div className="flex flex-col md:px-[1.87rem] min-h-screen">
      {isMobile ? (
        <OrdersMobile orders={orders} />
      ) : (
        <OrdersDesktop orders={orders} />
      )}
    </div>
  );
}

export default OdersSection;
