import React from "react";
import { MobileNavbar } from "./MobileNavbar";
import { DesktopNavbar } from "./DesktopNavbar";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { headerRoutes } from "../../utils/routes";

export const Navbar = () => {
  const { isMobile } = useWindowDimensions();

  return isMobile ? (
    <MobileNavbar navMenuItems={headerRoutes} />
  ) : (
    <DesktopNavbar navMenuItems={headerRoutes} />
  );
};
