import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../logo/Logo";
import { RxCaretDown } from "react-icons/rx";
import { DAUCHI_EMAIL } from "../../constants/Common";

export const MobileFooter = ({ treatments, resources, aboutUs, icons }) => {
  const [email, setEmail] = useState("");
  const [showTreatments, setShowTreatments] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const logoClassName = "text-secondary font-headline text-5xl";

  const handleSubscribe = () => {};

  const renderAboutUsSection = () => {
    return (
      <>
        <Navlinks navList={aboutUs} useLink={true} />
        <a href={`mailto:${DAUCHI_EMAIL}`}>
          <p className="text-white text-[14px] mt-1">Email us</p>
        </a>
      </>
    );
  };

  return (
    <div id="footer-container" className="bg-darkPrimary">
      <div id="footer-inner-container" className="bg-darkPrimary">
        <div
          id="logo-links-section"
          className="text-white text-start border-b border-secondary mx-8 py-5"
        >
          <Logo className={logoClassName} />
          <div id="social-media-hanles" className="flex pt-6">
            {icons.map((icon, index) => {
              return (
                <div
                  key={index}
                  className="flex h-[2.5rem] w-[2.5rem] bg-secondary rounded-full p-2 mr-4 items-center justify-center"
                >
                  <div className="text-darkPrimary text-[1.25rem]">
                    {icon.icon}
                  </div>
                </div>
              );
            })}
          </div>
          <NavLinksHeading
            heading="Treatments"
            isVisible={showTreatments}
            toggleVisibility={() => setShowTreatments(!showTreatments)}
          />
          {showTreatments && <Navlinks navList={treatments} useLink={true} />}
          <NavLinksHeading
            heading="Resources"
            isVisible={showResources}
            toggleVisibility={() => setShowResources(!showResources)}
          />
          {showResources && <Navlinks navList={resources} useLink={true} />}
          <NavLinksHeading
            heading="About Us"
            isVisible={showAboutUs}
            toggleVisibility={() => setShowAboutUs(!showAboutUs)}
          />
          {showAboutUs && renderAboutUsSection()}
          <div
            id="signup-section"
            className="flex flex-col text-white text-md my-5"
          >
            <h1 className="text-secondary text-2xl mb-1">
              Sign Up for Updates
            </h1>
            <p className="text-[1rem] mb-2">
              Enter your email to receive updates from Dauchi
            </p>
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-black focus:outline-none p-2 w-full h-[2.5rem] mb-2"
              />
              <button
                className="text-darkPrimary text-xl bg-secondary h-[2.5rem] w-full"
                onClick={handleSubscribe}
              >
                Subscribe!
              </button>
            </div>
          </div>
        </div>
        <div id="copyright-section" className="py-5">
          <div className="text-white text-start mx-8 text-md font-bodyCopy">
            ©2023 Dauchi, All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

const NavLinksHeading = ({ heading, isVisible, toggleVisibility }) => {
  return (
    <div className="flex items-center mt-5">
      <p className="text-2xl">{heading}</p>
      <RxCaretDown
        className={`text-3xl text-secondary ml-2 transform ${
          isVisible ? "rotate-180" : ""
        }`}
        onClick={toggleVisibility}
      />
    </div>
  );
};

const Navlinks = ({ navList, useLink = false }) => {
  return (
    <div>
      {navList.map((item, index) => {
        return (
          <div key={index}>
            {useLink ? (
              <Link to={item.href}>
                <p className="text-white mt-1 text-[14px]">{item.name}</p>
              </Link>
            ) : (
              <p className="text-white text-[14px] mt-1">{item.name}</p>
            )}
          </div>
        );
      })}
    </div>
  );
};
