import React from "react";
import { SurveyProgressElement } from "./SurveyProgressElement";
import { useLocation } from "react-router-dom";

export const SurveyProgressBar = ({ sections }) => {
  const location = useLocation();

  function currentPathName() {
    // Split the URL by slashes and get the last element
    const urlParts = location.pathname.split("/");
    const finalWord = urlParts[urlParts.length - 1];
    if (finalWord === "herpes_preliminary_survey") {
      return "";
    } else {
      return finalWord;
    }
  }

  const sectionNames = () => {
    const namesArray = sections.map((section) => section.name);
    namesArray[0] = "";
    return namesArray;
  };

  const currentSectionIndex = sectionNames().indexOf(currentPathName());

  const isPast = (sectionIndex) => {
    return currentSectionIndex > sectionIndex;
  };

  const isCurrent = (sectionIndex) => {
    return currentSectionIndex === sectionIndex;
  };

  const isFuture = (sectionIndex) => {
    return currentSectionIndex < sectionIndex;
  };

  return (
    <nav className="px-[1rem] md:px-0">
      <ol className="bg-transparent flex justify-center justify-around py-10 pb-[3rem] md:pt-16 md:pb-20">
        {sections.map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            {sectionIndex > 0 && (
              <div
                className={`w-1/5 mt-[0.75rem] lg:mt-[1.125rem] 2xl:mt-[1.625rem] ${
                  isPast(sectionIndex - 1)
                    ? "bg-secondary h-[2px] lg:h-[5px]"
                    : "bg-lightGray h-[1px] lg:h-[3px]"
                }`}
              ></div>
            )}
            <SurveyProgressElement
              section={section}
              sectionIndex={sectionIndex}
              isPast={isPast(sectionIndex)}
              isCurrent={isCurrent(sectionIndex)}
              isFuture={isFuture(sectionIndex)}
            />
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};
