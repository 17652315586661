import React, { useState } from "react";
import { fieldIsRequiredMessage } from "../../../constants/ValidationErrorConstants";
import { IoIosArrowDown } from "react-icons/io";

export const SurveySexDropDownField = ({
  question,
  register,
  errors,
  watch,
}) => {
  const showOthersInput = watch(question.searchable_tag) === "other_sex_answer";

  return (
    <div className="relative cursor-pointer">
      <select
        {...register(question.searchable_tag, {
          required: fieldIsRequiredMessage(question.validation_field_name),
        })}
        className="bg-transparent text-[0.75rem] sm:text-base w-full border-[1px] cursor-pointer border-borderGraySmall lg:border-borderGray rounded-[5px] lg:rounded-[10px] px-[10px] py-[6px] lg:p-[1.2rem] text-inputGray outline-none p-2 inline-block"
      >
        <option value="">{question.input_placeholder_text}</option>
        {question.answers.map((answer) => (
          <option
            key={answer.searchable_tag}
            {...register(answer.searchable_tag)}
            value={answer.searchable_tag}
          >
            {answer.label}
          </option>
        ))}
      </select>
      <IoIosArrowDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
      {showOthersInput && (
        <>
          <input
            {...register(`${question.searchable_tag}_other_input`, {
              required: "Others is required field",
            })}
            type="text"
            placeholder="Other: Please specify"
            className="outline-none sm:text-base w-full border-[1px] border-borderGraySmall lg:border-borderGray rounded-[5px] lg:rounded-[10px] px-[10px] py-[6px] lg:p-[1.2rem] text-[0.75rem] mt-[2rem] inline-block"
          />
          {errors[`${question.searchable_tag}_others`] && (
            <small className="flex justify-center text-red-700">
              {errors[`${question.searchable_tag}_others`]?.message}
            </small>
          )}
        </>
      )}
    </div>
  );
};
