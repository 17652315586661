import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import moment from "moment";
import { notificationIcon } from "../../../utils/dashboard";
import { clearSingleNotification } from "../../../services/clearSingleNotification";
import mailIcon from "../../../assets/icons/inbox-solid.svg";
import closeIcon from "../../../assets/icons/close.png";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function NotificationSection() {
  const [unreadCount, setUnreadCount] = useState(0);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [yesterdayNotifications, setYesterdayNotifications] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [filter, setFilter] = useState("unread");

  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();
  const { user, setNotifications, notifications } = useAppContext();

  // TODO: Refactor to generalize
  // this usee effect, reduce size
  useEffect(() => {
    const count = notifications?.filter(
      (notification) => !notification.read
    )?.length;
    setUnreadCount(count);

    const today = moment();
    const yesterday = moment().subtract(1, "days");

    const todayAll = [];
    const yesterdayAll = [];
    const previousAll = [];
    const todayRead = [];
    const todayUnread = [];
    const yesterdayRead = [];
    const yesterdayUnread = [];
    const previousRead = [];
    const previousUnread = [];

    // TODO: Generalize this logic
    notifications?.forEach((notification) => {
      const notificationDate = moment(notification.date);
      if (notificationDate.isSame(today, "day")) {
        notification.formattedDate = isMobile
          ? notificationDate.format("h:mm A")
          : `Today ${notificationDate.format("h:mm A")}`;
        todayAll.push(notification);
        if (!notification.read) todayUnread.push(notification);
        else todayRead.push(notification);
      } else if (notificationDate.isSame(yesterday, "day")) {
        notification.formattedDate = isMobile
          ? notificationDate.format("h:mm A")
          : notificationDate.format("MMMM D, YYYY | h:mm A");
        yesterdayAll.push(notification);
        if (!notification.read) yesterdayUnread.push(notification);
        else yesterdayRead.push(notification);
      } else {
        notification.formattedDate = isMobile
          ? notificationDate.format("h:mm A")
          : notificationDate.format("MMMM D, YYYY | h:mm A");
        previousAll.push(notification);
        if (!notification.read) previousUnread.push(notification);
        else previousRead.push(notification);
      }
    });

    if (filter === "unread") {
      setFilteredNotifications({
        today: todayUnread,
        yesterday: yesterdayUnread,
        previous: previousUnread,
      });
    } else {
      setFilteredNotifications({
        today: todayRead,
        yesterday: yesterdayRead,
        previous: previousRead,
      });
    }

    setTodayNotifications(todayUnread);
    setYesterdayNotifications(yesterdayAll);
    setPreviousNotifications(previousAll);
  }, [filter, isMobile]);

  const clearandSetNotifications = async (notificationId) => {
    try {
      const notificationsData = await clearSingleNotification(
        user?.id,
        notificationId
      );
      setNotifications(
        notificationIcon.filter(
          (notification) => notification.id !== notificationId
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterChange = (type) => {
    setFilter(type);
  };

  return (
    <div className="flex flex-col text-start mx-0 md:mx-5 md:my-12 xl:my-10">
      <div className="flex my-2 items-center">
        {isMobile && (
          <button
            onClick={() => navigate("/dashboard")}
            className="flex h-8 w-8 bg-darkPrimary rounded-full justify-center items-center mr-4"
          >
            <IoIosArrowBack className="text-secondary w-8 h-5" />
          </button>
        )}

        <h2 className="text-darkPrimary font-bodyCopy text-2xl md:text-[1.75rem] lg:text-3xl xl:text-5xl">
          Notifications
        </h2>
      </div>
      {isMobile ? (
        <div className="flex space-x-4 mt-8">
          <button
            className={`text-darkPrimary py-[5.5px] px-[1.8rem] rounded-full ${
              filter === "unread" ? "bg-secondary" : ""
            }`}
            onClick={() => handleFilterChange("unread")}
          >
            Unread
          </button>
          <button
            className={`text-darkPrimary py-[5.5px] px-[1.8rem] rounded-full ${
              filter === "read" ? "bg-secondary" : ""
            }`}
            onClick={() => handleFilterChange("read")}
          >
            Read
          </button>
        </div>
      ) : (
        <div>
          {notifications?.length ? (
            <p className="font-subHeadline text-sm xl:text-[1rem]">
              You have
              <span className="text-darkPrimary font-bold">{` ${unreadCount} `}</span>
              unread notifications
            </p>
          ) : null}
        </div>
      )}

      <div className="md:mt-4 xl:mt-6">
        {!notifications?.length ? (
          <div className="flex flex-col w-full h-full my-10 items-center justify-center">
            <img src={mailIcon} alt="mail-icon" className="h-40" />
          </div>
        ) : (
          <div>
            <NotificationsGroup
              notifications={
                isMobile ? filteredNotifications.today : todayNotifications
              }
              title="Today"
              clearandSetNotifications={clearandSetNotifications}
              isMobile={isMobile}
            />
            <NotificationsGroup
              notifications={
                isMobile
                  ? filteredNotifications.yesterday
                  : yesterdayNotifications
              }
              title="Yesterday"
              clearandSetNotifications={clearandSetNotifications}
              isMobile={isMobile}
            />
            <NotificationsGroup
              notifications={
                isMobile
                  ? filteredNotifications.previous
                  : previousNotifications
              }
              title="Previous"
              clearandSetNotifications={clearandSetNotifications}
              isMobile={isMobile}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationSection;

export const NotificationsGroup = ({
  notifications,
  title,
  clearandSetNotifications,
  isMobile,
}) => {
  return (
    <div>
      {notifications.length > 0 && (
        <div>
          <p className="text-[1.25rem] md:text-md lg:text-xl font-bodyCopy text-darkPrimary font-semibold mt-3 mb-2 md:mt-6 xl:mt-10 md:mb-3 xl:mb-3">
            {title}
          </p>
          {notifications.map(
            (
              {
                id,
                body,
                notification_title,
                formattedDate,
                unread,
                notification_type,
              },
              index
            ) => (
              <div
                className="flex mb-2 lg:mb-3 xl:mb-4 items-center md:w-[118%] 2xl:w-full"
                key={index}
              >
                <div className="flex py-5 px-3 sm:px-5 md:p-2 lg:p-3 xl:p-4 items-center border border-borderGraySmall w-full md:w-[80%]">
                  <div className="flex items-center justify-center h-8 md:h-6 lg:h-8 xl:h-12 w-9 md:w-6 lg:w-8 xl:w-12 mr-6 lg:mr-10 rounded-full">
                    <img
                      className="w-[16px] lg:w-[18px] xl:w-[24px] lg:h-[18px] xl:h-[24px]"
                      src={notificationIcon[notification_type]}
                      alt={`icon-${notification_type}`}
                    />
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="flex flex-col w-[12rem] smallCustom:w-[17rem] sm:w-[70%] lg:w-[60%] text-xs lg:text-sm xl:text-[1rem] font-bodyCopy">
                      <p className="font-semibold text-md md:text-xs lg:text-sm xl:text-[1rem] text-darkPrimary">
                        {notification_title}
                      </p>
                      <p
                        className={`text-primary text-sm md:text-xs lg:text-sm xl:text-[1rem] ${
                          isMobile ? "truncate" : ""
                        }`}
                        style={{
                          whiteSpace: isMobile ? "nowrap" : "normal",
                          overflow: isMobile ? "hidden" : "visible",
                          textOverflow: isMobile ? "ellipsis" : "clip",
                        }}
                      >
                        {body}
                      </p>
                    </div>
                    <div className="flex justify-end items-start md:items-center ml-2 lg:mx-4 xl:mx-6">
                      <p className="text-[10px] lg:text-sm xl:text-[1rem] text-primary">
                        {formattedDate}
                      </p>
                      {!isMobile && unread && (
                        <div className="md:h-[6px] xl:h-[10px] md:w-[6px] xl:w-[10px] md:ml-3 lg:ml-4 xl:ml-6 rounded-full bg-primary"></div>
                      )}
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <img
                    src={closeIcon}
                    alt="close-icon"
                    className="md:ml-2 lg:ml-4 xl:ml-6 md:h-4 xl:h-6"
                    onClick={() => clearandSetNotifications(id)}
                  />
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
