import React, { useState } from "react";
import { Logo } from "../logo/Logo";
import { Button } from "../button/Button";
import { CaretDownSvg } from "../svgs/CaretDownSvg";
import { colors } from "../../theme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loggedInHeaderRoutes, profileRoute } from "../../utils/routes";
import { useAppContext } from "../../context/AppContext";
import { signout } from "../../services/auth";

export const DesktopNavbar = ({ navMenuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, resetAppContext, notifications } = useAppContext();
  const pathSegments = location.pathname.split("/");

  const [showDropdown, setShowDropdown] = useState(false);

  const buttonProps = {
    buttonText: "PATIENT PORTAL",
    secondary: false,
    additionalClassNameStyles: "px-10 bg-secondary",
    href: "/dashboard",
  };

  const caretStyle = {
    maxWidth: "24px",
  };

  const link = (item, index) => {
    const linkClassName = "font-bodyCopy text-white mt-3 mx-2";
    if (item.href.includes("#")) {
      return (
        <a key={index} href={item.href} className={linkClassName}>
          {item.text}
        </a>
      );
    } else {
      return (
        <Link key={index} to={item.href} className={linkClassName}>
          {item.text}
          {/* Conditionally render CaretDownSvg for the second item */}
          {index === 1 && (
            <CaretDownSvg style={caretStyle} fillColor={colors.lightYellow} />
          )}
        </Link>
      );
    }
  };

  const button = (item) => (
    <Link to={item.href} style={{ paddingLeft: 4 }}>
      <Button buttonProps={buttonProps} />
    </Link>
  );

  const renderImage = (src, alt) => (
    <img src={src} alt={alt} className="w-[2rem] h-[2rem]" />
  );

  const handleLogOut = async () => {
    try {
      await signout();
    } catch (error) {
      console.error(error.message);
    } finally {
      resetAppContext();
      navigate("/login");
      setShowDropdown(false);
    }
  };

  const handleNavItemClick = (navItem) => {
    if (navItem.text === "My Profile") {
      setShowDropdown(!showDropdown);
    }
  };

  const loggedInItem = (item) => {
    if (!item.desktopVisibility) return <></>;
    return item.type === "link" ? (
      <Link to={item.href} className="relative">
        <img
          src={item.desktopIcon}
          alt={item.name}
          className="w-[2rem] h-[2rem]"
        />
      </Link>
    ) : (
      <div className="relative">
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={() => handleNavItemClick(item)}
        >
          {item.text === "My Profile" && (
            <p className="text-white font-semibold italic mr-3 hover:underline">
              Hi, {user?.first_name || "User"}!
            </p>
          )}

          <img
            src={item.desktopIcon}
            alt={item.name}
            className="w-[2rem] h-[2rem]"
          />
        </div>
        {notifications?.length && item.text === "Notifications" ? (
          <div
            className="w-5 h-5 bg-darkSecondary rounded-full absolute
              -bottom-1 -right-1 flex items-center justify-center"
          >
            <p className="text-white text-xs font-bold">
              {notifications?.length}
            </p>
          </div>
        ) : null}
        {showDropdown && item.text === "My Profile" && (
          <div
            onMouseLeave={() => setShowDropdown(false)}
            className="absolute top-10 right-0 bg-white border border-gray-300 rounded-lg p-2 shadow-md flex flex-col"
          >
            <Link
              className="w-[8rem] text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              to={profileRoute.href}
            >
              <a>Profile</a>
            </Link>
            <button
              className="w-[8rem] text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              onClick={handleLogOut}
            >
              Log out
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="bg-darkPrimary py-5 px-2 lg:px-10 xl:px-20 md:text-sm lg:text-md flex justify-between items-center">
      <Logo />
      {pathSegments[1] === "dashboard" ? (
        <div className="flex gap-[2rem]">
          {loggedInHeaderRoutes.map((item, indes) => loggedInItem(item))}
        </div>
      ) : (
        <>
          {navMenuItems.map((item, index) =>
            // Render the link for all items except the last one
            index !== navMenuItems.length - 1 ? (
              <React.Fragment key={index}>{link(item, index)}</React.Fragment>
            ) : (
              // Render the button for the last item
              <React.Fragment key={index}>{button(item)}</React.Fragment>
            )
          )}
        </>
      )}
    </div>
  );
};
