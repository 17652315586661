import React from "react";

export const CheckmarkSvg = ({ fillColor, size }) => {
  // Determine the height and width based on the window width
  const getWindowDimensions = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return { width };
  };

  const { width } = getWindowDimensions();

  const getSize = () => {
    if (size) {
      return size;
    }
    return width <= 500 ? 12 : 20;
  };

  const svgStyle = {
    height: `${getSize()}px`,
    width: `${getSize()}px`,
    minHeight: `${getSize()}px`,
    minWidth: `${getSize()}px`,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      style={svgStyle}
    >
      <path
        fill="none"
        stroke={fillColor}
        strokeLinecap="round"
        strokeWidth="3"
        d="m2 10 5 8S12 4 19 2"
      />
    </svg>
  );
};
