import React from "react";
import WideSectionCard from "../WideSectionCard";
import SectionTitle from "../SectionTitle";
import { useAppContext } from "../../../context/AppContext";

const MyProfile = () => {
  const { user } = useAppContext();
  const wideCardAddOnStyles = "md:w-full h-auto md:min-h-0 mb-[2rem]";

  const yourInformationSection = () => {
    const fields = [
      { label: "Name", value: `${user.first_name} ${user.last_name}` },
      { label: "Email Address", value: user.email },
      { label: "Phone Number", value: user.phone },
      { label: "Date of Birth", value: user.date_of_birth },
    ];

    return (
      <div>
        <div className="flex justify-between items-center mb-[1rem] lg:mb-[2rem]">
          <p className="lg:text-[1.25rem]">Your Information</p>
        </div>
        <div className="flex flex-wrap text-[14px] md:text-[1.1rem] lg:text-[1.25rem]">
          {fields.map((field, index) => (
            <div
              key={index}
              className={`mb-[12px] lg:mb-[1.5rem] w-full sm:w-[50%] lg:w-[25%]`}
            >
              <p className="font-light">{field.label}</p>
              <p>{field.value}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <SectionTitle title="Account" />
      <WideSectionCard
        content={yourInformationSection()}
        className={wideCardAddOnStyles}
      />
    </div>
  );
};

export default MyProfile;
