export const fieldIsRequiredMessage = (fieldName) => {
  return `${fieldName} is required`;
};

export const fieldRequiredMessage = (question) => {
  if (!question.required) {
    return false;
  } else {
    return `${question.validation_field_name} is required`;
  }
};

export const emailInputTypeEnumString = "email_input";
