import React from "react";
import { fieldRequiredMessage } from "../../constants/ValidationErrorConstants";

export const SurveyInputField = ({ question, register }) => {
  return (
    <input
      {...register(question.searchable_tag, {
        required: fieldRequiredMessage(question),
      })}
      type="text"
      placeholder={question.input_placeholder_text}
      className="outline-none text-[0.75rem] sm:text-base w-full border-[1px] border-borderGraySmall lg:border-borderGray rounded-[5px] lg:rounded-[10px] px-[10px] py-[6px] lg:p-[1.2rem] inline-block"
    />
  );
};
