import { fetchAddressByZipCode } from "../services/fetchAddressByZipCode";

export const populateAddressByZipCode = async (
  zipcode,
  setValue,
  cityField,
  stateField
) => {
  const { city, state } = await fetchAddressByZipCode(zipcode);
  if (city && state) {
    setValue(cityField, city);
    setValue(stateField, state);
  }
};
