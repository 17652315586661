import React from "react";
import moment from "moment";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import DauchiLogo from "../../../assets/images/DauchiLogo.png";
import _ from "lodash";

const SelectedOrder = ({ selectedOrder }) => {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.isMobile;

  const treatmentDummyImage =
    "https://mir-s3-cdn-cf.behance.net/projects/404/6b065d75605675.Y3JvcCw4OTQsNzAwLDAsMA.jpg";

  const { line_1, city, state } = selectedOrder?.pharmacy?.address || {};
  const pharmacyAddress = _.compact([line_1, city, state]).join(" ") || "-";

  const renderLogo = () => (
    <div className="relative flex items-center justify-center">
      <img
        src={DauchiLogo}
        alt="Dauchi"
        className="h-[3.12rem] w-[3.12rem] z-20"
      />
      <div className="w-full h-[1px] bg-secondary absolute top-1/2 z-10" />
    </div>
  );

  return (
    <div className="flex flex-col self-start overflow-hidden w-full lg:w-[58%] rounded-[5px] border-[1px] md:bottom-0 border-ordersBorder md:border-opacity-0">
      <h1 className="text-start px-[1.25rem] py-[7.65px] w-full bg-primary text-white md:hidden">
        Orders Summary
      </h1>
      <div className="w-full rounded-[5px] border-[1px] border-ordersBorder border-opacity-0 md:border-opacity-100 p-[1.25rem] text-start mb-0 md:mb-[1rem] lg:mb-[1.87rem]">
        <h2 className="text-[1rem] 2xl:text-[1.25rem] font-medium text-start mb-[1.25rem]">
          {selectedOrder.title}
        </h2>
        <div className="w-full flex gap-x-[20px] justify-between">
          <img
            className="h-[5.31rem] md:h-[7.81rem] w-[5.31rem] md:w-[7.81rem] object-cover mr-4"
            src={selectedOrder.image || treatmentDummyImage}
            alt="dauchi pharmacy"
          />
          <div className="flex w-full justify-between items-start">
            <div className="flex flex-col">
              <p className="font-medium mb-[10px]">{selectedOrder.title}</p>
              <p>
                Order Created:{" "}
                {moment(selectedOrder?.created_at)?.format("MM/DD/YYYY")}
              </p>
            </div>
            <p>${selectedOrder.total}</p>
          </div>
        </div>
      </div>
      {isMobile && renderLogo()}
      <div className="w-full rounded-[5px] border-0 md:border-[1px] md:border-ordersBorder border-opacity-0 md:border-opacity-100 p-[1.25rem] text-start mb-[1.25rem]">
        <h2 className="text-[1rem] 2xl:text-[1.25rem] font-medium text-start mb-[1.25rem]">
          Orders Details
        </h2>
        <div className="w-full flex flex-col gap-x-[20px] pb-[1.2rem] mb-[1.2rem] border-b-[1px] border-ordersBorder border-opacity-40">
          <h2 className="text-[1.12rem] font-medium mb-[10px]">
            Pharmacy Summary
          </h2>
          <div className="ml-[1rem] flex flex-col gap-y-2 text-[1rem]">
            <p>
              <span className="font-medium">Name:</span>{" "}
              {selectedOrder.pharmacy?.name}
            </p>
            <p>
              <span className="font-medium">Address:</span> {pharmacyAddress}
            </p>
            <p>
              <span className="font-medium">Phone:</span>
              {selectedOrder.pharmacy?.primary_phone}
            </p>
          </div>
        </div>
        <div className="flex justify-between">
          <p className="text-[1.12rem] font-medium mb-[10px]">Total Paid:</p>
          <p className="text-[1.12rem] font-medium mb-[10px]">
            {selectedOrder.total}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectedOrder;
