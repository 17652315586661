import { useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import { axiosInstance } from "../../services/MakeBackendRequest";

const WithAxios = ({ children }) => {
  const { signOutUser, user } = useAppContext();
  useEffect(() => {
    axiosInstance.interceptors.response.use(
      async (response) => {
        const authorization = response.headers["authorization"];

        if (authorization && user?.id) {
          // Set user session
          sessionStorage.setItem(
            "auth",
            JSON.stringify({
              authToken: authorization,
              id: user?.id,
            })
          );
        }

        return response;
      },
      (error) => {
        if (error?.response?.status == 401) {
          signOutUser();
        }
        throw error;
      }
    );
  }, []);

  return children;
};

export default WithAxios;
