import React from "react";
import { statesArray } from "../../constants/States";

export const SurveyStateSelectorDropdownField = (props) => {
  const { register, question } = props;
  // const { autofocus, name, placeholder, label, defaultValue, validate } =
  //   question;

  return (
    <select
      {...register(question.searchable_tag, {
        required: question.required,
      })}
      // autofocus={autofocus}
      className="block border-[1px] border-borderGray rounded-[10px]"
    >
      <option value="">State</option>
      {statesArray.map((state, index) => (
        <option key={index} {...register(state)} value={state}>
          {state}
        </option>
      ))}
    </select>
  );
};
