import moment from "moment";
import { useState } from "react";
import Badge from "../../badge";
import SelectedOrder from "./SelectedOrder";
import SectionTitle from "../SectionTitle";
import { ORDER_STATUS } from "../../../constants/Common";

const OrdersDesktop = ({ orders }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const getBadgeTone = (status) => {
    switch (status) {
      case ORDER_STATUS.SHIPPED:
        return "success";
      case ORDER_STATUS.IN_PURSUIT:
        return "warning";
      case ORDER_STATUS.CANCELLED:
        return "critical";
      default:
        return "success";
    }
  };

  const OrderCard = ({ order }) => {
    const isSelected = order.id === selectedOrder?.id;
    const tone = getBadgeTone(order.order_status);
    return (
      <div className="flex items-center">
        {isSelected && (
          <div className="hidden md:block h-[3.75rem] mb-[2.5rem] w-[5px] bg-secondary rounded-r-full" />
        )}
        <div
          className="w-full mb-[2rem] flex flex-col px-[16px] md:px-0 md:mx-[20px] pb-[40px] md:pb-0 border-b-[.5px] border-primary cursor-pointer relative"
          onClick={() => setSelectedOrder(order)}
        >
          <div className="flex flex-row justify-between mb-[10px]">
            <div className="flex flex-col items-center">
              <label className="text-[14px] lg:text-[1.12rem] font-medium">
                {order.title}
              </label>
              <p className="mb-[10px] text-[14px] lg:text-[1rem] text-start">
                {order.total}$
              </p>
            </div>
            {order.order_status && (
              <Badge tone={tone}>
                <p>{order?.order_status}</p>
              </Badge>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderOrders = () => {
    return orders.map((order, index) => (
      <OrderCard key={index} order={order} />
    ));
  };

  return (
    <div>
      <SectionTitle title="Current Orders" />
      <div className="flex flex-col-reverse lg:flex-row lg:space-x-[1rem] xl:space-x-[1.875rem]">
        <div className="w-full shrink-0 lg:w-[40%] min-w-[20rem] lg:min-w-[22rem] justify-between flex flex-col border-[1px] rounded-md md:rounded-[5px] border-opacity border-ordersBorder">
          <h2 className="px-[1rem] py-[7px] bg-primary text-white md:text-black md:bg-transparent md:p-[20px] md:px-[20px] font-medium text-[1.25rem] text-start mb-[1.87rem] md:mb-0">
            Order History
          </h2>
          {renderOrders()}
        </div>
        {selectedOrder && <SelectedOrder selectedOrder={selectedOrder} />}
      </div>
    </div>
  );
};

export default OrdersDesktop;
