import { createContext, useContext, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";
import { statesArray } from "../../constants/States";

export const SurveyStateContext = createContext({});

export function SurveyProvider({ check, children }) {
  const { user } = useAppContext();

  let day = "";
  let month = "";
  let year = "";

  if (user?.date_of_birth) {
    const dob = moment(user.date_of_birth);
    day = Number(dob.format("D"));
    month = dob.format("MMMM");
    year = Number(dob.format("YYYY"));
  }

  const value = useState({
    email_question: user?.email,
    first_name_question: user?.first_name,
    middle_name_question: user?.middle_initial,
    last_name_question: user?.last_name,
    sex_question: user?.sex,
    cell_question: user?.phone,
    date_of_birth_question: user?.date_of_birth,
    day_selector: day,
    year_selector: year,
    month_selector: month,
    shipping_address_line_1: user?.address?.line_1,
    shipping_address_line_2: user?.address?.line_1,
    shipping_address_state: statesArray[user?.address?.state_id],
    shipping_address_zip_code: user?.address?.zip,
    shipping_address_city: user?.address?.city,
    billing_address_line_1: user?.address?.line_1,
    billing_address_line_2: user?.address?.line_1,
    billing_address_state: statesArray[user?.address?.state_id],
    billing_address_zip_code: user?.address?.zip,
    billing_address_city: user?.address?.city,
  });
  return (
    <SurveyStateContext.Provider value={value}>
      {children}
    </SurveyStateContext.Provider>
  );
}
export function useSurveyState() {
  const context = useContext(SurveyStateContext);
  if (!context) {
    throw new Error("useSurveyState must be used within the SurveyProvider");
  }
  return context;
}
