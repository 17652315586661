import React from "react";
import { fieldIsRequiredMessage } from "../../constants/ValidationErrorConstants";
import { IoIosArrowDown } from "react-icons/io";

export const SurveyDropDownField = ({ question, register }) => {
  return (
    <div>
      <select
        {...register(question.searchable_tag, {
          required: fieldIsRequiredMessage(question.validation_field_name),
        })}
        className="bg-transparent text-[0.75rem] sm:text-base 2xl:text-2xl text-inputGray outline-none p-2 w-full border border-inputBorderSecondary rounded inline-block"
      >
        <option value="">{question.input_placeholder_text}</option>
        {question.answers.map((answer) => (
          <option
            key={answer.searchable_tag}
            {...register(answer.searchable_tag)}
            value={answer.searchable_tag}
            className=""
          >
            {answer.label}
          </option>
        ))}
      </select>
      <IoIosArrowDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
    </div>
  );
};
