import React from "react";

export const NoPage = () => {
  return (
    <div className="h-[65vh]">
      <div className="text-secondary font-headline font-semibold text-6xl mt-40 mb-28">
        Error 404
      </div>
      <div className="text-white text-2xl font-body my-16 mb-24">
        Page not found
      </div>
    </div>
  );
};
