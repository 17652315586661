import React from "react";
import { SurveyInputField } from "../SurveyInputField";
import { SurveyDropDownField } from "../SurveyDropDownField";
import { SurveyTripleDateDropdownField } from "../SurveyTripleDateDropDownField";
import { SurveyStateSelectorDropdownField } from "../SurveyStateSelectorDropDownField";
import { SurveyMultiSelectField } from "../SurveyMultiSelectField";
import { SurveySingleSelectField } from "../SurveySingleSelectField/SurveySingleSelectField";
import { SurveyCheckboxField } from "../SurveyCheckboxField";
import { SurveyPharmacyField } from "../SurveyPharmacyField/SurveyPharmacyField";
import { SurveyEmailInputField } from "../SurveyEmailInputField";
import { SurveyPhoneInputField } from "../SurveyPhoneInputField";
import { SurveyCreditCardField } from "../SurveyCreditCardField/SurveyCreditCardField";
import { SurveyTreatmentSubscriptionSelect } from "../SurveyTreatmentSubscriptionSelect";
import { SurveySexDropDownField } from "../SurveySexDropDownField/SurveySexDropDownField";
import "./SurveyField.scss";

export const SurveyField = (props) => {
  const {
    question,
    register,
    handleSubmit,
    errors,
    control,
    setError,
    clearErrors,
    watch,
    updateQuestionVisibilities,
    setValue,
    pharmacyHeightHandler,
    sectionName,
    fieldWidth,
    getPaymentNonceRef,
    trigger,
    unregister,
    hasUserData,
  } = props;

  const renderInputType = (question, register) => {
    switch (question.input_type) {
      case "input":
        return <SurveyInputField question={question} register={register} />;
      case "email_input":
        return (
          <SurveyEmailInputField
            question={question}
            register={register}
            hasUserData={hasUserData}
          />
        );
      case "phone_input":
        return (
          <SurveyPhoneInputField
            question={question}
            register={register}
            setError={setError}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
            unregister={unregister}
          />
        );
      case "dropdown":
        return <SurveyDropDownField question={question} register={register} />;
      case "triple_date_selector_dropdown":
        return (
          <SurveyTripleDateDropdownField
            question={question}
            register={register}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            setError={setError}
            clearErrors={clearErrors}
          />
        );
      case "state_selector_dropdown":
        return (
          <SurveyStateSelectorDropdownField
            question={question}
            register={register}
          />
        );
      // below syntax is considered a fall-through
      case "multi_select":
        return (
          <SurveyMultiSelectField
            question={question}
            register={register}
            control={control}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />
        );
      case "single_select":
        return (
          <SurveySingleSelectField
            question={question}
            register={register}
            updateQuestionVisibilities={updateQuestionVisibilities}
            setValue={setValue}
            watch={watch}
            sectionName={sectionName}
            trigger={trigger}
          />
        );
      case "checkbox":
        return (
          <SurveyCheckboxField
            question={question}
            register={register}
            errors={errors}
          />
        );
      case "pharmacy_search_and_input":
        return (
          <SurveyPharmacyField
            question={question}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            clearErrors={clearErrors}
            pharmacyHeightHandler={pharmacyHeightHandler}
            trigger={trigger}
            mainFormSetValue={setValue}
          />
        );
      case "credit_card_input":
        return (
          <SurveyCreditCardField
            question={question}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            clearErrors={clearErrors}
            getPaymentNonceRef={getPaymentNonceRef}
            setValue={setValue}
            watch={watch}
            unregister={unregister}
            trigger={trigger}
          />
        );
      case "treatment_subscription_select":
        return (
          <SurveyTreatmentSubscriptionSelect
            question={question}
            register={register}
            updateQuestionVisibilities={updateQuestionVisibilities}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
          />
        );
      case "sex_dropdown":
        return (
          <SurveySexDropDownField
            question={question}
            register={register}
            errors={errors}
            watch={watch}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`px-[5px] ${
        sectionName === "Information"
          ? "sm:py-[0.5rem]"
          : "sm:py-[1rem] md:py-[2.5rem] 2xl:py-[3.75rem] md:border-b-[1px] border-gray"
      }`}
      style={{
        width:
          sectionName === "Information"
            ? fieldWidth[question.searchable_tag]
            : "100%",
      }}
    >
      <div
        className={`${
          sectionName === "Information"
            ? "flex justify-start pt-[30px] lg:pt-[40px]"
            : "pb-[20px] xl:pb-[40px]"
        } `}
      >
        <label
          className={`${
            sectionName === "Information"
              ? "text-[13px] lg:text-base"
              : "text-primary font-bold text-[15px] 2xl:text-2xl"
          }   font-inter `}
        >
          {question.question_text}
        </label>
      </div>
      <div className="pt-[7px] lg:pt-[10px]">
        {renderInputType(question, register)}
      </div>
      {errors[question.searchable_tag] && (
        <small className="flex justify-center text-red-700">
          {errors[question.searchable_tag].message}
        </small>
      )}
    </div>
  );
};
