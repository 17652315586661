import { MakeBackendRequest } from "./MakeBackendRequest";

export const getUser = async (userId) => {
  const response = await MakeBackendRequest({
    urlSuffix: `/users/${userId}`,
    method: "get",
  });

  return response.data;
};
