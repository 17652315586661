import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { fieldIsRequiredMessage } from "../../constants/ValidationErrorConstants";
import moment from "moment";
import { useAppContext } from "../../context/AppContext";
import { IoIosArrowDown } from "react-icons/io";

const monthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const daysArray = Array.from({ length: 31 }, (_, index) => index + 1);

const generateYearsArray = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const endYear = currentYear - 18;

  const yearsArray = [];

  for (let year = endYear; year >= startYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
};

const yearsArray = generateYearsArray();

export const SurveyTripleDateDropdownField = ({
  question,
  register,
  errors,
  setValue,
  trigger,
  setError,
  clearErrors,
}) => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [daysInMonth, setDaysInMonth] = useState(daysArray);

  const { user } = useAppContext();

  useEffect(() => {
    if (user?.date_of_birth) {
      const dob = moment(user.date_of_birth);
      const year = Number(dob.format("YYYY"));
      const month = dob.format("MMMM");
      const day = Number(dob.format("D"));

      setSelectedYear(year);
      setSelectedMonth(month);
      setSelectedDay(day);
    }
  }, [user]);

  const getDaysInMonth = ({ year, month }) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const monthNumber = moment().month(month).format("M");

    const yearAsInteger = year ? Number(year) : currentYear;
    const monthAsInteger = month ? monthNumber : currentMonth;

    let dayCount = new Date(yearAsInteger, monthAsInteger, 0).getDate();

    const calculatedDaysArray = [];
    for (let i = 1; i <= dayCount; i++) {
      calculatedDaysArray.push(i);
    }

    if (!dayCount) {
      return daysArray;
    } else {
      return calculatedDaysArray;
    }
  };

  const handleMonthChange = (event, name) => {
    const passedInMonth = event.target.value;

    const calculatedDaysInMonth = getDaysInMonth({
      month: passedInMonth,
      year: selectedYear,
    });

    setSelectedMonth(passedInMonth);
    setValue(name, passedInMonth);

    setDaysInMonth(calculatedDaysInMonth);
    trigger(name);
  };

  const handleYearChange = (event, name) => {
    const yearIndex = event.target.value;
    const yearValue = yearsArray[yearIndex];

    const calculatedDaysInMonth = getDaysInMonth({
      month: selectedMonth,
      year: yearValue,
    });

    setSelectedYear(yearIndex);
    setValue(name, yearIndex);
    setDaysInMonth(calculatedDaysInMonth);
    trigger(name);
  };

  const handleDayChange = (event, name) => {
    const day = event.target.value;
    setSelectedDay(day);
    setValue(name, day);
    trigger(name);
  };

  const RenderDateDropDown = ({
    data,
    title,
    onChange,
    name,
    value,
    width,
  }) => {
    return (
      <div className={`${width} flex flex-wrap justify-center relative`}>
        <select
          {...register(name, { required: fieldIsRequiredMessage(title) })}
          value={value}
          onChange={(e) => onChange(e, name)}
          className={`w-full text-[12px] sm:text-base cursor-pointer border-[1px] border-borderGraySmall lg:border-borderGray rounded-[5px] lg:rounded-[10px] px-[10px] py-[6px] lg:p-[1.2rem] text-inputGray bg-transparent outline-none p-2 inline-block`}
        >
          <option value={""}>{title}</option>
          {data.map((datum, index) => (
            <option key={index} value={datum}>
              {datum}
            </option>
          ))}
        </select>
        {errors[name] && (
          <small className="justify-center text-red-700">
            {errors[name].message}
          </small>
        )}
        <IoIosArrowDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
      </div>
    );
  };

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      const monthNumber = moment().month(selectedMonth).format("M");
      const selectedDate = moment(
        `${selectedYear}-${monthNumber}-${selectedDay}`,
        "YYYY-MM-DD"
      );

      const eighteenYearsAgo = moment().subtract(18, "years");

      if (selectedDate.isAfter(eighteenYearsAgo)) {
        setError("UnRegisteredField", {
          type: "manual",
          message: "Please enter 18 years or older date",
        });
      } else {
        clearErrors("UnRegisteredField");
      }
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  return (
    <>
      <div className="flex gap-[10px]">
        <RenderDateDropDown
          data={monthsArray}
          title={"Month"}
          onChange={handleMonthChange}
          name={"month_selector"}
          value={selectedMonth}
          width={"w-[41%]"}
        />
        <RenderDateDropDown
          data={daysInMonth}
          title={"Day"}
          onChange={handleDayChange}
          name={"day_selector"}
          value={selectedDay}
          width={"w-[26%]"}
        />
        <RenderDateDropDown
          data={yearsArray}
          title={"Years"}
          onChange={handleYearChange}
          name={"year_selector"}
          value={selectedYear}
          width={"w-[32%]"}
        />
      </div>
      {errors["UnRegisteredField"] && (
        <small className="flex justify-center text-red-700 pt-[5px]">
          {errors["UnRegisteredField"].message}
        </small>
      )}
    </>
  );
};
