import React from "react";
import { NavLink } from "react-router-dom";
import ComingSoonBackground from "../../assets/icons/ComingSoonBackground.svg";
import ComingSoonBackgroundSmall from "../../assets/icons/ComingSoonBackgroundSmall.svg";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

const ComingSoon = () => {
  const { isMobile } = useWindowDimensions();
  return (
    <div className="h-[44rem] md:h-[60rem] bg-lightPurple flex justify-center items-center">
      <div className="bg-white h-[95%] lg:h-[47rem] w-[96%] flex flex-col justify-center items-center relative px-[2.18rem]">
        <img
          src={isMobile ? ComingSoonBackgroundSmall : ComingSoonBackground}
          className="absolute inset-0 h-full w-full z-0"
          alt="background"
        />
        <h1 className="text-[4rem] lg:text-[5rem] text-start xl:text-[6.12rem] text-mediumPrimary mb-[1.25rem] lg:mb-[1.87rem] leading-[4.69rem] lg:leading-[7.12rem] z-[5] font-bodyCopy">
          Coming Soon
        </h1>
        <p className="text-[1rem] text-start mb-[1.25rem] lg:mb-[3.12rem] z-[5] font-normal">
          Hang tight! Our team is currently hard at work putting these pages
          together. Check back again soon for updates!
        </p>
        <NavLink
          to="/"
          className="text-white bg-mediumPrimary text-[1.5rem] w-[20.62rem] py-[1.12rem] rounded-[1rem] z-[5] text-center"
        >
          Return to Home Page
        </NavLink>
      </div>
    </div>
  );
};

export default ComingSoon;
