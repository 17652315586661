import React from "react";

const badgeVariants = {
  success: "bg-success text-success-text",
  warning: "bg-warning text-warning-text",
  critical: "bg-critical text-critical-text",
};

const Badge = ({ children, tone = "success", className = "" }) => {
  const badgeClasses = `${badgeVariants[tone]} ${className}`;

  return (
    <div
      className={`inline-block self-start px-3 rounded-full py-[5px] text-sm font-medium ${badgeClasses}`}
    >
      {children}
    </div>
  );
};

export default Badge;
