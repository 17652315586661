import { useEffect, useState } from "react";
import HelpSection from "../../components/Dashbaord/HelpSection";
import HomeSection from "../../components/Dashbaord/HomeSection";
import OdersSection from "../../components/Dashbaord/OdersSection";
import PharmacySection from "../../components/Dashbaord/PharmacySection";
import SubscriptionsSection from "../../components/Dashbaord/SubscriptionsSection";
import MDISurveySection from "../../components/MDISurveySection";
import MyProfile from "../../components/Dashbaord/MyProfile";
import { useAppContext } from "../../context/AppContext";
import { sections } from "../../utils/dashboard";
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import Messages from "../Messages";
import Loader from "../../components/Loader";
import { Fade } from "react-awesome-reveal";
import { getPendingExternalSurveys } from "../../utils/survey";
import DashboardTwo from "./DashboardTwo";

function Dashboard() {
  // TODO: Only keep dashboard two
  // after design finalized
  return <DashboardTwo />;

  const { user, externalSurveys } = useAppContext();
  const [pendingSurveys, setPendingSurveys] = useState(null);
  const location = useLocation();
  const isOnboarding = new URLSearchParams(location.search).get("onboarding");

  useEffect(() => {
    setPendingSurveys(getPendingExternalSurveys(externalSurveys));
  }, [externalSurveys]);

  const renderDashbaordTabs = () => {
    return (
      <ul className="bg-white flex justify-start md:justify-center cursor-pointer w-full overflow-x-scroll md:overflow-x-visible">
        {sections.map((section, index) => (
          <li
            className={`mx-2 md:mx-[3.1rem] text-[16px] md:text-[20px] font-bodyCopy
            font-medium hover:underline underline-offset-4 transition duration-200`}
            key={index}
          >
            <NavLink
              to={`/dashboard/${section.path}`}
              className={({ isActive }) =>
                isActive && "underline font-semibold"
              }
            >
              {section.text}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {!pendingSurveys?.length && (
        <div className="hidden md:flex bg-white h-[5rem] items-center justify-center">
          {renderDashbaordTabs()}
        </div>
      )}
      <>
        {pendingSurveys === null ? (
          <Loader />
        ) : pendingSurveys?.length ? (
          <MDISurveySection
            pendingSurveys={pendingSurveys}
            isOnboarding={isOnboarding}
          />
        ) : (
          <section
            className={`min-h-screen bg-white md:bg-lightGray pt-[15px] md:pt-[3rem] pb-[50px]`}
          >
            <div className="w-[90%] md:w-[80%] mx-auto flex flex-col">
              <Routes>
                <Route
                  path="/"
                  exact
                  element={
                    <Fade>
                      <HomeSection />
                    </Fade>
                  }
                />
                <Route path="/order" element={<OdersSection />} />
                <Route
                  path="/subscriptions"
                  element={<SubscriptionsSection />}
                />
                <Route path="/pharmacies" element={<PharmacySection />} />
                <Route path="/help" element={<HelpSection />} />
                <Route path={"/profile"} element={<MyProfile />} />
                <Route path="/chat" element={<Messages />} />
              </Routes>
            </div>
          </section>
        )}
      </>
    </>
  );
}

export default Dashboard;
