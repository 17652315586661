import React from "react";
import OurProvidersImage from "../../assets/images/OurProviders.jpg";
export const OurProviders = () => {
  return (
    <div className="bg-lightPurple text-start flex flex-col md:flex-row justify-between px-[1.25rem] md:px-[2.4rem] lg:px-[3rem] xl:px-[4rem] 2xl:px-[5.4rem] py-[1.25rem] md:py-[3.12rem]">
      <div className="min-w-1/2 w-full md:w-[55%] xl:pt-[3.8rem] 2xl:pt-[4.8rem] mr-12 mb-[1.37rem] md:mb-0">
        <h1 className="text-[2rem] md:text-[2.3rem] lg:text-[3rem] xl:text-[3.5rem] 2xl:text-[4.5rem] text-primary font-medium mb-[1rem] 2xl:mb-[1.25rem]">
          Our Providers
        </h1>
        <p className="text-black text-[1rem] md:text-[1.2rem] lg:text-[1.6rem] 2xl:text-[2rem]">
          Our doctors are U.S. board-certified physicians with years of
          experience. They are dedicated to tailoring a personalized treatment
          plan for you and will thoroughly review your case. Even after your
          treatment is complete, you will have the ability to continue chatting
          with your physician for ongoing support.
        </p>
      </div>
      <img
        src={OurProvidersImage}
        alt="Dauchi Our Providers"
        className="min-w-[35%] w-full md:w-[35%] object-cover"
      />
    </div>
  );
};
