import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../services/auth";
import InputField from "./InputField";
import Loader from "../../components/Loader";
import { forgotPasswordFormData } from "../../utils/loginFormData";

const ForgotPasswordForm = ({ toggleForgotPassword, isForgotPassword }) => {
  const [showSubmittedMessage, setShowSubmittedMessage] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setShowSubmittedMessage(true);
      setResetPasswordEmail(data);

      const submissionData = {
        ...data,
        redirect_url: `${window.location?.origin}/password/change`,
      };
      await forgotPassword(submissionData);
    } catch (error) {}
  };

  const getCheckEmailMessage = () => {
    return (
      <div className="flex justify-center w-[85%] md:w-full mt-[40px]">
        <div className="w-full md:w-[70%] flex flex-col gap-[1rem] md:gap-[1.5rem] bg-darkPrimary md:bg-white p-[1.2rem] bg-opacity-60 rounded-[5px]">
          <p className="text-start text-[21px] text-white md:text-black md:text-[2rem] font-bold mb-[1rem] md:mb-0">
            Check Your Email!
          </p>
          <p className="text-start text-white md:text-black text-[14px] md:text-[14px] font-normal md:opacity-60">
            You should receive an email shortly with a link to reset your
            password.
          </p>
          <p className="text-white text-start text-[13px] md:text-[1.12rem] md:text-black md:mb-[2rem]">
            Didn't receive it?{" "}
            <button
              type="button"
              className="text-primary cursor-pointer underline text-secondary"
              onClick={() => onSubmit(resetPasswordEmail)}
            >
              Resend link
            </button>
          </p>
          <div className="flex justify-center">
            <button
              type="button"
              className={"bg-secondary font-bold text-[12px] md:text-xl text-primary py-3 rounded-md md:rounded-full border-l-2 w-full md:w-[20rem]"}
              onClick={() => toggleForgotPassword()}
            >
              Return to Login Page
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showSubmittedMessage ? (
        getCheckEmailMessage(toggleForgotPassword)
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-10 w-[85%] md:w-full"
        >
          <div className="flex flex-col items-center">
            <div className="w-full md:w-[70%] flex flex-col gap-[1rem] md:gap-[1.5rem] bg-darkPrimary md:bg-white p-[1.2rem] bg-opacity-60 rounded-[5px]">
              <div className="flex flex-col items-start">
                <p className="text-start text-[21px] text-white md:text-black md:text-[2rem] font-bold mb-[1rem] md:mb-0">
                  Forgot Password?
                </p>
                <p className="text-start text-white md:text-black text-[14px] md:text-[14px] font-normal md:opacity-60">
                  Please provide your email address that you have set up with
                  us. We'll send you a link to reset your password shortly!
                </p>
              </div>
              <div className="relative">
                {forgotPasswordFormData.map((field) => (
                  <InputField
                    key={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    register={register}
                    rules={field.rules}
                    error={errors[field.name]}
                    name={field.name}
                  />
                ))}
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={toggleForgotPassword}
                  className="text-white md:text-primary mt-2 cursor-pointer text-[13px] underline md:text-[14px]"
                >
                  {isForgotPassword ? "Login" : "Forgot Password?"}
                </button>
              </div>
              <div className="flex justify-center">
                {isSubmitting ? (
                  <Loader />
                ) : (
                  <button
                    type="submit"
                    className={`bg-secondary font-bold text-[12px] md:text-xl text-primary py-3 rounded-md md:rounded-full border-l-2 w-full md:w-[20rem]`}
                  >
                    Send
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default ForgotPasswordForm;
