import React from "react";
import { useSurveyState } from "./SurveyContext";

const SurveyOtpField = ({ register, errors }) => {
  const [state, setState] = useSurveyState();
  const validation = {
    required: "OTP is required",
    minLength: {
      value: 6,
      message: "OTP must be a 6-digit code",
    },
    maxLength: {
      value: 6,
      message: "OTP must be a 6-digit code",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "Only numeric values are allowed for OTP",
    },
  };
  return (
    <div className="w-full pt-[20px] px-[5px]">
      <input
        type="number"
        placeholder="Enter OTP"
        {...register("otp", validation)}
        className={`${
          !state.setRequestOtpCode && "hidden"
        } outline-none text-[0.75rem] sm:text-base w-full border-[1px] border-borderGraySmall lg:border-borderGray rounded-[5px] lg:rounded-[10px] px-[10px] py-[6px] lg:p-[1.2rem] inline-block`}
      />
      {errors["otp"] && (
        <small className="flex justify-center text-red-700">
          {errors["otp"].message}
        </small>
      )}
    </div>
  );
};

export default SurveyOtpField;
