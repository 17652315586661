import { MakeBackendRequest } from "./MakeBackendRequest";

export const SearchForPharmacy = async (queryParamsObject) => {
  try {
    const response = await MakeBackendRequest({
      urlSuffix: `/pharmacies/search`,
      method: "get",
      queryParamsObject,
    });
    const { pharmacies } = response.data;
    return pharmacies;
  } catch (error) {
    throw error;
  }
};
