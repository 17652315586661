import React from "react";
import { fieldRequiredMessage } from "../../constants/ValidationErrorConstants";
// import { useSurveyStepContext } from "../surveyStepContext/surveyStepContext";

export const SurveyCheckboxField = ({ question, register, errors }) => {
  // const { autofocus, name, placeholder, label, defaultValue, validate } =
  // question;

  return (
    <div>
      {question.answers.map((answer, index) => {
        return (
          <>
            <label key={index}>
              {answer.label}
              <input
                {...register(question.searchable_tag, {
                  required: fieldRequiredMessage(question),
                })}
                type="checkbox"
                name={question.searchable_tag}
                label={answer.label}
                value={answer.searchable_tag}
              />
            </label>
          </>
        );
      })}
    </div>
  );
};
