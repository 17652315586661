import { Link } from "react-router-dom";
import ReplyIcon from "../../assets/icons/reply.svg";
import { useAppContext } from "../../context/AppContext";
import { treatments } from "../../utils/treatments";
import _ from "lodash";

const Treatments = () => {
  const { userTreatments } = useAppContext();
  const userActiveTreatmentsTags = userTreatments
    ?.filter((treatment) => treatment.active === true)
    ?.map((treatment) => treatment?.treatment_searchable_tag);
  return (
    <div className="px-[2rem] flex flex-col items-start justify-start w-full">
      <h1 className="text-borderGray text-[2.2rem] sm:text-[3rem] text-left my-[3.2rem]">
        Start a new Treatment!
      </h1>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 mb-[9.37rem] gap-[2rem]">
        {treatments
          .filter(
            (treatment) =>
              treatment.searchable_tag !== undefined &&
              !_.find(userActiveTreatmentsTags, (tag) =>
                treatment.searchable_tag.includes(tag)
              )
          )
          .map((treatment, index) => (
            <Link
              to={treatment.href}
              key={index}
              className="border-[0.5px] shadow-sm group border-borderGraySmall lg:border-borderGray 
              hover:bg-primary hover:text-white
              w-full p-[1rem] py-[1.4rem] rounded-xl relative"
            >
              <h1 className="text-xl text-start">{treatment.name}</h1>
              <img
                src={treatment.icon}
                className="absolute right-5 bottom-5 h-[6rem] w-[6rem] group-hover:-translate-y-5
                2xlh-[7.5rem] 2xl:w-[7.5rem] transition-all duration-100"
                alt={`${treatment.name} icon`}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Treatments;
