import React from "react";
import DesktopHeroImage1 from "../../assets/images/DesktopHeroImage1.png";

const Slide = ({ title, description }) => {
  const handleScroll = () => {
    const section = document.querySelector("#treatments-offered");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="relative h-full">
      <img
        src={DesktopHeroImage1}
        alt="dauchi treatment"
        className="absolute inset-0 w-full h-full object-cover z-10 md:hidden"
      />
      <div
        id="headline-content"
        className="flex h-full text-left relative z-20 items-center py-[3.6rem] md:py-[3rem] lg:py-[5rem] 2xl:py-[9.5rem] px-[1.25rem] lg:px-[2.2rem] xl:px-[4rem] bg-black bg-opacity-70 md:bg-opacity-10"
      >
        <div id="hero-text" className="relative">
          <h2 className="text-white font-medium lg:font-normal text-[3.1rem] leading-[3.8rem] lg:text-[4rem] lg:leading-[5rem] xl:text-[4rem] xl:leading-[5rem] 2xl:leading-[7.25rem] 2xl:text-[6rem] mb-5">
            {title}
          </h2>
          <div className="text-white font-normal md:font-medium text-[1.25rem] lg:text-[1.5rem] xl:text-[1.8rem] 2xl:text-[2.12rem] mb-[2.5rem] lg:mb-[1.87rem]">
            {description}
          </div>
          <button
            type="button"
            onClick={handleScroll}
            className="text-darkPrimary text-[1.25rem] md:text-[1.25rem] lg:text-[1.25rem] xl:text-[1.37rem] w-full md:w-auto rounded-full font-semibold py-[1rem] md:px-[3rem] lg:px-[4rem] xl:px-[5.65rem] lg:py-[1.3rem] xl:py-[1.68rem] bg-secondary"
          >
            GET STARTED
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slide;
