import mailIcon from "../assets/images/mail.jpg";
import localShippingIcon from "../assets/images/local_shipping.jpg";
import packageIcon from "../assets/images/package.jpg";
import listIcon from "../assets/images/list_alt.jpg";

// tab icons
import DashboardIcon from "../assets/icons/dashboard-icon.svg";
import SubscriptionsIcon from "../assets/icons/subscriptions-icon.svg";
import OrdersIcon from "../assets/icons/orders-icon.svg";
import PharmacyIcon from "../assets/icons/pharmacy-icon.svg";
import HelpIcon from "../assets/icons/help-icon.svg";

// tab active
import activeDashboardIcon from "../assets/icons/active-dashboard-icon.svg";
import activeSubscriptionsIcon from "../assets/icons/active-subscriptions-icon.svg";
import activeOrdersIcon from "../assets/icons/active-orders-icon.svg";
import activePharmacyIcon from "../assets/icons/active-pharmacy-icon.svg";
import activeHelpIcon from "../assets/icons/active-help-icon.svg";

export const notificationIcon = {
  "Encounter Created": mailIcon,
  "Encounter Processing": listIcon,
  "Encounter Waiting": listIcon,
  "Encounter Cancelled": mailIcon,
  "Encounter Transferred to Support": listIcon,
  "Encounter Assigned to Clinician": listIcon,
  "Encounter Completed": mailIcon,
  "Honeybee Pharmacy Received": listIcon,
  "Prescription Updated": listIcon,
  "Problem With Order": mailIcon,
  "Shipment Updated": localShippingIcon,
  "New Message": mailIcon,
  "Prescription Submitted": mailIcon,
};

export const dummyNotifications = [
  {
    id: 1,
    body: "Your order for Emergency Contraception has been delivered!",
    notification_title: "Order delivered",
    notification_type: "Encounter Waiting",
    unread: false,
    date: "2024-08-06T18:00:00Z",
  },
  {
    id: 2,
    body: "You have a new message from your provider",
    notification_title: "New Message",
    notification_type: "Encounter Created",
    unread: true,
    date: "2024-08-06T09:00:00Z",
  },
  {
    id: 3,
    body: "You have a new message from your provider",
    notification_title: "New Message",
    notification_type: "Encounter Created",
    unread: true,
    date: "2024-08-06T08:30:00Z",
  },
  {
    id: 4,
    body: "Your order for Emergency Contraception has been delivered!",
    notification_title: "Order delivered",
    notification_type: "Encounter Waiting",
    unread: false,
    date: "2024-08-05T08:30:00Z",
  },
  {
    id: 5,
    body: "Your order for Period Cramp Relief has been shipped!",
    notification_title: "New Message",
    notification_type: "Encounter Transferred to Support",
    unread: false,
    date: "2024-07-31T15:30:00Z",
  },
  {
    id: 6,
    body: "Your order for Period Cramp Relief has been shipped!",
    notification_title: "New Message",
    notification_type: "Prescription Submitted",
    unread: false,
    date: "2024-07-24T08:00:00Z",
  },
  {
    id: 7,
    body: "Your order for Cold Sore Treatment is being processed.",
    notification_title: "New Message",
    notification_type: "Encounter Assigned to Clinician",
    unread: true,
    date: "2024-07-12T08:30:00Z",
  },
  {
    id: 8,
    body: "Your order for Cold Sore Treatment is being processed.",
    notification_title: "New Message",
    notification_type: "Encounter Assigned to Clinician",
    unread: true,
    date: "2024-07-01T18:30:00Z",
  },
];

export const sections = [
  {
    text: "Dashbaord",
    path: "home",
    icon: DashboardIcon,
    activeIcon: activeDashboardIcon,
  },
  {
    text: "Order",
    path: "order",
    icon: OrdersIcon,
    activeIcon: activeOrdersIcon,
  },
  {
    text: "Subscriptions",
    path: "subscriptions",
    icon: SubscriptionsIcon,
    activeIcon: activeSubscriptionsIcon,
  },
  {
    text: "Pharmacies",
    path: "pharmacies",
    icon: PharmacyIcon,
    activeIcon: activePharmacyIcon,
  },
  {
    text: "Help",
    path: "help",
    icon: HelpIcon,
    activeIcon: activeHelpIcon,
  },
];

export const orders = [
  {
    title: "Period Cramp Relief",
    delivery_method: "Home",
    order_status: "Shipped",
  },
  {
    title: "Emergency Contraception",
    delivery_method: "Pharmacy",
    order_status: "Processing",
  },
];
export const notifications = [
  { message: "You have a new message from your provider. ", type: "message" },
  {
    message: "Your order for Emergency Contraception has been delivered!",
    type: "delivered",
  },
  {
    message: "Your order for Period Cramp Relief has been shipped!",
    type: "shipped",
  },
  {
    message: "Your order for Cold Sore Treatment is being processed.",
    type: "processing",
  },
];

export const pharmacy = {
  name: "CVS (7300 S Alameda St)",
  timing: "8AM - 9PM",
  link: "https://www.cvs.com",
  phone: "212 - 357 - 2950",
};

export const subscriptionSectionData = [
  {
    isActive: true,
    name: "Core Sore Treatment (3 months supply)",
    description: "Description of Core Sore Treatment having 3 months supply",
    searchable_tag: "pharmacy_pickup_once_then_mail_delivery",
    price: "120.0",
    frequency: "Quarterly",
    devlivery_type: "pharmacy_pickup_once_then_mail_delivery",
    subtype: "Herpes Preliminary",
    treatment_id: 1,
  },
  {
    isActive: false,
    name: "Sore Treatment (8 months supply)",
    description: "Description of Sore Treatment having 8 months supply",
    searchable_tag: "pharmacy_pickup_once_then_mail_delivery",
    price: "1220.0",
    frequency: "Anually",
    devlivery_type: "pharmacy_pickup_once_then_mail_delivery",
    subtype: "Mouth",
    treatment_id: 1,
  },
];

export const status_code = {
  in_pursuit: "bg-statusYellow",
  processing: "bg-statusYellow",
  pickup_ready: "bg-statusYellow",
  shipped: "bg-statusGreen",
  delivered: "bg-statusGreen",
  completed: "bg-statusGreen",
  cancelled: "bg-statusRed",
};

export const encounters = [
  {
    id: 1,
    messaing_link: "",
    user_treatment: {},
    label: "HSV One Treatment",
  },
  {
    id: 2,
    messaing_link: "",
    user_treatment: {},
    label: "HSV Two Treatment",
  },
];
