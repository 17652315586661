import axios from "axios";

export const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = JSON.parse(sessionStorage.getItem("auth"))?.authToken;
    if (authToken) config.headers["Authorization"] = authToken;
    return config;
  },
  (error) => {
    console.warn(error);
  }
);

export const MakeBackendRequest = ({
  urlSuffix,
  method,
  data,
  headers = defaultHeaders,
  queryParamsObject,
}) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}${urlSuffix}`;

  const translateQueryObjectToQueryParams = (queryParamsObject) => {
    if (!queryParamsObject) {
      return "";
    }
    const mapped = Object.entries(queryParamsObject).map(
      ([key, value]) => `${key}=${value}`
    );

    const joinedString = "?" + mapped.join("&");
    return joinedString;
  };

  url += translateQueryObjectToQueryParams(queryParamsObject);

  return axiosInstance({
    url,
    method,
    data,
    headers,
  });
};
