import ApplePayPng from "../assets/images/availableCheckouts/applePay.png";
import AmazonPayPng from "../assets/images/availableCheckouts/amazonPay.png";
import PayPalPng from "../assets/images/availableCheckouts/payPal.png";
import ShopPayPng from "../assets/images/availableCheckouts/shopPay.png";

export const paymentOptions = [
  { src: ShopPayPng, alt: "ShopPay" },
  { src: AmazonPayPng, alt: "AmazonPay" },
  { src: ApplePayPng, alt: "ApplePay" },
  { src: PayPalPng, alt: "PayPal" },
];

const MAX_ZIP_LENGTH = 5;

export const billingAddressFields = {
  title: "Billing Address",
  fullWidthFields: [
    {
      label: "Address Line 1",
      placeholder: "Street Address",
      name: "billing_address_line_1",
      validation: {
        required: "Street Address required",
      },
    },
    {
      label: "Address Line 2",
      placeholder: "Apt, suite, etc. (optional)",
      name: "billing_address_line_2",
    },
  ],
  splitWidthFields: [
    {
      label: "City",
      placeholder: "Name of city or town",
      name: "billing_address_city",
      validation: {
        required: "City is required",
      },
    },
    {
      label: "State",
      type: "stateSelector",
      placeholder: "State",
      name: "billing_address_state",
      validation: {
        required: "State is required",
      },
    },
    {
      label: "Zip Code",
      placeholder: "00000",
      type: "zipCode",
      name: "billing_address_zip_code",
      validation: {
        required: "Zip Code is required",
        maxLength: {
          value: MAX_ZIP_LENGTH,
          message: `Enter max ${MAX_ZIP_LENGTH} digits`,
        },
        pattern: {
          value: /^\d+$/,
          message: "Only digits are allowed",
        },
      },
    },
  ],
};

export const shippingAddressFields = {
  title: "Shipping Address",
  fullWidthFields: [
    {
      label: "Address Line 1",
      placeholder: "Street Address",
      name: "shipping_address_line_1",
      validation: {
        required: "Street Address required",
      },
    },
    {
      label: "Address Line 2",
      placeholder: "Apt, suite, etc. (optional)",
      name: "shipping_address_line_2",
    },
  ],
  splitWidthFields: [
    {
      label: "City",
      placeholder: "Name of city or town",
      name: "shipping_address_city",
      validation: {
        required: "City is required",
      },
    },
    {
      label: "State",
      type: "stateSelector",
      placeholder: "State",
      name: "shipping_address_state",
      validation: {
        required: "State is required",
      },
    },
    {
      label: "Zip Code",
      placeholder: "00000",
      type: "zipCode",
      name: "shipping_address_zip_code",
      validation: {
        required: "Zip Code is required",
        maxLength: {
          value: MAX_ZIP_LENGTH,
          message: `Enter max ${MAX_ZIP_LENGTH} digits`,
        },
        pattern: {
          value: /^\d+$/,
          message: "Only digits are allowed",
        },
      },
    },
  ],
};

export const creditCardFields = {
  title: "Credit Card",
  fullWidthFields: [
    {
      label: "Name on Card",
      placeholder: "Name",
      required: "Name is required",
      name: "credit_card_name",
    },
    {
      label: "Card Number",
      placeholder: "Name",
      required: "Credit Card Number is required",
      name: "credit_card_name",
    },
  ],
  splitWidthFields: [
    {
      label: "Expiration Date",
      placeholder: "(MM/YY)",
      required: "Expiration Date is required",
      name: "credit_card_expiration_date",
    },
    {
      label: "CVV",
      placeholder: "123",
      required: "CVV is required",
      name: "credit_card_cvv",
    },
  ],
};
