import React from "react";
import { Hero } from "../../components/hero/Hero";
import { ThreeSimpleSteps } from "../../components/threeSimpleSteps.js/ThreeSimpleSteps.js";
import { TreatmentsOffered } from "../../components/treatmentsOffered/TreatmentsOffered";
import { MoreFromDauchi } from "../../components/moreFromDauchi/MoreFromDauchi.js";
import { OurProviders } from "../../components/OurProviders/OurProviders.js";

export const HomePage = () => {
  return (
    <>
      <Hero />
      <section id="three-simple-steps">
        <ThreeSimpleSteps />
      </section>
      <section id="treatments-offered">
        <TreatmentsOffered />
      </section>
      <OurProviders />
      <section id="more-from-dauchi">
        <MoreFromDauchi />
      </section>
    </>
  );
};
