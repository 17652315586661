import React, { useState } from "react";

export const ErrorBanner = ({ error, onDismiss }) => {
  return (
    <div>
      <div className="flex justify-between items-center bg-red-700 text-white p-4">
        <div>{error}</div>
        <button onClick={onDismiss} className="ml-2">
          X
        </button>
      </div>
    </div>
  );
};
