import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { FetchSurveyByTag } from "../../services/FetchSurveyByTag";
import { useQuery } from "@tanstack/react-query";
import { SurveyProvider } from "./SurveyContext";
import { SurveyStep } from "./SurveyStep/SurveyStep";
import { SurveyProgressBar } from "./SurveyProgressBar";
import SurveyPasswordSection from "./SurveyPasswordSection";
import { useLocation } from "react-router-dom";
import { paymentPlaceholderQuestion } from "../../utils/survey";
import PaymentConfirmedIcon from "../svgs/paymentConfirmed.svg";
import Loader from "../Loader";
import { useAppContext } from "../../context/AppContext";
import SurveyModal from "./SurveyModal/SurveyModal";
import _ from "lodash";

export const SurveyWizard = ({ surveySearchableTag, treatmentSurveyPath }) => {
  const { resetUserSurveyInfo, user } = useAppContext();
  const [sectionProps, setSectionProps] = useState([]);
  const location = useLocation();
  const { resetAppContext, modalState } = useAppContext();
  const currentPath = location.pathname;
  const pathSegments = currentPath.split("/");
  const lastPathSegment = pathSegments
    .filter((segment) => segment !== "")
    .pop();
  const {
    data: fetchedSurvey,
    isLoading,
    isError,
  } = useQuery([`surveys/${surveySearchableTag}`, surveySearchableTag], () =>
    FetchSurveyByTag(surveySearchableTag)
  );

  // Keep State Clean when user starts
  // onboarding from beginning
  useEffect(() => {
    resetUserSurveyInfo();
  }, []);

  useEffect(() => {
    if (!fetchedSurvey) return;

    const { survey_sections: sectionProps } = fetchedSurvey.survey || [];

    if (Object.keys(user).length === 0) {
      sectionProps[4] = paymentPlaceholderQuestion;
    } else {
      sectionProps.splice(2, 1);
      sectionProps[3] = paymentPlaceholderQuestion;
    }

    setSectionProps(sectionProps);
  }, [fetchedSurvey]);

  const allQuestions = sectionProps?.map((section) => section.questions).flat();

  if (lastPathSegment !== "password") {
    // Handle error states

    if (isError) {
      return <p>Error fetching survey data.</p>;
    }
  }

  const getOnboardingScreenBanner = (bannerContent) => {
    return (
      <div className="py-5 lg:py-8 w-full bg-primary flex flex-col justify-center items-center">
        {bannerContent.icon && (
          <img
            className="w-[4rem] h-[4rem] md:w-[6rem] md:h-[6rem] lg:w-[9.3rem] lg:h-[9.3rem]"
            src={bannerContent.icon}
            alt={bannerContent.title}
          />
        )}
        <p className="text-[1.3rem] md:text-[2.4rem] text-white">
          {bannerContent.title}
        </p>
      </div>
    );
  };

  const diagnosisBannerContent = {
    title: "Physician Diagnosis Questionnaire",
    subHeading: "We want to help!",
  };

  const confirmPasswordBannerContent = {
    icon: PaymentConfirmedIcon,
    title: "Payment Confirmed!",
    subHeading: "Thank you for your business! Now let's set up your password.",
  };

  const hasUserData = Object.keys(user).length > 0;

  return (
    <>
      {lastPathSegment === "password"
        ? getOnboardingScreenBanner(confirmPasswordBannerContent)
        : getOnboardingScreenBanner(diagnosisBannerContent)}
      <div className="bg-gradient-to-t from-[#dbd5f9] to-[#fefefe] flex flex-col items-center px-[3%] md:px-[10%] font-inter">
        {isLoading ? (
          <div className="h-screen flex justify-center">
            <Loader className="mt-20" size={70} />
          </div>
        ) : (
          <div className="pb-[3.75rem] w-full max-w-[87.5rem] pt-[1.25rem]">
            <SurveyProvider check={"this is true"}>
              {lastPathSegment !== "password" && !isLoading && (
                <div className="px-[3%] md:px-[10%]">
                  <SurveyProgressBar sections={sectionProps} />
                </div>
              )}
              <Routes>
                {sectionProps?.map((section, sectionIndex) => {
                  return (
                    <Route
                      key={sectionIndex}
                      path={`/${sectionIndex === 0 ? "" : section.name}`}
                      element={
                        <SurveyStep
                          allQuestions={allQuestions}
                          section={section}
                          sectionIndex={sectionIndex}
                          nextSection={sectionProps[sectionIndex + 1]}
                          previousSection={sectionProps[sectionIndex - 1]}
                          surveySearchableTag={surveySearchableTag}
                          lastPathSegment={lastPathSegment}
                          hasUserData={hasUserData}
                          treatmentSurveyPath={treatmentSurveyPath}
                          surveyTitle={fetchedSurvey?.survey?.title}
                        />
                      }
                    />
                  );
                })}
                <Route
                  path="/password"
                  element={<SurveyPasswordSection hasUserData={hasUserData} />}
                />
              </Routes>
            </SurveyProvider>
          </div>
        )}
        {modalState.switch && <SurveyModal />}
      </div>
    </>
  );
};
